import React, { useState } from "react";
import { ResultBoxArea } from "../organisms/SkillAcquisition/ResultBoxArea/ResultBoxArea";
import { ProbTableValue } from "./constants";
export const ProbeArea = () => {
  const [first_list, setFirstList] = useState([
    "Trials to Criterion: how many trials to master",
    "Average Value: %",
    "Trend: Upwards/Downwards/Stable",
    "Consecutive Sessions Not Run: ",
  ]);
  const [second_list, setSecondList] = useState([
    "Revision Criteria Met: Yes / No",
    "New Target: (Text Entry)",
    "New Goal: (Ibrahim to add Dropdown)",
  ]);
  return (
    <div className="PercentageArea">
      <h1>Probe Data</h1>
      <p>Goal : Partial Prompt</p>

      <div className="ProbResultContainer">
        <div className="ProbResultWrapper">
          <ul className="head">
            <li>Target</li>
            <li>Date / Therapist</li>
            <li>Mastered</li>
          </ul>
          <div className="body">
            {ProbTableValue.map((EachArray) => (
              <ul className="row">
                {EachArray.map((EachValue) => (
                  <li>{EachValue}</li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>

      <div className="percentage_bottom_area">
        <ResultBoxArea lists={first_list} title="Statistics" />
        <ResultBoxArea lists={second_list} title="Target Revision" buttons />
      </div>
    </div>
  );
};
