export const clientsHelper = [
  {
    name: "Atif Asim",
    code: "102020",
  },
  {
    name: "Matt Nielson",
    code: "16546",
  },
  {
    name: "Asim Ishaq",
    code: "16546",
  },
  {
    name: "Fady Ibrahim",
    code: "16546",
  },
  {
    name: "Ali",
    code: "16546",
  },
];
