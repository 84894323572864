import Trash from "../../img/trash.png";
export const AddListItem = (Icon, deleteIcon) => {
  let Current = 1;
  const AddSteps = (e) => {
    let ParentNodeWrapper = e.target.parentNode.parentNode;
    let InputWrapper = e.target.parentNode.parentNode.id;
    let LabelElement =
      ParentNodeWrapper.lastElementChild.querySelector(".label");
    var regExpString = /[a-zA-Z]/g;
    let HTML = ``;
    // console.log(ParentNodeWrapper.lastElementChild.firstElementChild.firstElementChild.textContent)
    console.log(LabelElement);
    if (LabelElement != null) {
      let Content = LabelElement.firstElementChild.textContent;
      let LabelValue = "";
      if (regExpString.test(Content)) {
        let Ascii = Content.charCodeAt();

        Ascii++;
        LabelValue = String.fromCharCode(Ascii);
      } else {
        Content++;

        LabelValue = Content;
      }

      HTML = `
            <div class="input-wrapper">
            <div class="label"><span>${LabelValue}</span><span>.</span></div>
            <i class="fas fa-pen"></i>
            <input type="text" id=${InputWrapper + Current} />
            <img src=${Trash} alt="" class="trash-image" />
            </div>
            `;
    } else {
      HTML = `
        <div class="input-wrapper">
        <i class="fas fa-pen"></i>
        <input type="text" id=${InputWrapper + Current} />
        <img src=${Trash} alt="" class="trash-image" />
        </div>
        `;
    }

    ParentNodeWrapper.insertAdjacentHTML("beforeend", HTML);

    Current++;

    // Again Add Event Listener
    document.querySelectorAll(deleteIcon).forEach((EachIcon) => {
      EachIcon.addEventListener("click", DeleteSteps);
    });
  };

  const DeleteSteps = (e) => {
    e.target.parentNode.remove();
  };

  document.querySelectorAll(deleteIcon).forEach((EachIcon) => {
    EachIcon.addEventListener("click", DeleteSteps);
  });
  document.querySelectorAll(Icon).forEach((EachIcon) => {
    EachIcon.addEventListener("click", AddSteps);
  });
};
