export const ConsentForm = [
  {
    clinic: "ConsentForm 1",
    id: 4442123,
    lockVal: false,
    type: "ConsentForm",
    date: "28/06/2020",
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    sensoryconcerns: "free form text here",
    home: {
      generalcomments: "free form text here",
      sleeppatterns: "free form text here",
      leisuretime: "free form text here",
      homegoals: "free form text here",
    },
    school: {
      generalcomments: "free form text here",
      preferredactivities: "free form text here",
      schoolgoals: "free form text here",
    },
    community: {
      generalcomments: "free form text here",
      transitions: "free form text here",
      communitygoals: "free form text here",
    },
    selfhelpskills: {
      grooming: "free form text here",
      generalcomments: "free form text here",
      toileting: "free form text here",
      dressing: "free form text here",
    },
    challengingbehavior: "free form text here",
    preferreditems: "free form text here",
    goals: {
      longterm: {
        goal1: "free form text here",
        goal2: "free form text here",
        goal3: "free form text here",
      },
      shortterm: {
        goal1: "free form text here",
        goal2: "free form text here",
        goal3: "free form text here",
      },
    },
    history: {
      familyhistory: "free form text here",
      therapyhistory: "free form text here",
    },
    additionalcomments: "free form text here",
  },

  {
    clinic: "ConsentForm 2",
    type: "ConsentForm",
    lockVal: false,
    id: 5676556,
    date: "22/07/2020",
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    sensoryconcerns: "free form text here",
    home: {
      generalcomments: "free form text here",
      sleeppatterns: "free form text here",
      leisuretime: "free form text here",
      homegoals: "free form text here",
    },
    school: {
      generalcomments: "free form text here",
      preferredactivities: "free form text here",
      schoolgoals: "free form text here",
    },
    community: {
      generalcomments: "free form text here",
      transitions: "free form text here",
      communitygoals: "free form text here",
    },
    selfhelpskills: {
      grooming: "free form text here",
      generalcomments: "free form text here",
      toileting: "free form text here",
      dressing: "free form text here",
    },
    challengingbehavior: "free form text here",
    preferreditems: "free form text here",
    goals: {
      longterm: {
        goal1: "free form text here",
        goal2: "free form text here",
        goal3: "free form text here",
      },
      shortterm: {
        goal1: "free form text here",
        goal2: "free form text here",
        goal3: "free form text here",
      },
    },
    history: {
      familyhistory: "free form text here",
      therapyhistory: "free form text here",
    },
    additionalcomments: "free form text here",
  },
  {
    clinic: "ConsentForm 3",
    type: "ConsentForm",
    id: 34543,
    date: "12/07/2020",
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    sensoryconcerns: "free form text here",
    home: {
      generalcomments: "free form text here",
      sleeppatterns: "free form text here",
      leisuretime: "free form text here",
      homegoals: "free form text here",
    },
    school: {
      generalcomments: "free form text here",
      preferredactivities: "free form text here",
      schoolgoals: "free form text here",
    },
    community: {
      generalcomments: "free form text here",
      transitions: "free form text here",
      communitygoals: "free form text here",
    },
    selfhelpskills: {
      grooming: "free form text here",
      generalcomments: "free form text here",
      toileting: "free form text here",
      dressing: "free form text here",
    },
    challengingbehavior: "free form text here",
    preferreditems: "free form text here",
    goals: {
      longterm: {
        goal1: "free form text here",
        goal2: "free form text here",
        goal3: "free form text here",
      },
      shortterm: {
        goal1: "free form text here",
        goal2: "free form text here",
        goal3: "free form text here",
      },
    },
    history: {
      familyhistory: "free form text here",
      therapyhistory: "free form text here",
    },
    additionalcomments: "free form text here",
  },
];
