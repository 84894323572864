import React from "react";

export const CumulativeList = () => {
  return (
    <div className="CumulativeListWrapper">
      <div className="CumulativeList">
        <ul className="head">
          <li>Target</li>
          <li>Date Started</li>
          <li>Baselined Date</li>
          <li>Mastered Date</li>
          <li>Instructor Therapists</li>
        </ul>
        <div className="body_area">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
