import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../component/Header";
import "../../Assets/style/css/AddClientInformation.css";
import Trash from "../../Assets/img/trash.png";
import tick from "../../Assets/img/tick.png";
import EALogoWhite from "../../Assets/img/EA logo_wht.png";
import { Link } from "react-router-dom";
import { HandleFormNavigation } from "../../Assets/script/js/HandleFormNavigation";
import { ConnectUseState } from "../statemanagement/StateProvider";
import { TeachingStep } from "../../Assets/script/js/TeachingStep";
import { AddListItem } from "../../Assets/script/js/AddListItem";
import CommonHeader from "../component/CommonHeader";
function AddClientInformation() {
  const history = useHistory();
  const [state, dispatch] = ConnectUseState();
  const [Name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [monthYear, setMonthYear] = useState("");

  const [NameStorage, setNameStorage] = useState("");
  const [Id, setId] = useState("");
  useEffect(() => {
    HandleFormNavigation();
    let Name = window.sessionStorage.getItem("name");
    let Id = window.sessionStorage.getItem("id");

    setNameStorage(Name);
    setId(Id);
  }, []);

  // add event listener
  useEffect(() => {
    AddListItem(".fa-plus-square", ".trash-image");
  }, []);

  const HandleSubmit = (e) => {
    // e.preventDefault()
    dispatch({
      type: "SET_CLIENT",
      client: {
        id: Math.random().toString().substr(3),
        name: Name,
        gender: gender,
        birth: birth,
        serviceHours: serviceHours,
        monthYear: monthYear,
      },
    });
    setName("");
    setGender("");
    setBirth("");
    setServiceHours("");
    setMonthYear("");
  };
  return (
    <div className="AddClientInformation">
      <Header current="home" noSticky />

      <form onSubmit={(e) => e.preventDefault()}>
        <CommonHeader
          EALogoWhite={EALogoWhite}
          Name={Name}
          setName={setName}
          gender={gender}
          setGender={setGender}
          birth={birth}
          setBirth={setBirth}
          title="ADD New Client Information"
          serviceHours={serviceHours}
          setServiceHours={setServiceHours}
          monthYear={monthYear}
          setMonthYear={setMonthYear}
        />
        <section className="main-form-area">
          <div className="main-form-area-content w-90">
            <div className="navigation-area">
              <div className="main-heading">
                <img src={tick} alt="" />
                <p>Last Auto-Save 2s ago</p>
              </div>

              <ul>
                <li>
                  <a href="#Sensory-Concern" className="active">
                    Sensory Concern
                  </a>
                </li>
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#School">School</a>
                </li>
                <li>
                  <a href="#Community">Community</a>
                </li>
                <li>
                  <a href="#Self-Help">Self-Help Skills</a>
                </li>
                <li>
                  <a href="#Challenging-Behaviour">Challenging Behaviour</a>
                </li>
                <li>
                  <a href="#Preferred-Items">Preferred Items/Activities</a>
                </li>
                <li>
                  <a href="#Goals">Goals</a>
                </li>
                <li>
                  <a href="#Family-History">Family History</a>
                </li>
                <li>
                  <a href="#Therapy-History">Therapy History</a>
                </li>
                <li>
                  <a href="#Additional-Comments">Additional Comments</a>
                </li>
              </ul>
            </div>

            <div className="pure-form-area">
              <span id="Sensory-Concern"></span>
              <div className="input-collection">
                <label htmlFor="Sensory-Concern">
                  <span className="required_Ast">*</span>
                  Sensory Concern
                </label>
                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Sensory-Concern"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <span id="home"></span>
              <div className="input-collection">
                <label htmlFor="Home">
                  <span className="required_Ast">*</span>Home
                </label>
                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Home"
                    cols="30"
                    rows="10"
                    placeholder="General Comments, concerns or challenging behaviours"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Home"
                    cols="30"
                    rows="10"
                    placeholder="Sleep Patterns"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Home"
                    cols="30"
                    rows="10"
                    placeholder="Leisure Time"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Home"
                    cols="30"
                    rows="10"
                    placeholder="Goals for Home"
                  ></textarea>
                </div>
              </div>

              <span id="School"></span>

              <div className="input-collection">
                <label htmlFor="School">
                  {" "}
                  <span className="required_Ast">*</span>School
                </label>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="School"
                    cols="30"
                    rows="10"
                    placeholder="General Comments, concerns or challenging behaviours"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="School"
                    cols="30"
                    rows="10"
                    placeholder="Preferred Activities or Programs"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="School"
                    cols="30"
                    rows="10"
                    placeholder="Goals for School"
                  ></textarea>
                </div>
              </div>

              <span id="Community"></span>
              <div className="input-collection">
                <label htmlFor="Community">
                  <span className="required_Ast">*</span>Community
                </label>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Community"
                    cols="30"
                    rows="10"
                    placeholder="General Comments, concerns or challenging behaviours"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Community"
                    cols="30"
                    rows="10"
                    placeholder="Transitions"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Community"
                    cols="30"
                    rows="10"
                    placeholder="Goals for Community"
                  ></textarea>
                </div>
              </div>

              <span id="Self-Help"></span>

              <div className="input-collection" id="">
                <label htmlFor="Skills">
                  {" "}
                  <span className="required_Ast">*</span>Self-Help Skills
                </label>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Skills"
                    cols="30"
                    rows="10"
                    placeholder="General Comments, concerns or challenging behaviours"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Skills"
                    cols="30"
                    rows="10"
                    placeholder="Toileting"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Skills"
                    cols="30"
                    rows="10"
                    placeholder="Dressing"
                  ></textarea>
                </div>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Skills"
                    cols="30"
                    rows="10"
                    placeholder="Grooming Skills - including brushing teeth and washing hands"
                  ></textarea>
                </div>
              </div>
              <span id="Challenging-Behaviour"></span>
              <div className="input-collection" id="">
                <label htmlFor="Challenging-Behaviour">
                  Challenging Behaviour (including aggression, SIB, repetitive
                  or stereotypic...)
                </label>
                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Challenging-Behaviour"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>

              <span id="Preferred Items"></span>

              <div className="input-collection">
                <label htmlFor="Preferred-Items">
                  <span className="required_Ast">*</span> Preferred
                  Items/Activities
                </label>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Preferred-Items"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>

              <span id="Goals"></span>

              <div className="input-collection" id="">
                <label htmlFor="Goals-Short">
                  <span className="required_Ast">*</span> Goals | Short Term | 3
                  Minimum
                  <i class="fas fa-plus-square"></i>
                </label>
                <div className="input-wrapper">
                  <i class="fas fa-pen"> </i>
                  <input
                    type="text"
                    id="Goals-Short"
                    style={{ marginLeft: 10 }}
                  />
                  <img src={Trash} alt="" />
                </div>
              </div>

              <div className="input-collection">
                <label htmlFor="Goals-Long">
                  <span className="required_Ast">*</span> Goals | Long Term | 3
                  Minimum
                  <i class="fas fa-plus-square"></i>
                </label>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input
                    type="text"
                    id="Goals-Long"
                    style={{ marginLeft: 10 }}
                  />
                  <img src={Trash} alt="" />
                </div>
              </div>
              <span id="Family-History"></span>
              <div className="input-collection" id="">
                <label htmlFor="Family-History">
                  {" "}
                  <span className="required_Ast">*</span>Family History
                </label>
                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Family-History"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <span id="Therapy-History"></span>
              <div className="input-collection" id="">
                <label htmlFor="Therapy-History">
                  {" "}
                  <span className="required_Ast">*</span>Therapy History
                </label>

                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Therapy-History"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>

              <span id="Additional-Comments"></span>

              <div
                className="input-collection"
                style={{ borderBottom: "none" }}
                id=""
              >
                <label htmlFor="Additional-Comments">Additional Comments</label>
                <div className="text-area-wrapper">
                  <i class="fas fa-pen"></i>
                  <textarea
                    name=""
                    id="Additional-Comments"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>

              <div className="buttons-wrapper">
                {/* <input type="submit" id="save" value="" style={{display:"none"}} onClick={HandleSubmit()} /> */}
                <input
                  type="submit"
                  id="save"
                  value=""
                  style={{ display: "none" }}
                  onClick={() => {
                    history.push("/home");
                    HandleSubmit();
                  }}
                />
                <Link to="/home">
                  <label htmlFor="close" id="close-label">
                    Close
                  </label>
                </Link>
                <label htmlFor="save" id="submit-label">
                  Submit
                </label>

                <input
                  type="submit"
                  id="close"
                  value=""
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}

export default AddClientInformation;
