export const domain = [
  {
    name: "MANDING",
    link: "",
  },
  {
    name: "Behaviour",
    link: "",
  },
  {
    name: "Toileting",
    link: "",
  },
  {
    name: "Cooperation & Reinforcer Effectiveness",
    link: "",
  },
  {
    name: "Visual Performance",
    link: "",
  },
  {
    name: "Receptive Language",
    link: "",
  },
  {
    name: "Motor Imitation",
    link: "",
  },
  {
    name: "Vocal Imitation",
    link: "",
  },
  {
    name: "Requesting",
    link: "",
  },
  {
    name: "Labelling",
    link: "",
  },
  {
    name: "Intraverbals",
    link: "",
  },
  {
    name: "Spontaneous Vocalization",
    link: "",
  },
  {
    name: "Grammar",
    link: "",
  },
  {
    name: "Play",
    link: "",
  },
  {
    name: "Play and Leisure",
    link: "",
  },
  {
    name: "Social",
    link: "",
  },
  {
    name: "Social Intervention",
    link: "",
  },
  {
    name: "Group Instruction",
    link: "",
  },
  {
    name: "Classroom Routines",
    link: "",
  },
  {
    name: "Generalized Response",
    link: "",
  },
  {
    name: "Reading",
    link: "",
  },
  {
    name: "Writing",
    link: "",
  },
  {
    name: "Math",
    link: "",
  },
  {
    name: "Spelling",
    link: "",
  },
  {
    name: "Dressing",
    link: "",
  },
  {
    name: "Eating",
    link: "",
  },
  {
    name: "Grooming",
    link: "",
  },
  {
    name: "Gross Motor",
    link: "",
  },
  {
    name: "Fine Motor",
    link: "",
  },
  {
    name: "Tact",
    link: "",
  },
  {
    name: "Listener",
    link: "",
  },
  {
    name: "Visual Performance",
    link: "",
  },
  {
    name: "Imitation",
    link: "",
  },
  {
    name: "Echoic",
    link: "",
  },
  {
    name: "Vocal",
    link: "",
  },
  {
    name: "Listener Receptive",
    link: "",
  },
  {
    name: "Linguistics",
    link: "",
  },
];

export const baselineToiletingInterval = [
  {
    name: "5 min",
    link: "",
  },
  {
    name: "10 min",
    link: "",
  },
  {
    name: "15 min",
    link: "",
  },
  {
    name: "20 min",
    link: "",
  },
  {
    name: "25 min",
    link: "",
  },
  {
    name: "30 min",
    link: "",
  },
  {
    name: "45 min",
    link: "",
  },
  {
    name: "60 min",
    link: "",
  },
  {
    name: "1h 15min",
    link: "",
  },
  {
    name: "1h 30min",
    link: "",
  },
];

export const masteryCriterion1 = [
  {
    name: "Mastery Criterion Percentage - 100%",
    link: "",
  },
  {
    name: "Mastery Criterion Percentage - 90%",
    link: "",
  },
  {
    name: "Mastery Criterion Percentage - 80%",
    link: "",
  },
  {
    name: "Mastery Criterion Percentage - 70%",
    link: "",
  },
];
export const masteryCriterion2 = [
  {
    name: "Mastery Criterion Timeline - 3 Days",
    link: "",
  },
  {
    name: "Mastery Criterion Timeline - 4 Days",
    link: "",
  },
  {
    name: "Mastery Criterion Timeline - 5 Days",
    link: "",
  },
  {
    name: "Mastery Criterion Timeline - 6 Days",
    link: "",
  },
  {
    name: "Mastery Criterion Timeline - 7 Days",
    link: "",
  },
];
export const masteryCriterion3 = [
  {
    name: "Mastery Criterion Approvals - 1 Therapist",
    link: "",
  },
  {
    name: "Mastery Criterion Approvals - 2 Therapists",
    link: "",
  },
  {
    name: "Mastery Criterion Approvals - 1 Therapist and 1 Parent",
    link: "",
  },
  {
    name: "Mastery Criterion Approvals - 1 Parent",
    link: "",
  },
  {
    name: "Mastery Criterion Approvals - 2 Parents",
    link: "",
  },
];
export const revCriteria1 = [
  {
    name: "Rev Criteria Percentage - 60%",
    link: "",
  },
  {
    name: "Rev Criteria Percentage - 50%",
    link: "",
  },
  {
    name: "Rev Criteria Percentage - 40%",
    link: "",
  },
  {
    name: "Rev Criteria Percentage - 30%",
    link: "",
  },
];
export const revCriteria2 = [
  {
    name: "Rev Criteria Timeline - 3 Days",
    link: "",
  },
  {
    name: "Rev Criteria Timeline - 4 Days",
    link: "",
  },
  {
    name: "Rev Criteria Timeline - 5 Days",
    link: "",
  },
  {
    name: "Rev Criteria Timeline - 6 Days",
    link: "",
  },
  {
    name: "Rev Criteria Timeline - 7 Days",
    link: "",
  },
];
export const revCriteria3 = [
  {
    name: "Rev Criteria Consecutive - Yes",
    link: "",
  },
  {
    name: "Rev Criteria Consecutive - No",
    link: "",
  },
];
export const promptType = [
  {
    name: "Prompt Type - Verbal",
    link: "",
  },
  {
    name: "Prompt Type - Physical",
    link: "",
  },
  {
    name: "Prompt Type - Position",
    link: "",
  },
];
export const promptHierarchy = [
  {
    name: "Least - Most",
    link: "",
  },
  {
    name: "Most - Least",
    link: "",
  },
];

export const genericBehaviours = [
  {
    name: "Select a Behaviour",
    value: "",
    link: "",
    genericDef: "",
    CustomBehaviour: "Applicable",
    CustomDef: "",
  },
  {
    name: "Aggression",
    value: "",
    link: "",
    genericDef:
      "Any instance that the student makes physical contact with another person. This can be done using his body or an object with enough force that it causes a loud sound for at least 1 second. For example, the student slaps the teacher on the arm and a loud thud is heard would be considered aggression. The student tapping the teacher on the shoulder to get their attention would not be considered aggression. Each instance of aggression is counted separately.",
    CustomBehaviour: "Not Applicable",
    CustomDef: "",
  },
  {
    name: "Self Injurious Behaviour",
    value: "",
    link: "",
    genericDef:
      "Any instance in which the student engages in hitting self and/or punishing self and/or biting self and/or pinching self and/or head banging and/or any other purposeful action that causes physical harm to self. For example, the student bites his wrist until it begins to bleed. The student scratching himself to relieve an itch would not be considered a self-injurious behaviour. Each instance of hitting self and/or, punishing self and/or biting self and/or pinching self and/or head banging and/or any other purposeful action that causes physical harm to self is counted separately.",

    CustomBehaviour: "Not Applicable",
    CustomDef: "",
  },
  {
    name: "Property Destruction",
    value: "",
    link: "",
    genericDef:
      "Any instance in which the student engages in pushing items and/or, swiping items and/or throwing items, and/or breaking items that belong to him/her or someone else. For example, the student scratches punches a hole in the wall. The student accidentally falling into the wall and putting a hole into it would not be considered property destruction. The behaviour is considered complete after 15 seconds of no pushing items and/or, swiping items and/or throwing items, and/or breaking items that belong to him/her or someone else.",
    CustomBehaviour: "Not Applicable",
    CustomDef: "",
  },
];

export const assessmentNames = [
  {
    name: "Verbal Behaviour Milestones Assessment and Placement Program (VB-MAPP)",
    value: "",
    link: "",
  },
  {
    name: "Assessment of Basic Language and Learning Skills Revised (ABLLS-R)",
    value: "",
    link: "",
  },
  {
    name: "Promoting Emergence of Advanced Knowledge (PEAK)",
    value: "",
    link: "",
  },
  {
    name: "Essential for Living (EFL)",
    value: "",
    link: "",
  },
  {
    name: "Assessment of functional living skills (AFLS)",
    value: "",
    link: "",
  },
  {
    name: "Early Start Denver Model (ESDM)",
    value: "",
    link: "",
  },
];
