import React, { useState } from "react";
import { ResultDropDown } from "../ResultDropDown";
import { SelectNotes, SelectNotesDate } from "./constants";
import { ResultNavigation } from "../ResultNavigation";
import { SeniorTherapistNotes } from "./SeniorTherapistNotes";
import { ClinicalSupervisorNotes } from "./ClinicalSupervisorNotes";
import { ParentHomeWork } from "./ParentHomeWork";
import { SessionNotes } from "./SessionNotes";

export const NotesBodyAreaBox = ({ active }) => {
  const [Notes, setNotes] = useState("Senior Therapist");
  return (
    <div className="NotesBodyAreaBox">
      <ResultNavigation active={active} />

      <div className="NotesBodyAreaBoxContentArea">
        <div>
          <div className="dropdownWrapper">
            <h2>Select Notes</h2>
            <ResultDropDown list={SelectNotes} setNotes={setNotes} />
          </div>{" "}
          <div className="dropdownWrapper">
            <h2>Select Date</h2>
            <ResultDropDown list={SelectNotesDate} />
          </div>
        </div>
        <div className="NotesWritingArea">
          {Notes == "Senior Therapist" && <SeniorTherapistNotes />}
          {Notes == "Clinical Supervisor" && <ClinicalSupervisorNotes />}
          {Notes == "Parent Homework" && <ParentHomeWork />}
          {Notes == "Session Notes" && <SessionNotes />}
        </div>
      </div>
    </div>
  );
};
