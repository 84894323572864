export const HandleFileUploadEvents = (e) => {
  let resultsLabel = document.querySelectorAll(".drop-down-result label");
  console.log(resultsLabel);
  const HandledropDown = (e) => {
    let DropDownHead = document.querySelector(".drop-down-head");
    let DropDownResult = document.querySelector(".drop-down-result");

    DropDownHead.textContent = e.target.textContent;

    DropDownResult.classList.remove("active");
  };

  resultsLabel.forEach((EachResultLabel) => {
    EachResultLabel.addEventListener("click", HandledropDown);
  });
};
