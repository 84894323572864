import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AddData from "./AddData";
import EditBox from "./EditBox";
import EditOptionHome from "./EditOptionHome";
import UploadNewFiles from "./UploadNewFiles";
import { clientInfo } from "../Api/client_info";
import { client_plan_isp } from "../Api/client_plan_isp";

import { client_plan_bsp } from "../Api/client_plan_bsp";
import { client_program } from "../Api/client_program";
import { client_behaviormanagement } from "../Api/client_behaviormanagement";
import { ConsentForm } from "../Api/ConsentForm";
import { AssessmentGraphs } from "../Api/AssessmentGraphs";
function Clients({ name, code }) {
  const history = useHistory();
  const [active, setActive] = useState("");
  const [start, setstart] = useState(false);
  const [clientInfoState, setclientInfo] = useState(clientInfo);
  const [client_plan_isp_bsp_State, setclient_plan_isp_bsp_State] = useState([
    ...client_plan_isp,
    ...client_plan_bsp,
  ]);
  const [client_plan_bspState, setclient_plan_bsp] = useState(client_plan_bsp);
  const [client_programState, setclient_program] = useState(client_program);

  const [client_behaviormanagementState, setclient_behaviormanagement] =
    useState(client_behaviormanagement);
  const [ConsentFormState, setConsentForm] = useState(ConsentForm);

  const [AssessmentGraphsState, setAssessmentGraphs] =
    useState(AssessmentGraphs);
  const [data, setData] = useState([]);
  const [type, setType] = useState(null);
  useEffect(() => {
    setData(clientInfo);
  }, []);

  useEffect(() => {
    setType(active);
  }, [active]);
  const startNotify = (e) => {
    setstart(true);
  };
  const HanldleActive = (active) => {
    if (active == "client_info") {
      setData(clientInfo);
    }
    if (active == "ConsentForm") {
      setData(ConsentForm);
    }
    if (active == "AssessmentGraphs") {
      setData(AssessmentGraphs);
    }
    if (active == "CurrentPrograms") {
      setData(client_program);
    }
    if (active == "BehaviouralManagement") {
      setData(client_behaviormanagement);
    }
    if (active == "IndividualPlan") {
      setData([...client_plan_isp, ...client_plan_bsp]);
    }
  };

  const HandleInputSearch = (Value, filterarray, stateset) => {
    let SearchFilter = filterarray.filter((EachInfoState) => {
      let Clinic = EachInfoState.clinic.toLowerCase();
      if (Clinic.includes(Value)) {
        return 1;
      } else {
        return 0;
      }
    });
    stateset(SearchFilter);
  };

  return (
    <>
      <div className="clients-box" id={`clients-${code}-Box`}>
        <div className="clients-box-top">
          <p>
            <i class="fas fa-id-badge"></i>
            {name} <span>{code}</span>
          </p>
        </div>

        <div className="edit-options">
          <EditOptionHome
            startNotify={startNotify}
            setActive={setActive}
            valueShould="client_info"
            active={active}
            HanldleActive={HanldleActive}
          >
            <span className="badge-notify">{clientInfoState.length}</span>
            <p>Client Info</p>
            <i class="fas fa-id-card"></i>
          </EditOptionHome>

          <EditOptionHome
            startNotify={startNotify}
            setActive={setActive}
            valueShould="ConsentForm"
            active={active}
            HanldleActive={HanldleActive}
          >
            <span className="badge-notify">{ConsentFormState.length}</span>
            <p>Consent Form</p>
            <i class="fas fa-file-signature"></i>
          </EditOptionHome>

          <EditOptionHome
            startNotify={startNotify}
            setActive={setActive}
            valueShould="AssessmentGraphs"
            active={active}
            HanldleActive={HanldleActive}
          >
            <span className="badge-notify">{AssessmentGraphsState.length}</span>
            <p>Assessment Graphs</p>
            <i class="fas fa-chart-pie"></i>
          </EditOptionHome>

          <EditOptionHome
            startNotify={startNotify}
            setActive={setActive}
            valueShould="IndividualPlan"
            active={active}
            HanldleActive={HanldleActive}
          >
            <span className="badge-notify">
              {client_plan_isp_bsp_State.length}
            </span>
            <p>Individual / Behaviour Support Plan</p>
            <i class="fas fa-chart-line"></i>
          </EditOptionHome>

          <EditOptionHome
            startNotify={startNotify}
            setActive={setActive}
            valueShould="CurrentPrograms"
            active={active}
            HanldleActive={HanldleActive}
          >
            <span className="badge-notify">{client_programState.length}</span>
            <p>Current Programs</p>
            <i class="fas fa-file-alt"></i>
          </EditOptionHome>

          <EditOptionHome
            startNotify={startNotify}
            setActive={setActive}
            valueShould="BehaviouralManagement"
            active={active}
            HanldleActive={HanldleActive}
          >
            <span className="badge-notify">
              {client_behaviormanagementState.length}
            </span>
            <p>Behavioural Management Tools</p>
            <i class="fas fa-file-signature"></i>
          </EditOptionHome>

          <Link
            to="/results/notes"
            style={{ color: "#000" }}
            className="edit-option"
            id={`edit-option-${code}-7`}
          >
            <p>Results</p>
            <i class="fas fa-file-contract"></i>
          </Link>
        </div>

        {start && (
          <div className={`show-notifications ${active == null && "hide"}`}>
            <form action="">
              <div className="input_wrapper">
                <i class="fas fa-search"></i>
                <input
                  type="text"
                  onChange={(e) => {
                    let Value = e.target.value.toLowerCase();

                    if (active == "client_info") {
                      HandleInputSearch(Value, clientInfo, setclientInfo);
                    } else if (active == "ConsentForm") {
                      HandleInputSearch(Value, ConsentForm, setConsentForm);
                    } else if (active == "AssessmentGraphs") {
                      HandleInputSearch(
                        Value,
                        AssessmentGraphs,
                        setAssessmentGraphs
                      );
                    } else if (active == "CurrentPrograms") {
                      HandleInputSearch(
                        Value,
                        client_program,
                        setclient_program
                      );
                    } else if (active == "BehaviouralManagement") {
                      HandleInputSearch(
                        Value,
                        client_behaviormanagement,
                        setclient_behaviormanagement
                      );
                    } else if (active == "IndividualPlan") {
                      //                   setclient_plan_isp_bsp_State
                      // ...client_plan_isp,
                      // ...client_plan_bsp,

                      let FirstSearchFilter = client_plan_isp.filter(
                        (EachInfoState) => {
                          let Clinic = EachInfoState.clinic.toLowerCase();
                          if (Clinic.includes(Value)) {
                            return 1;
                          } else {
                            return 0;
                          }
                        }
                      );
                      let SecondSearchFilter = client_plan_bsp.filter(
                        (EachInfoState) => {
                          let Clinic = EachInfoState.clinic.toLowerCase();
                          if (Clinic.includes(Value)) {
                            return 1;
                          } else {
                            return 0;
                          }
                        }
                      );
                      setclient_plan_isp_bsp_State([
                        ...FirstSearchFilter,
                        ...SecondSearchFilter,
                      ]);
                    }
                  }}
                />
              </div>

              <div className="select_wrapper">
                <select name="" id="">
                  <option value="" selected disabled>
                    Sort
                  </option>
                  <option value="newest">newest</option>
                  <option value="oldest">oldest</option>
                  <option value="mastered">mastered</option>
                </select>
              </div>
            </form>
            {active == "client_info" && (
              <>
                <AddData link="/AddClientInformation" code={code} name={name} />
                {clientInfoState.map((Each) => (
                  <EditBox
                    heading={Each.clinic}
                    date={Each.date}
                    id={Each.id}
                    StateOriginal={clientInfoState}
                    SettingState={setclientInfo}
                  />
                ))}
              </>
            )}

            {active == "ConsentForm" && (
              <>
                <UploadNewFiles />
                {ConsentFormState.map((Each) => (
                  <EditBox
                    heading={Each.clinic}
                    id={Each.id}
                    date={Each.date}
                    StateOriginal={ConsentFormState}
                    SettingState={setConsentForm}
                  />
                ))}
              </>
            )}
            {active == "AssessmentGraphs" && (
              <>
                <UploadNewFiles />
                {AssessmentGraphsState.map((Each) => (
                  <EditBox
                    heading={Each.clinic}
                    id={Each.id}
                    date={Each.date}
                    StateOriginal={AssessmentGraphsState}
                    SettingState={setAssessmentGraphs}
                  />
                ))}
              </>
            )}
            {active == "CurrentPrograms" && (
              <>
                <AddData link="/AddNewCurrentProgram" code={code} name={name} />
                {client_programState.map((Each) => (
                  <EditBox
                    heading={Each.clinic}
                    id={Each.id}
                    lock
                    date={Each.date}
                    lockVal={Each.lockVal}
                    StateOriginal={client_programState}
                    SettingState={setclient_program}
                  />
                ))}
              </>
            )}
            {active == "BehaviouralManagement" && (
              <>
                <AddData
                  link="/BehaviourManagementTools"
                  code={code}
                  name={name}
                />
                {client_behaviormanagementState.map((Each) => (
                  <EditBox
                    heading={Each.clinic}
                    id={Each.id}
                    lockVal={Each.lockVal}
                    lock
                    date={Each.date}
                    StateOriginal={client_behaviormanagementState}
                    SettingState={setclient_behaviormanagement}
                  />
                ))}
              </>
            )}
            {active == "IndividualPlan" && (
              <>
                <AddData
                  link="/IndividualSupportPlan"
                  code={code}
                  name={name}
                />
                {client_plan_isp_bsp_State.map((Each) => (
                  <EditBox
                    heading={Each.clinic}
                    id={Each.id}
                    lockVal={Each.lockVal}
                    lock
                    date={Each.date}
                    StateOriginal={client_plan_isp_bsp_State}
                    SettingState={setclient_plan_isp_bsp_State}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Clients;
