import React from "react";

export const PopUpConfirm = ({ cancel, deletefun }) => {
  return (
    <div className="PopUpConfirm">
      <div className="innerPopUpConfirm">
        <h1>You are permanently deleting this form, are you sure?</h1>
        <div className="buttons_wrapper">
          <button onClick={(e) => deletefun()}>Delete</button>
          <button onClick={(e) => cancel()}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
