export const HandleHomeEvent = () => {
  let CurrentActiveBoxId = "";
  let ClientsBoxNotify = document.querySelectorAll(".NotificationMessages");

  let ClientBoxEditButtons = document.querySelectorAll(
    ".clients-box-top button"
  );
  let ClientBoxEditBoxCloseButtons = document.querySelectorAll(
    ".edit-client-box .close-button"
  );
  let LockImages = document.querySelectorAll(".action-image");
  let NotificationBox = document.querySelectorAll(
    ".show-notifications .notification-box"
  );

  let GlobalBoxButtons = "";
  let GlobalEditButton = "";

  // NotificationBox.forEach((EachBox) => {
  //   EachBox.addEventListener("mouseenter", (e) => {
  //     let ActiveOptionName =
  //       e.target.parentNode.previousElementSibling.querySelector(
  //         ".edit-option.active p"
  //       ).textContent;

  //     let LockElement = e.target.firstElementChild.firstElementChild;
  //     let ButtonsWrpper =
  //       e.target.querySelector(".presentation").lastElementChild
  //         .previousElementSibling;
  //     let EditButton = ButtonsWrpper.firstElementChild;

  //     if (
  //       LockElement.classList.contains("active") ||
  //       ActiveOptionName === "Assessment Graphs" ||
  //       ActiveOptionName === "Consent Form"
  //     ) {
  //       EditButton.style.display = "none";
  //     }

  //     // check behaviour management tool

  //     if (ActiveOptionName === "Behavioural Management Tools") {
  //       ButtonsWrpper.lastElementChild.style.display = "none";
  //     }

  //     ButtonsWrpper.style.display = "block";
  //     GlobalBoxButtons = ButtonsWrpper;
  //     GlobalEditButton = EditButton;
  //   });
  //   EachBox.addEventListener("mouseleave", (e) => {
  //     GlobalBoxButtons.style.display = "none";

  //     GlobalBoxButtons.querySelectorAll("a").forEach((EachLink) => {
  //       EachLink.style.display = "block";
  //     });
  //   });
  // });

  const HandleDetailsBox = (e) => {
    let ButtonText = e.target.querySelector("p").textContent;
    //  window.alert(ButtonText)

    let EditBox = e.target.parentNode.nextElementSibling;

    // if (ButtonText === "Consent Form") {
    //   if (EditBox.querySelector(".add-box.active") != null) {
    //     EditBox.querySelector(".add-box.active").classList.remove("active");
    //   }
    //   EditBox.querySelector(".upload-files-consent").style.display = "flex";
    // } else {
    //   EditBox.querySelector(".upload-files-consent").style.display = "none";
    // }

    // if (ButtonText === "Assessment Graphs") {
    //   if (EditBox.querySelector(".add-box.active") != null) {
    //     EditBox.querySelector(".add-box.active").classList.remove("active");
    //   }
    //   EditBox.querySelector(".upload-files-assessment").style.display = "flex";
    // } else {
    //   EditBox.querySelector(".upload-files-assessment").style.display = "none";
    // }

    // conditions
    // if (ButtonText === "Client Info" || ButtonText === "Assessment Graphs") {
    //   EditBox.querySelectorAll(".notification-box").forEach((EachOption) => {
    //     EachOption.querySelector(".action-image").style.visibility = "hidden";
    //     EachOption.querySelector(
    //       ".top-area"
    //     ).lastElementChild.style.visibility = "visible";
    //   });
    // } else if (ButtonText === "Consent Form") {
    //   EditBox.querySelectorAll(".notification-box").forEach((EachOption) => {
    //     EachOption.querySelector(".action-image").style.visibility = "hidden";
    //     EachOption.querySelector(
    //       ".top-area"
    //     ).lastElementChild.style.visibility = "hidden";
    //   });
    // } else {
    //   EditBox.querySelectorAll(".notification-box").forEach((EachOption) => {
    //     EachOption.querySelector(".action-image").style.visibility = "visible";
    //     EachOption.querySelector(
    //       ".top-area"
    //     ).lastElementChild.style.visibility = "visible";
    //   });
    // }

    // if (ButtonText === "Individual / Behaviour Support Plan") {
    //   if (EditBox.querySelector(".add-box.active") != null) {
    //     EditBox.querySelector(".add-box.active").classList.remove("active");
    //   }

    //   EditBox.querySelector(".add-box.Individual-Support-Plan").classList.add(
    //     "active"
    //   );
    // } else if (ButtonText === "Behavioural Management Tools") {
    //   if (EditBox.querySelector(".add-box.active") != null) {
    //     EditBox.querySelector(".add-box.active").classList.remove("active");
    //   }

    //   EditBox.querySelector(
    //     ".add-box.Behavioural-Management-Tools"
    //   ).classList.add("active");
    // } else if (ButtonText === "Client Info") {
    //   if (EditBox.querySelector(".add-box.active") != null) {
    //     EditBox.querySelector(".add-box.active").classList.remove("active");
    //   }

    //   EditBox.querySelector(".add-box.Client-Info").classList.add("active");
    // } else if (ButtonText === "Current Programs") {
    //   if (EditBox.querySelector(".add-box.active") != null) {
    //     EditBox.querySelector(".add-box.active").classList.remove("active");
    //   }

    //   EditBox.querySelector(".add-box.current-programs").classList.add(
    //     "active"
    //   );
    // }

    // let NotificationBox = document.querySelectorAll(".show-notifications");

    // NotificationBox.forEach((EachBox) => {
    //   EachBox.classList.remove("active");
    // });

    if (CurrentActiveBoxId === e.target.id) {
      EditBox.classList.remove("active");
      CurrentActiveBoxId = "";
      return true;
    } else {
      EditBox.classList.add("active");
    }

    CurrentActiveBoxId = e.target.id;
  };

  const HandleClientInformationEditBox = (e, firstaction, secondaction) => {
    let ButtonId = e.target.id;
    if (ButtonId != null) {
      let ButtonIdSplit = ButtonId.split("-")[0];
      let CurrentTargetClientBox = document.querySelector(
        `#${ButtonIdSplit}-clients-Box`
      );
      let CurrentTargetEditBox = document.querySelector(
        `#${ButtonIdSplit}-Edit-Box`
      );

      CurrentTargetClientBox.style.display = firstaction;
      CurrentTargetEditBox.style.display = secondaction;
    } else {
      return false;
    }
  };

  // const HandleLockImages = (e) => {
  //   let TargetElement = e.target;
  //   let TopParentBox = TargetElement.parentNode.parentNode;
  //   let EditButton =
  //     TopParentBox.querySelector(".buttons-wrapper").firstElementChild;
  //   if (!TargetElement.classList.contains("active")) {
  //     let MasteredHTML = `<span class="span-title">Mastered</span>`;
  //     TopParentBox.insertAdjacentHTML("beforeend", MasteredHTML);
  //     EditButton.style.display = "none";
  //   } else {
  //     if (TopParentBox.lastElementChild.classList[0] === "span-title") {
  //       TopParentBox.lastElementChild.remove();
  //       EditButton.style.display = "block";
  //     }
  //   }
  //   TargetElement.classList.toggle("active");
  // };

  ClientBoxEditButtons.forEach((EachButton) => {
    EachButton.addEventListener("click", (e) =>
      HandleClientInformationEditBox(e, "none", "block")
    );
  });

  ClientBoxEditBoxCloseButtons.forEach((EachButton) => {
    EachButton.addEventListener("click", (e) =>
      HandleClientInformationEditBox(e, "block", "none")
    );
  });

  ClientsBoxNotify.forEach((EachBox) => {
    EachBox.addEventListener("click", (e) => HandleDetailsBox(e));
  });
  let CurrentActiveColorBoxId = "";

  ClientsBoxNotify.forEach((EachImageClientsBoxNotify) => {
    EachImageClientsBoxNotify.addEventListener("click", (e) => {
      if (e.target.id === CurrentActiveColorBoxId) {
        e.target.classList.remove("active");
        CurrentActiveColorBoxId = "";
        return true;
      } else {
        e.target.classList.add("active");
      }

      if (CurrentActiveColorBoxId !== "") {
        document
          .querySelector(`#${CurrentActiveColorBoxId}`)
          .classList.remove("active");
      }

      CurrentActiveColorBoxId = e.target.id;
    });
  });

  // LockImages.forEach((EachImage) => {
  //   EachImage.addEventListener("click", (e) => HandleLockImages(e));
  // });
};
