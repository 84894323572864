import React from "react";

export const Therapist = () => {
  return (
    <div className="Therapist RawDataCommon">
      <div className="top">
        <p>
          MANDING <br /> 15 per day
        </p>
        <p>Prompted: 3</p>
        <p>Independent: 2</p>
      </div>
      <div className="RawDataBody">
        <ul>
          <li>Programs</li>
          <li>
            <p>
              Targets / <br /> Task Group
            </p>
            <p>Goal</p>
          </li>
          <li>
            <p>Trials</p>{" "}
            <ul>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>10</li>
            </ul>
          </li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>{" "}
        <ul>
          <li></li>

          <li></li>
          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>{" "}
        <ul>
          <li></li>
          <li></li>

          <li></li>
        </ul>
      </div>
    </div>
  );
};
