export const client_plan_bsp = [
  {
    clinic: "Endless Abilities Inc BSP",
    id: 2211222,
    lockVal: false,
    date: "10/05/2020",
    "baselined-behaviour": {
      behaviour: "aggression",
      "add-baselined-graph": "no",
    },
    "biopsychosocial-considerations": {
      biological: "free form text here",
      social: "free form text here",
      psychological: "free form text here",
    },
    longtext: {
      "hypothesized-function": "free form text here",
      "replacement-behaviours": "free form text here",
      monitoringprogress: "free form text here",
      "goal-planobjective": "free form text here",
      "target-behaviour": "free form text here",
    },
    "strategies-reduce-target-behaviours": {
      "antecedent-strategy": "free form text here",
      "consequent-strategy": "free form text here",
    },
    risks: {
      risk2: "free form text here",
      risk1: "free form text here",
      risk3: "free form text here",
    },
    dropdowns: {
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    checkboxes: {
      datacollection: {
        duration: "no",
        "momentary-time-sampling": "no",
        "frequency-rate": "yes",
      },
      generalization: {
        material: "no",
        people: "yes",
        settings: "no",
      },
    },
    planinfo: {
      type: "behaviour support plan",
      consent:
        "Informed consent was gathered by for the completion of curricular assessments, and implementation of applied behavioral analytic therapy.",
      "plan-name": "plan name here",
    },
    benefits: {
      benefit3: "free form text here",
      benefit1: "free form text here",
      benefit2: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-21-2022",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },

  {
    clinic: "Endless Abilities Inc BSP 2",
    id: 1236123,
    date: "22/05/2020",
    lockVal: false,
    "baselined-behaviour": {
      behaviour: "aggression",
      "add-baselined-graph": "no",
    },
    "biopsychosocial-considerations": {
      biological: "free form text here",
      social: "free form text here",
      psychological: "free form text here",
    },
    longtext: {
      "hypothesized-function": "free form text here",
      "replacement-behaviours": "free form text here",
      monitoringprogress: "free form text here",
      "goal-planobjective": "free form text here",
      "target-behaviour": "free form text here",
    },
    "strategies-reduce-target-behaviours": {
      "antecedent-strategy": "free form text here",
      "consequent-strategy": "free form text here",
    },
    risks: {
      risk2: "free form text here",
      risk1: "free form text here",
      risk3: "free form text here",
    },
    dropdowns: {
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    checkboxes: {
      datacollection: {
        duration: "no",
        "momentary-time-sampling": "no",
        "frequency-rate": "yes",
      },
      generalization: {
        material: "no",
        people: "yes",
        settings: "no",
      },
    },
    planinfo: {
      type: "behaviour support plan",
      consent:
        "Informed consent was gathered by for the completion of curricular assessments, and implementation of applied behavioral analytic therapy.",
      "plan-name": "plan name here",
    },
    benefits: {
      benefit3: "free form text here",
      benefit1: "free form text here",
      benefit2: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-21-2022",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },

  {
    clinic: "Endless Abilities Inc BSP 3",
    id: 798172,
    lockVal: false,
    date: "22/06/2020",
    "baselined-behaviour": {
      behaviour: "aggression",
      "add-baselined-graph": "no",
    },
    "biopsychosocial-considerations": {
      biological: "free form text here",
      social: "free form text here",
      psychological: "free form text here",
    },
    longtext: {
      "hypothesized-function": "free form text here",
      "replacement-behaviours": "free form text here",
      monitoringprogress: "free form text here",
      "goal-planobjective": "free form text here",
      "target-behaviour": "free form text here",
    },
    "strategies-reduce-target-behaviours": {
      "antecedent-strategy": "free form text here",
      "consequent-strategy": "free form text here",
    },
    risks: {
      risk2: "free form text here",
      risk1: "free form text here",
      risk3: "free form text here",
    },
    dropdowns: {
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    checkboxes: {
      datacollection: {
        duration: "no",
        "momentary-time-sampling": "no",
        "frequency-rate": "yes",
      },
      generalization: {
        material: "no",
        people: "yes",
        settings: "no",
      },
    },
    planinfo: {
      type: "behaviour support plan",
      consent:
        "Informed consent was gathered by for the completion of curricular assessments, and implementation of applied behavioral analytic therapy.",
      "plan-name": "plan name here",
    },
    benefits: {
      benefit3: "free form text here",
      benefit1: "free form text here",
      benefit2: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-21-2022",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },
];
