export const SelectNotes = [
  {
    name: "Senior Therapist",
    link: "",
  },
  {
    name: "Session Notes",
    link: "",
  },
  {
    name: "Parent Homework",
    link: "",
  },
  {
    name: "Clinical Supervisor",
    link: "",
  },
];
export const PerTherapist = [
  {
    name: "Combined",
    link: "",
  },
  {
    name: "Therapist 1",
    link: "",
  },
  {
    name: "Therapist 2",
    link: "",
  },
  {
    name: "Therapist 3",
    link: "",
  },
];
export const SelectNotesDate = [
  {
    name: "6-11-2021",
    link: "",
  },
  {
    name: "6-10-2021",
    link: "",
  },
  {
    name: "5-28-2021",
    link: "",
  },
  {
    name: "5-25-2021",
    link: "",
  },
];

export const ProbTableValue = [
  [
    "Apple",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "N",
    "N",
    "N",
    "YES",
  ],
  [
    "Ball",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "N",
    "N",
    "N",
    "N",
    "Y",
    "N",
    "YES",
  ],
  [
    "Board",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "N",
    "N",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "Y",
    "YES",
  ],
  [
    "Dog",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "N",
    "N",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "Y",
    "YES",
  ],
  [
    "Cat",
    "N",
    "N",
    "N",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "Y",
    "Y",
    "Y",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "YES",
  ],
  [
    "Mouse",
    "Y",
    "N",
    "Y",
    "Y",
    "Y",
    "N",
    "N",
    "N",
    "N",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "Y",
    "Y",
    "YES",
  ],
  [
    "Car",
    "Y",
    "N",
    "Y",
    "N",
    "N",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "Y",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "YES",
  ],
  [
    "Bike",
    "Y",
    "N",
    "Y",
    "N",
    "N",
    "Y",
    "Y",
    "Y",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "N",
    "Y",
    "N",
    "YES",
  ],
  [
    "Rock",
    "Y",
    "N",
    "Y",
    "N",
    "N",
    "Y",
    "Y",
    "Y",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "N",
    "N",
    "Y",
    "Y",
    "N",
    "YES",
  ],
  [
    "Pencil",

    "Y",
    "Y",
    "Y",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "Y",
    "N",
    "N",
    "Y",
    "N",
    "N",
    "Y",
    "N",
    "Y",
    "N",
    "YES",
  ],
];
export const MaintainTableValue = [
  [
    "Schedule",
    "Target 1",
    "Target 2",
    "Target 3",
    "Target 4",
    "Target 5",
    "Target 6",
    "Target 7",
    "Target 8",
    "Target 9",
    "Target 10",
  ],
  [
    ["Week 1", "Y", "Y", "Y", "N", "N", "Y", "N", "Y", "Y", "N"],
    ["Week 2", "N", "Y", "N", "Y", "Y", "Y", "N", "Y", "Y", "N"],
    ["Week 3", "Y", "Y", "N", "N", "Y", "Y", "N", "Y", "Y", "N"],
    ["Week 4", "N", "N", "Y", "N", "Y", "Y", "N", "Y", "Y", "Y"],
    ["Week 6", "Y", "N", "Y", "Y", "Y", "Y", "N", "N", "Y", "N"],
    ["Week 8", "Y", "Y", "Y", "N", "N", "Y", "N", "Y", "Y", "N"],
    ["Week 12", "Y", "Y", "Y", "N", "N", "Y", "N", "Y", "Y", "N"],
    ["Week 16", "Y", "Y", "Y", "N", "N", "Y", "N", "Y", "Y", "N"],
    ["Week 20", "Y", "Y", "Y", "N", "N", "Y", "N", "Y", "Y", "N"],
    ["Week 24", "Y", "Y", "Y", "N", "N", "Y", "N", "Y", "Y", "N"],
    ["6 Months", "Y", "Y", "Y", "N", "N", "Y", "N", "Y", "Y", "N"],
  ],
];
