import React, { useState } from "react";
import { useEffect } from "react";
import { DropDownList } from "../organisms/SkillAcquisition/DropDownList/DropDownList";

import { ResultNavigation } from "../ResultNavigation";
import { CumulativeArea } from "./CumulativeArea";
import { FrequencyArea } from "./FrequencyArea";
import { MaintenanceArea } from "./MaintenanceArea";
import { PercentageArea } from "./PercentageArea";
import { ProbeArea } from "./ProbeArea";

export const SkillAcquisitionBodyAreaBox = ({ active }) => {
  const [option, setOption] = useState("Percentage");
  const [Therapist, setTherapist] = useState(null);

  const [SettingsDropdown, setSettingsDropdown] = useState(null);

  useEffect(() => {
    switch (option) {
      case "Percentage":
        setSettingsDropdown(option);
        break;
    }
  }, [option]);

  const AssignOption = (name) => {
    setOption(name);
  };
  const AssignProgram = (name) => {
    setOption(name);
  };
  const AssignTherapist = (name) => {
    setTherapist(name);
  };
  return (
    <div className="SkillAcquisitionBodyAreaBox NotesBodyAreaBox">
      <ResultNavigation active={active} />

      {/* DropdownList */}
      <DropDownList
        OptionFunc={AssignOption}
        AssignTherapist={AssignTherapist}
        SettingsDropdown={SettingsDropdown}
        setSettingsDropdown={setSettingsDropdown}
        setProgramAssign={AssignProgram}
      />

      {/* LogicalComponentRendering */}
      {option == "Percentage" && <PercentageArea Therapist={Therapist} />}
      {option == "Manding" && <FrequencyArea Therapist={Therapist} />}
      {option == "Combined" && <FrequencyArea Therapist={Therapist} />}
      {option == "Probe" && <ProbeArea />}
      {option == "Cumulative" && <CumulativeArea />}
      {option == "Maintenance" && <MaintenanceArea />}
    </div>
  );
};
