export const CustomDropDown = () => {
  let RadioInputsLabel = document.querySelectorAll(".select-body li label");
  RadioInputsLabel.forEach((EachLabel) => {
    if (EachLabel.classList[0] !== "Applicable-Assesments-label") {
      EachLabel.addEventListener("click", (e) => {
        let SplitId = e.target.id.split("-");
        let NewId = "";

        for (let i = 0; i < SplitId.length - 1; i++) {
          NewId += `${SplitId[i]}-`;
        }

        NewId += "head";

        let CurrentHead = document.querySelector(`.${NewId}`);
        let CurrentHeadPara = document.querySelector(`.${NewId} > p`);
        CurrentHeadPara.textContent = e.target.textContent;
      });
    }
  });
};
