import React from "react";

const EditOptionHome = ({
  setActive,
  valueShould,
  active,
  children,
  HanldleActive,
  startNotify,
}) => {
  return (
    <div
      className={`edit-option NotificationMessages ${
        active == valueShould && valueShould
      }`}
      onClick={(e) => {
        startNotify();
        setActive(valueShould);
        HanldleActive(valueShould);

        if (active == valueShould) {
          setActive(null);
          HanldleActive(null);
        }
      }}
    >
      {children}
    </div>
  );
};
export default EditOptionHome;
