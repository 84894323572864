import React, { useState } from "react";

import { DropDownListBehvaiour } from "../organisms/Behviour/DropDownListBehvaiour/DropDownListBehvaiour";

import { ResultNavigation } from "../ResultNavigation";
import { BehaviourNotes } from "./BehaviourNotes";

import { DayArea } from "./DayArea";
import { DurationArea } from "./DurationArea";
import { FrequencyArea } from "./FrequencyArea";

export const BehavioursBodyAreaBox = ({ active }) => {
  const [option, setOption] = useState("Frequency");

  const [SettingsDropdown, setSettingsDropdown] = useState(null);

  const AssignOption = (name) => {
    setOption(name);
  };
  const AssignProgram = (name) => {
    setOption(name);
  };
  return (
    <div className="SkillAcquisitionBodyAreaBox NotesBodyAreaBox">
      <ResultNavigation active={active} />

      {/* DropdownList */}
      <DropDownListBehvaiour
        OptionFunc={AssignOption}
        SettingsDropdown={SettingsDropdown}
        setSettingsDropdown={setSettingsDropdown}
        setProgramAssign={AssignProgram}
      />

      {/* LogicalComponentRendering */}

      {option == "Frequency" && (
        <div>
          <FrequencyArea nogridLines={true} slider={setOption} />
        </div>
      )}

      {option == "Duration" && (
        <div>
          <DurationArea slider={setOption} />
        </div>
      )}
      {option == "Rate / Day" && (
        <div>
          <DayArea heading="Rate / Day" slider={setOption} />
        </div>
      )}
      {option == "ABC Notes" && <BehaviourNotes />}
      {option == "Rate / Hour" && (
        <div>
          <DayArea heading="Rate / Hour" slider={setOption} />
        </div>
      )}
      {option == "Rate / Minute" && (
        <div>
          <DayArea heading="Rate / Minute" slider={setOption} />
        </div>
      )}
    </div>
  );
};
