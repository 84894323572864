import React, { useState } from "react";
import Header from "../component/Header";
import { useHistory } from "react-router-dom";
import { ConnectUseState } from "../statemanagement/StateProvider";
import { FormsDropDowns } from "../component/FormsDropDowns";
import * as CurrentProgramsDropdowns from "../../Assets/script/js/DropdownFormConstants";
import "../../Assets/style/css/AddClientInformation.css";
import Trash from "../../Assets/img/trash.png";
import tick from "../../Assets/img/tick.png";
import EALogoWhite from "../../Assets/img/EA logo_wht.png";
import { useEffect } from "react";
// import { CustomDropDown } from "../../Assets/script/js/CustomDropDown";
import { HandleFormNavigation } from "../../Assets/script/js/HandleFormNavigation";
import { AddListItem } from "../../Assets/script/js/AddListItem";
import {
  DecreamentValueSecond,
  DecreamentValueFirst,
  IncreamentValueFirst,
  IncreamentValueSecond,
} from "../../Assets/script/js/ArrowFunction";

import { Link } from "react-router-dom";
import { doc } from "prettier";
import CommonHeader from "../component/CommonHeader";

function AddNewCurrentProgram() {
  // Domain Selection
  const [domain, setDomain] = useState("");
  const [domainVal, setdomainVal] = useState("Select Domain");

  // Behaviour Interval Seleection
  const [baselineInterval, setBaselineInterval] = useState(1);
  // Reinforcement Schedule Integers
  const [FirstInteger, setFirstInteger] = useState(1);
  const [SecondInteger, setSecondInteger] = useState(1);
  // Master Criteria Selection Inputs
  const [masteryCriterion1, setMasteryCriterion1] = useState(1);
  const [masteryCriterion2, setMasteryCriterion2] = useState(2);
  const [masteryCriterion3, setMasteryCriterion3] = useState(3);
  // Revision Critera Selection Inputs
  const [revisionCriteria1, setRevisionCriteria1] = useState(1);
  const [revisionCriteria2, setRevisionCriteria2] = useState(2);
  const [revisionCriteria3, setRevisionCriteria3] = useState(3);
  // Prompting Selection Inputs
  const [promptType, setPromptType] = useState(1);
  const [promptHierarchy, setPromptHierarchy] = useState(2);

  // Data Collection & Generalization Inputs
  const [DataCollection, setDataCollection] = useState("");
  const [Generalization, setGeneralization] = useState("");

  const [dropdown, setdropdown] = useState(null);

  // Baselined Interval Checkbox confirmation
  const [isCollectChecked, setIsCollectChecked] = useState(false);
  const [isBypassChecked, setIsBypassChecked] = useState(false);

  const collect_handleOnChange = () => {
    setIsCollectChecked(true);
    setIsBypassChecked(false);
  };

  const bypass_handleOnChange = () => {
    setIsBypassChecked(true);
    setIsCollectChecked(false);
  };

  // Behaviour Technique Selection Checkbox
  const [techChecked, setTechChecked] = useState("");
  const [subTechChecked, setSubTechChecked] = useState("");
  const [drTypes, setDrTypes] = useState("");

  // Not sure what these functions below are for yet...
  useEffect(() => {
    HandleFormNavigation();
    // CustomDropDown();

    let Labels = document.querySelectorAll(
      ".special-select-body input + label"
    );
    Labels.forEach((Label) => {
      Label.addEventListener("click", () => {
        let CountElement = Label.parentNode.parentNode.lastChild.firstChild;

        if (
          Label.textContent === "Continuous Schedule of Reinforcements (CRF)"
        ) {
          CountElement.style.backgroundColor = "gray";
          CountElement.style.color = "#fff";
        } else {
          CountElement.style.backgroundColor = "#fff";
          CountElement.style.color = "#000";
        }
      });
    });
  }, []);

  const history = useHistory();
  const [state, dispatch] = ConnectUseState();
  const [Name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [monthYear, setMonthYear] = useState("");
  useEffect(() => {
    HandleFormNavigation();
  }, []);

  // add event listener
  useEffect(() => {
    AddListItem(".fa-plus-square", ".trash-image");
  }, []);

  const HandleSubmit = (e) => {
    // e.preventDefault()
    dispatch({
      type: "SET_CLIENT",
      client: {
        id: Math.random().toString().substr(3),
        name: Name,
        gender: gender,
        birth: birth,
        serviceHours: serviceHours,
        monthYear: monthYear,
      },
    });
    setName("");
    setGender("");
    setBirth("");
    setServiceHours("");
    setMonthYear("");
  };

  return (
    <div className="AddClientInformation AddNewCurrentProgram">
      <Header current="home" noSticky />
      <CommonHeader
        EALogoWhite={EALogoWhite}
        Name={Name}
        setName={setName}
        gender={gender}
        setGender={setGender}
        birth={birth}
        setBirth={setBirth}
        title="Add New Current Program"
        serviceHours={serviceHours}
        setServiceHours={setServiceHours}
        monthYear={monthYear}
        setMonthYear={setMonthYear}
      />
      <section className="main-form-area">
        <div className="main-form-area-content w-90">
          <div className="navigation-area">
            <div className="main-heading">
              <img src={tick} alt="" />
              <p>Last Auto-Save 2s ago</p>
            </div>

            <ul>
              <li className="save-template-button">
                <button style={{ cursor: "pointer" }}>Saved Templates</button>
              </li>
              <li>
                <a href="#Domain" className="active">
                  Domain
                </a>
              </li>
              <li>
                <a href="#Program-Name">Program Name</a>
              </li>
              <li>
                <a href="#Assigned-ISP-BSP">Assigned ISP/BSP</a>
              </li>
              <li>
                {domain === "Behaviour" ? (
                  <a href="#Technique">Technique</a>
                ) : domain === "Toileting" ? (
                  <a href="#Baseline">Baseline</a>
                ) : null}
              </li>
              <li>
                <a href="#Goal">Goal</a>
              </li>
              <li>
                <a href="#Distriminivite-Stimilus">Discriminitive Stimulus</a>
              </li>
              <li>
                <a href="#Response-Criterion">Response Criterion</a>
              </li>
              <li>
                {domain !== "Toileting" ? (
                  <a href="#Maintenance">Maintenance</a>
                ) : null}
              </li>
              <li>
                <a href="#Mastery-Revision-Criteria">
                  Mastery / Revision Criteria
                </a>
              </li>
              <li>
                <a href="#Teaching-Steps">Teaching Steps</a>
              </li>
              <li>
                {domain === "Toileting" ? null : domain === "MANDING" ? (
                  <a href="#Targets">Targets</a>
                ) : domain === "Behaviour" ? (
                  <a href="#Targets">Targets</a>
                ) : (
                  <a href="#Targets-Tasks">Targets / Task Steps</a>
                )}
              </li>
              <li>
                {domain !== "Toileting" ? (
                  <a href="#Prompting-Procedures"> Prompting Procedures</a>
                ) : null}
              </li>
              <li>
                <a href="#Data-Collection">Data Collection</a>
              </li>
              <li>
                {domain !== "MANDING" ? (
                  domain !== "Behaviour" ? (
                    domain !== "Toileting" ? (
                      <a href="#Task-Analysis">Task Analysis</a>
                    ) : null
                  ) : null
                ) : null}
              </li>
              <li>
                {domain !== "Toileting" ? (
                  <a href="#Error-Correction">Error Correction</a>
                ) : null}
              </li>
              <li>
                <a href="#Stimuli">Stimuli</a>
              </li>
              <li>
                <a href="#Generalization">Generalization</a>
              </li>
              {/* Conditional Schedule of Reinforcement Navigation Option */}
              {domain === "Behaviour" ? null : (
                <li>
                  <a href="#Schedule-of-Reinforcement">
                    Schedule of Reinforcement
                  </a>
                </li>
              )}
            </ul>
          </div>

          <form action="#" className="pure-form-area">
            <span id="Program-Name"></span>
            <span id="Domain"></span>
            <div className="input-collection d-flex">
              <div className="left-side">
                <label htmlFor="">
                  <span className="required_Ast">*</span>
                  Domain
                </label>

                <div className="select-wrapper">
                  <FormsDropDowns
                    head_input={domainVal}
                    list={CurrentProgramsDropdowns.domain}
                    list_fun={setDomain}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="domain"
                  />
                </div>
              </div>

              <div className="right-side">
                <label htmlFor="" required>
                  <span className="required_Ast">*</span> Program Name
                </label>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" placeholder="Please Write Program Name" />
                </div>
              </div>
            </div>
            <span id="Assigned-ISP-BSP"></span>
            <div className="input-collection d-flex">
              <div className="left-side" style={{ marginRight: 0 }}>
                <label htmlFor="">
                  <span className="required_Ast">*</span>Assigned ISP/BSP
                </label>

                <div className="select-wrapper " style={{ width: "50%" }}>
                  <div
                    className="select-heading Assigned-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Assigned-ISP")
                        .classList.toggle("active");
                    }}
                  >
                    {domain === "MANDING" ? (
                      <p>Assigned ISP</p>
                    ) : domain === "Behaviour" ? (
                      <p>Assigned BSP</p>
                    ) : (
                      <p>Assigned ISP</p>
                    )}
                    <i class="fas fa-chevron-down"></i>
                  </div>
                  {domain !== "Behaviour" ? (
                    <ul className="select-body Assigned-ISP">
                      <li
                        onClick={(e) => {
                          e.target
                            .closest(".select-wrapper")
                            .querySelectorAll(" .Assigned-head p")
                            .forEach((Eachp) => {
                              Eachp.textContent = document.querySelector(
                                "#demi-data-Assigned-ISP-3 + label"
                              ).textContent;
                            });
                          document
                            .querySelectorAll(".Assigned-ISP")
                            .forEach((EachVal) => {
                              EachVal.classList.remove("active");
                            });
                        }}
                      >
                        <input
                          type="radio"
                          name="Assigned-ISP/BSP-1"
                          id="demi-data-Assigned-ISP-3"
                        />

                        <label
                          id="Assigned-label"
                          htmlFor="demi-data-Assigned-ISP-3"
                        >
                          ISP - 11/05/2021
                        </label>
                      </li>
                      <li
                        onClick={(e) => {
                          e.target
                            .closest(".select-wrapper")
                            .querySelectorAll(" .Assigned-head p")
                            .forEach((Eachp) => {
                              Eachp.textContent = document.querySelector(
                                "#demi-data-Assigned-ISP-4 + label"
                              ).textContent;
                            });

                          document
                            .querySelectorAll(".Assigned-ISP")
                            .forEach((EachVal) => {
                              EachVal.classList.remove("active");
                            });
                        }}
                      >
                        <input
                          type="radio"
                          name="Assigned-ISP/BSP-1"
                          id="demi-data-Assigned-ISP-4"
                        />

                        <label
                          id="Assigned-label"
                          htmlFor="demi-data-Assigned-ISP-4"
                        >
                          ISP - 11/10/2021
                        </label>
                      </li>
                    </ul>
                  ) : (
                    <ul className="select-body Assigned-ISP">
                      <li
                        onClick={(e) => {
                          e.target
                            .closest(".select-wrapper")
                            .querySelectorAll(".Assigned-head p")
                            .forEach((Eachp) => {
                              Eachp.textContent = document.querySelector(
                                "#demi-data-Assigned-BSP-1 + label"
                              ).textContent;
                            });

                          document
                            .querySelectorAll(".Assigned-ISP")
                            .forEach((EachVal) => {
                              EachVal.classList.remove("active");
                            });
                        }}
                      >
                        <input
                          type="radio"
                          name="Assigned-ISP/BSP-1"
                          id="demi-data-Assigned-BSP-1"
                        />

                        <label
                          id="Assigned-label"
                          htmlFor="demi-data-Assigned-ISP/BSP-1"
                        >
                          BSP - 02/10/2022
                        </label>
                      </li>
                      <li
                        onClick={(e) => {
                          e.target
                            .closest(".select-wrapper")
                            .querySelectorAll(".Assigned-head p")
                            .forEach((Eachp) => {
                              Eachp.textContent = document.querySelector(
                                "#demi-data-Assigned-BSP-2 + label"
                              ).textContent;
                            });

                          document
                            .querySelectorAll(".Assigned-ISP")
                            .forEach((EachVal) => {
                              EachVal.classList.remove("active");
                            });
                        }}
                      >
                        <input
                          type="radio"
                          name="Assigned-BSP-2"
                          id="demi-data-Assigned-BSP-2"
                        />

                        <label
                          id="Assigned-label"
                          htmlFor="demi-data-Assigned-BSP-2"
                        >
                          BSP - 03/10/2022
                        </label>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>

            <span id="Technique"></span>
            <span id="Baseline"></span>
            {/* Conditional Checkboxes different domains */}
            {domain === "Behaviour" ? (
              <div className="input-collection" id="">
                <label htmlFor="Technique">
                  <span className="required_Ast">*</span>Technique
                </label>
                <div className="checkboxes">
                  <div className="checkbox">
                    {/* <input type="checkbox" id="checkbox-FCT" /> */}
                    <input
                      type="radio"
                      name="technique-collection"
                      value="FCT"
                      id="checkbox-FCT"
                      // checked={techChecked}
                      onChange={(e) => setTechChecked(e.target.value)}
                    />
                    <label htmlFor="checkbox-FCT">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Functional Comm. Training</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    {/* <input type="checkbox" id="checkbox-DR" /> */}
                    <input
                      type="radio"
                      name="technique-collection"
                      value="DR"
                      id="checkbox-DR"
                      onChange={(e) => setTechChecked(e.target.value)}
                    />
                    <label htmlFor="checkbox-DR">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Differential Reinforcement</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    {/* <input type="checkbox" id="checkbox-NCR" /> */}
                    <input
                      type="radio"
                      name="technique-collection"
                      value="NCR"
                      id="checkbox-NCR"
                      // checked={techChecked}
                      onChange={(e) => setTechChecked(e.target.value)}
                    />
                    <label htmlFor="checkbox-NCR">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Non-Contingent Reinforcement</p>
                    </label>
                  </div>
                </div>
              </div>
            ) : domain === "Toileting" ? (
              <div className="input-collection" id="">
                <label htmlFor="Baseline">Baseline</label>
                <div className="checkboxes">
                  <div className="checkbox">
                    <input
                      type="radio"
                      name="baseline-collection"
                      id="checkbox-Collect-Baseline"
                      checked={isCollectChecked}
                      onChange={collect_handleOnChange}
                    />
                    <label htmlFor="checkbox-Collect-Baseline">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Collect Baseline</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      name="baseline-collection"
                      id="checkbox-Bypass"
                      checked={isBypassChecked}
                      onChange={bypass_handleOnChange}
                    />
                    <label htmlFor="checkbox-Bypass">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">
                        Baseline Previously Collected (Bypass)
                      </p>
                    </label>
                  </div>
                </div>

                {isCollectChecked === true ? (
                  <div
                    className="select-wrapper"
                    style={{
                      width: "50%",
                      transform: "translateY(40%)",
                      zIndex: "1",
                    }}
                  >
                    <FormsDropDowns
                      head_input={"Baseline Interval"}
                      list={CurrentProgramsDropdowns.baselineToiletingInterval}
                      list_fun={setBaselineInterval}
                      setdropdown={setdropdown}
                      dropdown={dropdown}
                      type="baselined"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {/* Conditional Checkboxes Technique DR Suboptions */}
            {domain === "Behaviour" ? (
              techChecked === "DR" ? (
                <div className="checkboxes">
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="Recommended_DR"
                      onChange={(e) => setSubTechChecked(e.target.value)}
                      name="dr-suboption"
                      id="checkbox-dr-recommended"
                    />
                    <label htmlFor="checkbox-dr-recommended">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Recommended - 3 min</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="Bypass_DR"
                      onChange={(e) => setSubTechChecked(e.target.value)}
                      name="dr-suboption"
                      className="by_pass_wrapper"
                      id="checkbox-dr-bypass"
                    />
                    <label htmlFor="checkbox-dr-bypass">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Bypass - Enter Value here</p>
                      <input
                        type="text"
                        style={{ display: "block" }}
                        className="bypassEnter"
                      />
                    </label>
                  </div>
                </div>
              ) : null
            ) : null}

            {/* Conditional Checkboxes Technique NCR Suboptions */}
            {domain === "Behaviour" ? (
              techChecked === "NCR" ? (
                <div className="checkboxes">
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="Recommended_NCR"
                      onChange={(e) => setSubTechChecked(e.target.value)}
                      name="ncr-suboption"
                      checked
                      id="checkbox-ncr-recommended"
                    />
                    <label htmlFor="checkbox-ncr-recommended">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Recommended - 3 min</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="Bypass_NCR"
                      onChange={(e) => setSubTechChecked(e.target.value)}
                      name="ncr-suboption"
                      className="by_pass_wrapper"
                      id="checkbox-ncr-bypass"
                    />
                    <label htmlFor="checkbox-ncr-bypass">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Bypass - Enter Value here</p>
                      <input
                        type="text"
                        style={{ display: "block" }}
                        className="bypassEnter"
                      />
                    </label>
                  </div>
                </div>
              ) : null
            ) : null}

            {/* Conditional Checkboxes DR Types */}
            {domain === "Behaviour" ? (
              subTechChecked === "Recommended_DR" ? (
                <div className="checkboxes">
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="Incompatible"
                      onChange={(e) => setDrTypes(e.target.value)}
                      name="dr-type"
                      id="checkbox-dr-incompatible"
                    />
                    <label htmlFor="checkbox-dr-incompatible">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Incompatible</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="Other"
                      onChange={(e) => setDrTypes(e.target.value)}
                      name="dr-type"
                      id="checkbox-dr-other"
                    />
                    <label htmlFor="checkbox-dr-other">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Other</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="Alternative"
                      onChange={(e) => setDrTypes(e.target.value)}
                      name="dr-type"
                      id="checkbox-dr-alternative"
                    />
                    <label htmlFor="checkbox-dr-alternative">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Alternative </p>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="LowerRate"
                      onChange={(e) => setDrTypes(e.target.value)}
                      name="dr-type"
                      id="checkbox-dr-lowerrate"
                    />
                    <label htmlFor="checkbox-dr-lowerrate">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Lower Rates</p>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      value="HigherRate"
                      onChange={(e) => setDrTypes(e.target.value)}
                      name="dr-type"
                      id="checkbox-dr-higherrate"
                    />
                    <label htmlFor="checkbox-dr-higherrate">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Higher Rates</p>
                    </label>
                  </div>
                </div>
              ) : null
            ) : null}
            <span id="Goal"></span>
            <div className="input-collection" id="">
              <label htmlFor="Goal" required>
                <span className="required_Ast">*</span>Goal
              </label>
              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea name="" id="Goal" cols="30" rows="10"></textarea>
              </div>
            </div>
            <span id="Distriminivite-Stimilus"></span>
            <div className="input-collection" id="">
              <label htmlFor="Discriminitive-Stimulus" required>
                Discriminitive Stimulus
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Discriminitive-Stimulus"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <span id="Response-Criterion"></span>
            <div className="input-collection" id="">
              <label htmlFor="Response-Criterion" required>
                <span className="required_Ast">*</span> Response Criterion
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Response-Criterion"
                  cols="30"
                  rows="10"
                  placeholder="General Comments, concerns or challenging behaviours"
                ></textarea>
              </div>
            </div>
            <span id="Maintenance"></span>
            {domain === "Toileting" ? null : (
              <div className="input-collection" id="">
                <div className="checkboxes">
                  <label htmlFor="Maintenance" style={{ marginRight: 30 }}>
                    <span className="required_Ast">*</span> Maintenance
                  </label>
                  <div className="checkbox">
                    <input
                      type="radio"
                      name="maintenance-option"
                      id="checkbox-Yes"
                    />

                    <label htmlFor="checkbox-Yes">
                      <p className="name">Yes</p>
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="radio"
                      name="maintenance-option"
                      id="checkbox-No"
                    />

                    <label htmlFor="checkbox-No">
                      <p className="name">No</p>
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            )}
            <span id="Mastery-Revision-Criteria"></span>
            <div className="input-collection" id="">
              <label
                htmlFor="Mastery-Revision-Criteria"
                style={{ marginRight: 30 }}
              >
                <span className="required_Ast">*</span> Mastery / Revision
                Criteria
              </label>
              <div className="selecters-grid">
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-select-body-1")
                        .classList.toggle("active");
                    }}
                  > */}
                  {/* <p>Select Mastery Criterion 1</p>
                  <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Percentage"}
                    list={CurrentProgramsDropdowns.masteryCriterion1}
                    list_fun={setMasteryCriterion1}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-1"
                  />
                  {/* </div>{" "} */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-select-body-2")
                        .classList.toggle("active");
                    }}
                  > */}
                  {/* <p>Select Mastery Criterion 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Timeline"}
                    list={CurrentProgramsDropdowns.masteryCriterion2}
                    list_fun={setMasteryCriterion2}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-2"
                  />
                  {/* </div> */}
                </div>

                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-select-body-2")
                        .classList.toggle("active");
                    }}
                  > */}
                  {/* <p>Select Mastery Criterion 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Therapist/Parent"}
                    list={CurrentProgramsDropdowns.masteryCriterion3}
                    list_fun={setMasteryCriterion3}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-3"
                  />
                  {/* </div> */}
                </div>

                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Revision-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Revision-Criteria-select-body-1")
                        .classList.toggle("active");
                    }}
                  > */}
                  {/* <p>Revision Criteria 1</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Revision Criteria - Percentage"}
                    list={CurrentProgramsDropdowns.revCriteria1}
                    list_fun={setRevisionCriteria1}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="revision-criteria-1"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Revision-2-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Revision-Criteria-select-body-2")
                        .classList.toggle("active");
                    }}
                  > */}
                  {/* <p>Revision Criteria 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Revision Criteria - Timeline"}
                    list={CurrentProgramsDropdowns.revCriteria2}
                    list_fun={setRevisionCriteria2}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="revision-criteria-2"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  <FormsDropDowns
                    head_input={"Revision Criteria - Consecutive"}
                    list={CurrentProgramsDropdowns.revCriteria3}
                    list_fun={setRevisionCriteria3}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="revision-criteria-3"
                  />
                </div>
              </div>
            </div>
            <span id="Teaching-Steps"></span>
            <div className="input-collection" id="">
              <label htmlFor="Teaching-Steps">
                <span className="required_Ast">*</span> Teaching Steps
                <i class="fas fa-plus-square"></i>
              </label>
              <div className="input-wrapper">
                <i class="fas fa-pen"></i>
                <input type="text" id="Teaching-Steps" />
                <img src={Trash} alt="" className="trash-image" />
              </div>
            </div>
            <span id="Task-Analysis"></span>
            {domain !== "MANDING" ? (
              domain !== "Behaviour" ? (
                domain !== "Toileting" ? (
                  <div className="input-collection" id="">
                    <label htmlFor="Task-Analysis">
                      <span className="required_Ast">*</span> Task Analysis (if
                      applicable)
                    </label>
                    <div className="checkboxes">
                      <div className="checkbox">
                        <input
                          type="radio"
                          name="task-analysis-check"
                          id="checkbox-forward-chaining"
                        />
                        <label htmlFor="checkbox-forward-chaining">
                          <div className="custom-checkbox">
                            <i class="fas fa-check"></i>
                          </div>
                          <p className="name">Forward Chaining</p>
                        </label>
                      </div>

                      <div className="checkbox">
                        <input
                          type="radio"
                          name="task-analysis-check"
                          id="checkbox-backward-chaining"
                        />
                        <label htmlFor="checkbox-backward-chaining">
                          <div className="custom-checkbox">
                            <i class="fas fa-check"></i>
                          </div>
                          <p className="name">Backward Chaining</p>
                        </label>
                      </div>

                      <div className="checkbox">
                        <input
                          type="radio"
                          name="task-analysis-check"
                          id="checkbox-total-tasks"
                        />
                        <label htmlFor="checkbox-total-tasks">
                          <div className="custom-checkbox">
                            <i class="fas fa-check"></i>
                          </div>
                          <p className="name">Total Tasks</p>
                        </label>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}

            {/*Conditional for Task Analysis Heading */}
            <span id="Targets"></span>
            <span id="Targets-Tasks"></span>
            {domain === "Toileting" ? (
              <div className="input-collection" id="">
                <label htmlFor="Targets">
                  <span className="required_Ast">*</span> Targets
                  <i class="fas fa-plus-square"></i>
                </label>
                <small>
                  Add each new target as a separate line item, NO digits or
                  symbols (write out number targets)
                </small>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" id="Targets" />
                  <img src={Trash} alt="" className="trash-image" />
                </div>
              </div>
            ) : domain === "MANDING" ? (
              <div className="input-collection">
                <label htmlFor="Targets">
                  <span className="required_Ast">*</span> Targets
                  <i class="fas fa-plus-square"></i>
                </label>
                <small>
                  Add each new target as a separate line item, NO digits or
                  symbols (write out number targets)
                </small>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" id="Targets" />
                  <img src={Trash} alt="" className="trash-image" />
                </div>
              </div>
            ) : domain === "Behaviour" ? (
              <div className="input-collection">
                <label htmlFor="Targets">
                  <span className="required_Ast">*</span> Targets
                  <i class="fas fa-plus-square"></i>
                </label>
                <small>
                  Add each new target as a separate line item, NO digits or
                  symbols (write out number targets)
                </small>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" id="Targets" />
                  <img src={Trash} alt="" className="trash-image" />
                </div>
              </div>
            ) : (
              <div className="input-collection" id="">
                <label htmlFor="Targets-Tasks">
                  <span className="required_Ast">*</span> Targets / Task Steps
                  <i class="fas fa-plus-square"></i>
                </label>
                <small>
                  Add each new target as a separate line item, NO digits or
                  symbols (write out number targets)
                </small>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" id="Targets-Tasks" />
                  <img src={Trash} alt="" className="trash-image" />
                </div>
              </div>
            )}
            <span id="Prompting-Procedures"></span>
            {domain === "Toileting" ? null : (
              <div className="input-collection d-flex" id="">
                <div className="left-side" style={{ marginRight: 0 }}>
                  <label htmlFor="" style={{ marginBottom: 5 }}>
                    <span className="required_Ast">*</span> Prompting Procedures
                  </label>
                  <small style={{ marginBottom: 20, display: "block " }}>
                    Use most to least prompt hierarchy, or least - most
                  </small>

                  <div className="selecters-grid">
                    <div className="select-wrapper" style={{ width: "30%" }}>
                      {/* <div
                      className="select-heading Prompting-Procedures-1-head"
                      onClick={(e) => {
                        document
                          .querySelector(".Prompting-Procedures-select-body-1")
                          .classList.toggle("active");
                      }}
                    > */}
                      {/* <p>Select Prompt Type</p>
                      <i class="fas fa-chevron-down"></i> */}
                      <FormsDropDowns
                        head_input={"Select Prompt Type"}
                        list={CurrentProgramsDropdowns.promptType}
                        list_fun={setPromptType}
                        setdropdown={setdropdown}
                        dropdown={dropdown}
                        type="prompt-type"
                      />
                      {/* </div> */}
                    </div>

                    <div className="select-wrapper" style={{ width: "30%" }}>
                      {/* <div
                      className="select-heading Prompting-Procedures-2-head"
                      onClick={(e) => {
                        document
                          .querySelector(".Prompting-Procedures-select-body-2")
                          .classList.toggle("active");
                      }}
                    > */}
                      {/* <p>Select Hierarchy</p>
                      <i class="fas fa-chevron-down"></i> */}
                      <FormsDropDowns
                        head_input={"Select Prompt Hierarchy"}
                        list={CurrentProgramsDropdowns.promptHierarchy}
                        list_fun={setPromptHierarchy}
                        setdropdown={setdropdown}
                        dropdown={dropdown}
                        type="prompt-hierarchy"
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="left-side" style={{ marginRight: 0 }}>
                    <label
                      htmlFor=""
                      style={{ marginTop: 25, marginBottom: 10 }}
                    >
                      <span className="required_Ast">*</span> Prompt Details
                    </label>
                    {promptType === "Prompt Type - Verbal" &&
                    promptHierarchy === "Least - Most" ? (
                      <small style={{ marginBottom: 20, display: "block " }}>
                        <li>Independent</li>
                        <li>Partial Verbal Prompt</li>
                        <li>Full Verbal Prompt</li>
                      </small>
                    ) : promptType === "Prompt Type - Verbal" &&
                      promptHierarchy === "Most - Least" ? (
                      <small style={{ marginBottom: 20, display: "block " }}>
                        <li>Full Verbal Prompt</li>
                        <li>Partial Verbal Prompt</li>
                        <li>Independent</li>
                      </small>
                    ) : promptType === "Prompt Type - Physical" &&
                      promptHierarchy === "Least - Most" ? (
                      <small style={{ marginBottom: 20, display: "block " }}>
                        <li>Independent</li>
                        <li>Gesture Prompt</li>
                        <li>Partial Physical Prompt</li>
                        <li>Full Physical Prompt</li>
                      </small>
                    ) : promptType === "Prompt Type - Physical" &&
                      promptHierarchy === "Most - Least" ? (
                      <small style={{ marginBottom: 20, display: "block " }}>
                        <li>Full Physical Prompt</li>
                        <li>Partial Physical Prompt</li>
                        <li>Gesture Prompt</li>
                        <li>Independent</li>
                      </small>
                    ) : promptType === "Prompt Type - Position" &&
                      promptHierarchy === "Least - Most" ? (
                      <small style={{ marginBottom: 20, display: "block " }}>
                        <li>Expected Position (Independent)</li>
                        <li>Partial Close Position</li>
                        <li>Full Close Position</li>
                      </small>
                    ) : promptType === "Prompt Type - Position" &&
                      promptHierarchy === "Most - Least" ? (
                      <small style={{ marginBottom: 20, display: "block " }}>
                        <li>Full Close Position</li>
                        <li>Partial Close Position</li>
                        <li>Expected Position (Independent)</li>
                      </small>
                    ) : (
                      <small style={{ marginBottom: 20, display: "block " }}>
                        Generated List HERE
                      </small>
                    )}
                  </div>
                </div>
              </div>
            )}
            <span id="Data-Collection"></span>
            <div className="input-collection" id="">
              <label htmlFor="Data-collection">
                <span className="required_Ast">*</span> Data Collection /
                Graphing
              </label>
              {/* Conditional Checkboxes */}
              {domain === "MANDING" ? (
                <div className="checkboxes">
                  <p className="name">Frequency / Rate</p>
                </div>
              ) : // <div className="checkboxes">
              //   <div className="checkbox">
              //     <input type="checkbox" id="checkbox-Frequency" />
              //     <label htmlFor="checkbox-Frequency">
              //       <div className="custom-checkbox">
              //         <i class="fas fa-check"></i>
              //       </div>
              //       <p className="name">Frequency / Rate</p>
              //     </label>
              //   </div>
              // </div>
              domain === "Behaviour" ? (
                techChecked === "" ? (
                  <div className="checkboxes">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        value="Frequency"
                        onChange={(e) => setDataCollection(e.target.value)}
                        name="data-collection-1"
                        id="checkbox-Frequency"
                      />
                      <label htmlFor="checkbox-Frequency">
                        <div className="custom-checkbox">
                          <i class="fas fa-check"></i>
                        </div>
                        <p className="name">Frequency / Rate</p>
                      </label>
                    </div>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        value="Duration"
                        onChange={(e) => setDataCollection(e.target.value)}
                        name="data-collection-2"
                        id="checkbox-Duration"
                      />
                      <label htmlFor="checkbox-Duration">
                        <div className="custom-checkbox">
                          <i class="fas fa-check"></i>
                        </div>
                        <p className="name">
                          Duration (automatically tracking - Whole/Partial)
                        </p>
                      </label>
                    </div>
                  </div>
                ) : techChecked === "FCT" ? (
                  <div className="checkboxes">
                    <p className="name">Frequency / Rate</p>
                  </div>
                ) : // <div className="checkboxes">
                //   <div className="checkbox">
                //     <input
                //       type="checkbox"
                //       value="Frequency"
                //       onChange={(e) => setDataCollection(e.target.value)}
                //       name="data-collection-1"
                //       id="checkbox-Frequency"
                //     />
                //     <label htmlFor="checkbox-Frequency">
                //       <div className="custom-checkbox">
                //         <i class="fas fa-check"></i>
                //       </div>
                //       <p className="name">Frequency / Rate</p>
                //     </label>
                //   </div>
                // </div>
                techChecked === "DR" ? (
                  <div className="checkboxes">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        value="Percentage"
                        onChange={(e) => setDataCollection(e.target.value)}
                        name="data-collection-1"
                        id="checkbox-Percentage"
                      />
                      <label htmlFor="checkbox-Percentage">
                        <div className="custom-checkbox">
                          <i class="fas fa-check"></i>
                        </div>
                        <p className="name">Percentage</p>
                      </label>
                    </div>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        value="Duration"
                        onChange={(e) => setDataCollection(e.target.value)}
                        name="data-collection-2"
                        id="checkbox-Duration"
                      />
                      <label htmlFor="checkbox-Duration">
                        <div className="custom-checkbox">
                          <i class="fas fa-check"></i>
                        </div>
                        <p className="name">
                          Duration (automatically tracking - Whole/Partial)
                        </p>
                      </label>
                    </div>
                  </div>
                ) : techChecked === "NCR" ? (
                  <div className="checkboxes">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        value="Percentage"
                        onChange={(e) => setDataCollection(e.target.value)}
                        name="data-collection-1"
                        id="checkbox-Percentage"
                      />
                      <label htmlFor="checkbox-Percentage">
                        <div className="custom-checkbox">
                          <i class="fas fa-check"></i>
                        </div>
                        <p className="name">Percentage</p>
                      </label>
                    </div>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        value="Duration"
                        onChange={(e) => setDataCollection(e.target.value)}
                        name="data-collection-2"
                        id="checkbox-Duration"
                      />
                      <label htmlFor="checkbox-Duration">
                        <div className="custom-checkbox">
                          <i class="fas fa-check"></i>
                        </div>
                        <p className="name">
                          Duration (automatically tracking - Whole/Partial)
                        </p>
                      </label>
                    </div>
                  </div>
                ) : null
              ) : (
                <div className="checkboxes">
                  <div className="checkbox">
                    <input type="checkbox" id="checkbox-Trial-by-Trial" />
                    <label htmlFor="checkbox-Trial-by-Trial">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Percentage</p>
                    </label>
                  </div>

                  <div className="checkbox">
                    <input type="checkbox" id="checkbox-Probe" />
                    <label htmlFor="checkbox-Probe">
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">Probe</p>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <span id="Error-Correction"></span>
            {domain === "Toileting" ? null : (
              <div className="input-collection" id="">
                <label htmlFor="error">
                  <span className="required_Ast">*</span> Error Correction
                  <i class="fas fa-plus-square"></i>
                </label>

                <div
                  className="checkbox"
                  style={{ marginTop: 10, marginBottom: 15 }}
                >
                  <input type="checkbox" id="checkbox-error-correction2" />
                  <label htmlFor="checkbox-error-correction2">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">In-App Error Correction</p>
                  </label>
                </div>

                <small>
                  If the learner does not respond or makes an error, follow
                  these steps
                </small>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" id="error" />
                  <img src={Trash} alt="" className="trash-image" />
                </div>
              </div>
            )}
            <span id="Stimuli"></span>
            <div className="input-collection" id="">
              <label htmlFor="Stimuli-input">
                <span className="required_Ast">*</span> Stimuli
                <i class="fas fa-plus-square"></i>
              </label>
              <div className="input-wrapper">
                <i class="fas fa-pen"></i>
                <input type="text" id="Stimuli-input" />
                <img src={Trash} alt="" className="trash-image" />
              </div>
            </div>
            <span id="Generalization"></span>
            <div className="input-collection" id="">
              <label htmlFor="Generalization">
                <span className="required_Ast">*</span>Generalization
              </label>

              <div className="checkboxes">
                <div className="checkbox">
                  <input type="checkbox" id="checkbox-People" />
                  <label htmlFor="checkbox-People">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">People</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input type="checkbox" id="checkbox-Settings" />
                  <label htmlFor="checkbox-Settings">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Settings</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input type="checkbox" id="checkbox-Material" />
                  <label htmlFor="checkbox-Material">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Material</p>
                  </label>
                </div>
              </div>
            </div>
            <span id="Schedule-of-Reinforcement"></span>

            {domain === "MANDING" ? (
              <div
                className="input-collection"
                style={{ borderBottom: "none" }}
                id=""
              >
                <label htmlFor="Targets">
                  <span className="required_Ast">*</span> Schedule Of
                  Reinforcements - Current Targets
                </label>
                <div
                  className="select-container"
                  style={{
                    width: "50%",
                    // left: "50%",
                    transform: "translateX(0%) translateY(0%)",
                  }}
                >
                  <div className="select-wrapper">
                    {/* <h4>Current Targets</h4> */}
                    <ul className="special-select-body Targats-select-body">
                      <li id="step-1-1">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-manding"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-manding"
                        >
                          Continuous Schedule of Reinforcements (CRF)
                        </label>
                      </li>
                      <li id="step-1-2">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-2"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-2"
                        >
                          Fixed Ratio
                        </label>
                      </li>
                      <li id="step-1-3">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-3"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-3"
                        >
                          Variable Ratio
                        </label>
                      </li>
                      <li id="step-1-4">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-4"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-4"
                        >
                          Fixed Interval
                        </label>
                      </li>
                      <li id="step-1-5">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-5"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-5"
                        >
                          Variable Interval
                        </label>
                      </li>
                      <li className="important-variable">
                        <div className="count">
                          <div
                            className="arrow arrow-left"
                            id="step-1-arrow"
                            onClick={(e) => {
                              if (FirstInteger > 1)
                                setFirstInteger(FirstInteger - 1);
                            }}
                          >
                            <i class="fas fa-arrow-left"></i>
                          </div>
                          <div className="number">
                            <p>{FirstInteger}</p>
                          </div>
                          <div
                            className="arrow-right arrow"
                            onClick={(e) => {
                              setFirstInteger(FirstInteger + 1);
                            }}
                            id="step-1-arrow"
                          >
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : domain === "Toileting" ? (
              <div
                className="input-collection"
                style={{ borderBottom: "none" }}
              >
                <label htmlFor="Targets">
                  <span className="required_Ast">*</span> Schedule Of
                  Reinforcements - Current Targets
                </label>
                <div
                  className="select-container"
                  style={{
                    width: "40%",
                    // left: "50%",
                    transform: "translateX(0%) translateY(0%)",
                  }}
                >
                  <div className="select-wrapper">
                    {/* <h4>Current Targets</h4> */}
                    <ul className="special-select-body Targats-select-body">
                      <li id="step-1-1">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-toileting"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-toileting"
                        >
                          Continuous Schedule of Reinforcements (CRF)
                        </label>
                      </li>
                      <li id="step-1-2">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-2"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-2"
                        >
                          Fixed Ratio
                        </label>
                      </li>
                      <li id="step-1-3">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-3"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-3"
                        >
                          Variable Ratio
                        </label>
                      </li>
                      <li id="step-1-4">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-4"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-4"
                        >
                          Fixed Interval
                        </label>
                      </li>
                      <li id="step-1-5">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-5"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-5"
                        >
                          Variable Interval
                        </label>
                      </li>
                      <li className="important-variable">
                        <div className="count">
                          <div
                            className="arrow arrow-left"
                            id="step-1-arrow"
                            onClick={(e) => {
                              if (FirstInteger > 1)
                                setFirstInteger(FirstInteger - 1);
                            }}
                          >
                            <i class="fas fa-arrow-left"></i>
                          </div>
                          <div className="number">
                            <p>{FirstInteger}</p>
                          </div>
                          <div
                            className="arrow-right arrow"
                            onClick={(e) => {
                              setFirstInteger(FirstInteger + 1);
                            }}
                            id="step-1-arrow"
                          >
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : domain === "Behaviour" ? null : (
              <div
                className="input-collection"
                style={{ borderBottom: "none" }}
                id="Schedule-of-Reinforcement"
              >
                <label htmlFor="Targets">
                  <span className="required_Ast">*</span>Schedule Of
                  Reinforcements
                </label>
                <div
                  className="select-container"
                  style={{
                    width: "60%",
                    // left: "50%",
                    transform: "translateX(10%) translateY(0%)",
                  }}
                >
                  <div className="select-wrapper">
                    <h4>Current Targets</h4>
                    <ul className="special-select-body Targats-select-body">
                      <li id="step-1-1">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-1"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-1"
                        >
                          Continuous Schedule of Reinforcements (CRF)
                        </label>
                      </li>
                      <li id="step-1-2">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-2"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-2"
                        >
                          Fixed Ratio
                        </label>
                      </li>
                      <li id="step-1-3">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-3"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-3"
                        >
                          Variable Ratio
                        </label>
                      </li>
                      <li id="step-1-4">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-4"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-4"
                        >
                          Fixed Interval
                        </label>
                      </li>
                      <li id="step-1-5">
                        <input
                          type="radio"
                          name="Current-Targats-1"
                          id="demi-data-Current-Targats-5"
                        />

                        <label
                          id="Current-Targats-label"
                          htmlFor="demi-data-Current-Targats-5"
                        >
                          Variable Interval
                        </label>
                      </li>

                      <li className="important-variable">
                        <div className="count">
                          <div
                            className="arrow arrow-left"
                            id="step-1-arrow"
                            onClick={(e) => {
                              if (FirstInteger > 1)
                                setFirstInteger(FirstInteger - 1);
                            }}
                          >
                            <i class="fas fa-arrow-left"></i>
                          </div>
                          <div className="number">
                            <p>{FirstInteger}</p>
                          </div>
                          <div
                            className="arrow-right arrow"
                            onClick={(e) => {
                              setFirstInteger(FirstInteger + 1);
                            }}
                            id="step-1-arrow"
                          >
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="select-wrapper"
                    style={{
                      width: "60%",
                      // left: "50%",
                      transform: "translateX(40%) translateY(0%)",
                    }}
                  >
                    <h4>
                      <span className="required_Ast">*</span>Maintenance
                    </h4>
                    <ul className="special-select-body maintenance-select-body">
                      <li id="step-2-1">
                        <input
                          type="radio"
                          name="Maintenance-1"
                          id="demi-data-Maintenance-1"
                        />
                        <label
                          id="Maintenance-label"
                          htmlFor="demi-data-Maintenance-1"
                        >
                          Continuous Schedule of Reinforcements (CRF)
                        </label>
                      </li>
                      <li id="step-2-2">
                        <input
                          type="radio"
                          name="Maintenance-1"
                          id="demi-data-Maintenance-2"
                        />

                        <label
                          id="Maintenance-label"
                          htmlFor="demi-data-Maintenance-2"
                        >
                          Fixed Ratio
                        </label>
                      </li>
                      <li id="step-2-3">
                        <input
                          type="radio"
                          name="Maintenance-1"
                          id="demi-data-Maintenance-3"
                        />

                        <label
                          id="Maintenance-label"
                          htmlFor="demi-data-Maintenance-3"
                        >
                          Variable Ratio
                        </label>
                      </li>
                      <li id="step-2-4">
                        <input
                          type="radio"
                          name="Maintenance-1"
                          id="demi-data-Maintenance-4"
                        />

                        <label
                          id="Maintenance-label"
                          htmlFor="demi-data-Maintenance-4"
                        >
                          Fixed Interval
                        </label>
                      </li>
                      <li id="step-2-5">
                        <input
                          type="radio"
                          name="Maintenance-1"
                          id="demi-data-Maintenance-5"
                        />

                        <label
                          id="Maintenance-label"
                          htmlFor="demi-data-Maintenance-5"
                        >
                          Variable Interval
                        </label>
                      </li>
                      <li className="important-variable">
                        <div className="count">
                          <div
                            className="arrow arrow-left"
                            onClick={(e) => {
                              if (SecondInteger > 1)
                                setSecondInteger(SecondInteger - 1);
                            }}
                            id="step-2-arrow"
                          >
                            <i class="fas fa-arrow-left"></i>
                          </div>
                          <div className="number">
                            <p>{SecondInteger}</p>
                          </div>
                          <div
                            className="arrow-right arrow"
                            onClick={(e) => {
                              setSecondInteger(SecondInteger + 1);
                            }}
                            id="step-2-arrow"
                          >
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            <div className="buttons-wrapper">
              <input
                type="submit"
                id="save"
                value=""
                style={{ display: "none" }}
              />
              <input
                type="submit"
                id="Save-Template"
                value=""
                style={{ display: "none" }}
              />
              <Link to="/home">
                {" "}
                <label htmlFor="close" id="close-label">
                  Close
                </label>
              </Link>
              <label htmlFor="Save-Template" id="Save-Template-label">
                Save Template
              </label>
              <label htmlFor="save" id="submit-label">
                Submit
              </label>
              <input
                type="submit"
                id="close"
                value=""
                style={{ display: "none" }}
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default AddNewCurrentProgram;
