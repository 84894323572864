import React from "react";
import styles from "./ResultBoxArea.module.css";
export const ResultBoxArea = ({
  lists,
  title,
  buttons = null,
  buttonSingle = null,
}) => {
  return (
    <div className={styles.ResultBoxArea}>
      <div className={styles.box_title}>
        <h1>{title}</h1>
      </div>
      <div className={styles.list_wrapper}>
        {lists.map((Each) => (
          <p>{Each}</p>
        ))}
        {buttons && (
          <div
            className={`${styles.buttonWrapper} ${
              buttonSingle && styles.buttonSingle
            }`}
          >
            <button>Submit</button>
            {buttonSingle == null && <button>Hold / Reintroduce</button>}
          </div>
        )}
      </div>
    </div>
  );
};
