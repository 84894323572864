import React from "react";

export const SessionNotes = () => {
  return (
    <div className="ParentHomeWork NotesWritingAea">
      <div className="top">
        <h3>Session Notes</h3>
        <button>Download PDF</button>
      </div>
      <div className="instructor_observerd">
        <p>Instructor Assigned:</p>
        <input type="text" />
      </div>{" "}
      <div className="textareaWrapper">
        <textarea name="" id="" cols="30" rows="10"></textarea>
      </div>
    </div>
  );
};
