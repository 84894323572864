import React from "react";

export const RequestTable = () => {
  const HandleSeperateDescisionSelection = (e) => {
    let OuterParentElement = e.target.parentElement.parentElement.nextSibling;
    OuterParentElement.classList.add("active");
  };
  const HandleSelectionBox = (e) => {
    let Value = e.target.value;

    let ApproveCheckbox = document.querySelectorAll(".approve-checkbox");
    let AllCheckbox = document.querySelectorAll(".row-checkbox");
    let RejectCheckbox = document.querySelectorAll(".reject-checkbox");
    let MessageForm = document.querySelectorAll(".message-form");

    if (Value === "Approve") {
      ApproveCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });

      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });

      MessageForm.forEach((EachForm) => {
        EachForm.classList.add("active");
      });
    } else if (Value === "Reject") {
      RejectCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });

      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });
      MessageForm.forEach((EachForm) => {
        EachForm.classList.add("active");
      });
    } else if (Value === "select-all") {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });
    } else if (Value === "select-clear") {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
      RejectCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
      ApproveCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
      MessageForm.forEach((EachForm) => {
        EachForm.classList.remove("active");
      });
    }
  };

  const HandleMainCheckbox = (e) => {
    let AllCheckbox = document.querySelectorAll(".row-checkbox");
    if (e.target.checked) {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });
    } else {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
    }
  };

  return (
    <form className="notification-table-area">
      <div className="notification-table-top">
        <div className="col-1 drop-down-area">
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox-People"
              onChange={HandleMainCheckbox}
              className="row-checkbox"
            />
            <label htmlFor="checkbox-People">
              <div className="custom-checkbox">
                <i class="fas fa-check"></i>
              </div>
            </label>
          </div>
          <div
            className="checkbox-icon-wrapper "
            onClick={(e) => {
              document
                .querySelector(".selection-dropdown")
                .classList.toggle("active");
            }}
          >
            <i class="fas fa-chevron-down"></i>
          </div>
          <h4>Details</h4>

          <ul className="selection-dropdown">
            {/* <li>
            <input
              type="radio"
              name="details"
              onChange={HandleSelectionBox}
              value="select-all"
              id="all-select"
            />
            <label htmlFor="all-select">Select All</label>
          </li> */}

            <li>
              <input
                type="radio"
                name="details"
                onChange={HandleSelectionBox}
                value="Approve"
                id="approve"
              />
              <label htmlFor="approve">Approve</label>
            </li>
            <li>
              <input
                type="radio"
                name="details"
                onChange={HandleSelectionBox}
                value="Reject"
                id="reject"
              />
              <label htmlFor="reject">Reject</label>
            </li>
            <li>
              <input
                type="radio"
                name="details"
                onChange={HandleSelectionBox}
                value="select-clear"
                id="clear-select"
              />
              <label htmlFor="clear-select">Clear Selection</label>
            </li>
          </ul>
        </div>
        <div className="col-2">
          <h4>Created By</h4>
        </div>
        <div className="col-3">
          <h4>Date</h4>
        </div>
        <div className="col-4">
          <h4>Actions</h4>
        </div>
      </div>

      {/* table body */}

      <div className="notification-table-body-area">
        <div className="notification-table-body-row active">
          <div className="col-1">
            <div className="checkbox">
              <input
                type="checkbox"
                id="table-body-row-1-checkbox"
                className="row-checkbox"
              />
              <label htmlFor="table-body-row-1-checkbox">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
              </label>
            </div>

            <p>Duis rhoncus dui venenatis</p>
          </div>

          <div className="col-2">
            <p>Craig Palmer</p>
          </div>
          <div className="col-3">
            <p>12/28/2021</p>
          </div>
          <div className="col-4">
            <div className="checkbox">
              <input
                type="radio"
                name="decision-1"
                id="checkbox-Approve-1"
                className="approve-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Approve-1">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Approve</p>
              </label>
            </div>

            <div className="checkbox">
              <input
                type="radio"
                name="decision-1"
                id="checkbox-Reject-1"
                className="reject-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Reject-1">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Reject</p>
              </label>
            </div>
          </div>

          {/* form */}
          <form action="" className="message-form">
            <div className="text-area-wrapper">
              <i class="fas fa-pen"></i>
              <textarea
                name=""
                id="Goal"
                cols="30"
                rows="10"
                placeholder="Please Write Approval or Rejection Comments"
              ></textarea>

              <input type="submit" id="sender1" />
              <label htmlFor="sender1">
                <i class="far fa-paper-plane"></i>
              </label>
            </div>
          </form>
        </div>

        <div className="notification-table-body-row">
          <div className="col-1">
            <div className="checkbox">
              <input
                type="checkbox"
                id="table-body-row-2-checkbox"
                className="row-checkbox"
              />
              <label htmlFor="table-body-row-2-checkbox">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
              </label>
            </div>

            <p>In at iaculis lorem. Praesent tempor</p>
          </div>

          <div className="col-2">
            <p>Craig Palmer</p>
          </div>
          <div className="col-3">
            <p>12/28/2021</p>
          </div>
          <div className="col-4">
            <div className="checkbox">
              <input
                type="radio"
                name="decision-2"
                id="checkbox-Approve-2"
                className="approve-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Approve-2">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Approve</p>
              </label>
            </div>

            <div className="checkbox">
              <input
                type="radio"
                name="decision-2"
                id="checkbox-Reject-2"
                className="reject-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Reject-2">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Reject</p>
              </label>
            </div>
          </div>

          {/* form */}
          <form action="" className="message-form">
            <div className="text-area-wrapper">
              <i class="fas fa-pen"></i>
              <textarea
                name=""
                id="Goal"
                cols="30"
                rows="10"
                placeholder="Please Write Approval or Rejection Comments"
              ></textarea>
              <input type="submit" id="sender1" />
              <label htmlFor="sender1">
                <i class="far fa-paper-plane"></i>
              </label>
            </div>
          </form>
        </div>
        <div className="notification-table-body-row">
          <div className="col-1">
            <div className="checkbox">
              <input
                type="checkbox"
                id="table-body-row-3-checkbox"
                className="row-checkbox"
              />
              <label htmlFor="table-body-row-3-checkbox">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
              </label>
            </div>

            <p>In at iaculis lorem. Praesent tempor</p>
          </div>

          <div className="col-2">
            <p>Craig Palmer</p>
          </div>
          <div className="col-3">
            <p>12/28/2021</p>
          </div>
          <div className="col-4">
            <div className="checkbox">
              <input
                type="radio"
                name="decision-3"
                id="checkbox-Approve-3"
                className="approve-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Approve-3">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Approve</p>
              </label>
            </div>

            <div className="checkbox">
              <input
                type="radio"
                name="decision-3"
                id="checkbox-Reject-3"
                className="reject-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Reject-3">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Reject</p>
              </label>
            </div>
          </div>

          {/* form */}
          <form action="" className="message-form">
            <div className="text-area-wrapper">
              <i class="fas fa-pen"></i>
              <textarea
                name=""
                id="Goal"
                cols="30"
                rows="10"
                placeholder="Please Write Approval or Rejection Comments"
              ></textarea>
              <input type="submit" id="sender1" />
              <label htmlFor="sender1">
                <i class="far fa-paper-plane"></i>
              </label>
            </div>
          </form>
        </div>

        <div className="notification-table-body-row">
          <div className="col-1">
            <div className="checkbox">
              <input
                type="checkbox"
                id="table-body-row-4-checkbox"
                className="row-checkbox"
              />
              <label htmlFor="table-body-row-4-checkbox">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
              </label>
            </div>

            <p>In at iaculis lorem. Praesent tempor</p>
          </div>

          <div className="col-2">
            <p>Craig Palmer</p>
          </div>
          <div className="col-3">
            <p>12/28/2021</p>
          </div>
          <div className="col-4">
            <div className="checkbox">
              <input
                type="radio"
                name="decision-4"
                id="checkbox-Approve-4"
                className="approve-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Approve-4">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Approve</p>
              </label>
            </div>

            <div className="checkbox">
              <input
                type="radio"
                name="decision-4"
                id="checkbox-Reject-4"
                className="reject-checkbox"
                onChange={HandleSeperateDescisionSelection}
              />
              <label htmlFor="checkbox-Reject-4">
                <div className="custom-checkbox">
                  <i class="fas fa-check"></i>
                </div>
                <p className="name">Reject</p>
              </label>
            </div>
          </div>

          {/* form */}
          <form action="" className="message-form">
            <div className="text-area-wrapper">
              <i class="fas fa-pen"></i>
              <textarea
                name=""
                id="Goal"
                cols="30"
                rows="10"
                placeholder="Please Write Approval or Rejection Comments"
              ></textarea>
              <input type="submit" id="sender1" />
              <label htmlFor="sender1">
                <i class="far fa-paper-plane"></i>
              </label>
            </div>
          </form>
        </div>
      </div>
    </form>
  );
};
