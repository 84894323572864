import React, { useState } from "react";
import Header from "../component/Header";
import EALogoWhite from "../../Assets/img/EA logo_wht.png";
import "../../Assets/style/css/notification.css";
import { RequestTable } from "../component/notification/RequestTable";
import { TargetTable } from "../component/notification/TargetTable";
import { RemindersTable } from "../component/notification/RemindersTable";
function Notification() {
  const [tabState, setTabState] = useState(1);
  return (
    <div className="Notification">
      <Header current="notification" />

      <div className="profile-information" style={{ padding: "0rem 0rem" }}>
        <div className="profile-information-content w-90">
          <img src={EALogoWhite} alt="" className="profile-ea-logo-white" />
          <div className="profile-about">
            <div className="left">
              <small>Employee ID/ Email</small>
              <h1>
                Jessica Ryle <span>(12342151632)</span>
              </h1>
              <p className="email">info@endlessabilities.com</p>
            </div>
            <div className="right">
              <small>Login</small>
              <div className="login-information">
                <div className="date">
                  <i class="far fa-calendar"></i>
                  <p>11/05/2020</p>
                </div>
                <div className="time">
                  <i class="far fa-clock"></i>
                  <p>02:16:00 pm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="notification-wrapper">
        <div className="notification-wrapper-content w-90">
          <div className="top-area">
            <div
              className={`box ${tabState == 1 && "active"}`}
              onClick={(e) => {
                setTabState(1);
              }}
            >
              <i class="fas fa-check"></i>

              <h3>Requests</h3>
              <p className="badge">4</p>
            </div>
            <div
              className={`box ${tabState == 2 && "active"}`}
              onClick={(e) => {
                setTabState(2);
              }}
            >
              <i class="fas fa-info"></i>
              <h3>Targets Mastered</h3>
              <p className="badge">2</p>
            </div>
            {/* <div className="box">
              <i class="fas fa-flag"></i>
              <h3>Announcements</h3>
              <p className="badge">14</p>
            </div> */}
            <div
              className={`box ${tabState == 3 && "active"}`}
              onClick={(e) => {
                setTabState(3);
              }}
            >
              <i class="fas fa-bell"></i>
              <h3>Reminders</h3>
              <p className="badge">6</p>
            </div>
          </div>

          {/* table */}
          {tabState == 1 && <RequestTable />}
          {tabState == 2 && <TargetTable />}
          {tabState == 3 && <RemindersTable />}
        </div>
      </div>
    </div>
  );
}

export default Notification;
