import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const CommonHeader = ({
  EALogoWhite,
  Name,
  setName,
  gender,
  setGender,
  birth,
  setBirth,
  serviceHours,
  setServiceHours,
  monthYear,
  title,
  setMonthYear,
}) => {
  const [NameStorage, setNameStorage] = useState("");
  const [DateState, setDate] = useState("");
  useEffect(() => {
    let Name = window.sessionStorage.getItem("name");
    let date = new Date();
    let Formate =
      date.getDate() +
      "/" +
      `${
        date.getMonth() + 1 > 10
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`
      }` +
      "/" +
      date.getFullYear();

    setNameStorage(Name);
    setDate(Formate);
  }, []);

  return (
    <div className="profile-information">
      <div className="top-area">
        <div className="top-area-content">
          <Link to="/home">
            <i class="fas fa-arrow-circle-left"></i>
          </Link>
          <h1>{title}</h1>
        </div>
      </div>
      <div className="profile-information-content w-90">
        <img src={EALogoWhite} alt="" className="profile-ea-logo-white" />
        <div className="profile-about">
          <input type="submit" value="" style={{ display: "none" }} />
          <div className="input-container">
            <label htmlFor="">Name/Client ID</label>
            <div className="input-wrapper">
              <i class="fas fa-pen"></i>
              <input
                type="text"
                readOnly
                value={NameStorage}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="input-container">
            <label htmlFor="">Gender</label>
            <div className="input-wrapper">
              <i class="fas fa-pen"></i>
              <input
                type="text"
                value={gender}
                readOnly
                onChange={(e) => setGender(e.target.value)}
              />
            </div>
          </div>

          <div className="input-container">
            <label htmlFor="">Date of Birth</label>
            <div className="input-wrapper">
              <input
                type="text"
                value={DateState}
                onChange={(e) => setBirth(e.target.value)}
              />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="">Service Hours</label>
            <div className="input-wrapper">
              <i class="fas fa-pen"></i>
              <input
                type="text"
                value={serviceHours}
                onChange={(e) => setServiceHours(e.target.value)}
              />
            </div>
          </div>
          <div className="input-container month">
            <label htmlFor="">Month Year</label>
            <div className="input-wrapper">
              <input
                type="date"
                value={monthYear}
                onChange={(e) => setMonthYear(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommonHeader;
