import React from "react";

export const BehaviourNotes = () => {
  return (
    <div className="BehaviourNotesWrapper">
      <div className="BehaviourNotes">
        <ul>
          <li>Date/Time Therapist</li>
          <li>Antecedent</li>
          <li>Behaviour</li>
          <li>Consequence</li>
        </ul>
        <div className="body_area">
          <ul>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="What happened 
before the 
behaviour occurred..."
              ></textarea>
            </li>
            <li>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Description of
the behaviour"
              ></textarea>
            </li>
            <li>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="After effects, did the
therapist react or not."
              ></textarea>
            </li>
          </ul>

          <ul>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
          </ul>
          <ul>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
          </ul>
          <ul>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
          </ul>
          <ul>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
            <li>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
