import React from "react";
import { Link } from "react-router-dom";
const AddData = ({ link, code, name }) => {
  const SetId = (e) => {
    window.sessionStorage.setItem("id", code);
    window.sessionStorage.setItem("name", name);
  };
  return (
    <Link
      class="add-box Individual-Support-Plan"
      to={link}
      onClick={(e) => SetId()}
    >
      <i class="fas fa-plus"></i>
    </Link>
  );
};
export default AddData;
