import React from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderLogo from "../../Assets/img/logo.png";
function Header({
  current,
  noSticky = null,
  search = null,
  SearchClient = null,
}) {
  const history = useHistory();
  return (
    <div className={`Header ${noSticky && "noSticky"}`}>
      <div className="header-content w-90">
        <Link className="header-content w-10" to="/home">
          <img src={HeaderLogo} alt="" className="logo" />
        </Link>
        <nav className="main-header-nav">
          <li>
            <Link to="/home" className={`${current === "home" && "active"}`}>
              <i class="fas fa-home"></i>
              <p>Home</p>
            </Link>
          </li>
          <li>
            <Link
              to="/AssignInstructor"
              className={`${current === "instructor" && "active"}`}
            >
              <i class="fas fa-user-alt"></i>
              <p>Assign Instructor</p>
            </Link>
          </li>
          <li>
            <Link
              to="/notification"
              className={`${current === "notification" && "active"}`}
            >
              <i class="fas fa-bell"></i>
              <p>Notifications</p>
            </Link>
          </li>
        </nav>

        <form action="">
          {search && (
            <div className="input_wrwapper">
              <input
                type="text"
                placeholder="Search...."
                onChange={(e) => {
                  SearchClient(e.target.value);
                }}
              />
            </div>
          )}

          <Link to="/">
            <button className="sign-out-button">Sign-Out</button>
          </Link>
        </form>
      </div>
    </div>
  );
}

export default Header;
