import React, { useState } from "react";
import { ResultDropDown } from "../ResultDropDown";
import { PerTherapist, SelectNotesDate } from "./constants";
import { ResultNavigation } from "../ResultNavigation";
import { SeniorTherapistNotes } from "./SeniorTherapistNotes";
import { ClinicalSupervisorNotes } from "./ClinicalSupervisorNotes";
import { ParentHomeWork } from "./ParentHomeWork";
import { SessionNotes } from "./SessionNotes";
import { Combined } from "./Combined";
import { Therapist } from "./Therapist";

export const RawDataBodyAreaBox = ({ active }) => {
  const [Notes, setNotes] = useState("Combined");
  return (
    <div className="NotesBodyAreaBox">
      <ResultNavigation active={active} />

      <div className="NotesBodyAreaBoxContentArea">
        <div>
          <div className="dropdownWrapper">
            <h2>Per Therapist</h2>
            <ResultDropDown list={PerTherapist} setNotes={setNotes} />
          </div>{" "}
          <div className="dropdownWrapper">
            <h2>Date</h2>
            <ResultDropDown list={SelectNotesDate} />
          </div>
        </div>
        <div className="RawDataWritingArea">
          {Notes == "Combined" ? <Combined /> : <Therapist />}
        </div>
      </div>
    </div>
  );
};
