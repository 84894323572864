import React from "react";
import { Link } from "react-router-dom";
const UploadNewFiles = () => {
  return (
    <Link to="/home/ConsentUpload" class="edit-option upload-files-consent">
      <p>Upload New Files</p>
      <i class="fas fa-cloud-upload-alt"></i>
    </Link>
  );
};
export default UploadNewFiles;
