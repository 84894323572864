import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const ResultDropDown = ({
  list,
  setNotes = null,
  function_custom = null,
  SettingsDropdown = null,
  setSettingsDropdown = null,
  custom_head = null,
}) => {
  const [head, setHeadName] = useState("Select one");
  useEffect(() => {}, [SettingsDropdown]);
  return (
    <div className="ResultDropDown">
      <div className="head">
        {custom_head ? <p>{custom_head}</p> : <p>{head}</p>}

        {custom_head == null && <i class="fas fa-chevron-down"></i>}
      </div>
      <ul>
        {list.map((EachItem) => (
          <li>
            <Link
              onClick={(e) => {
                if (function_custom) {
                  function_custom(EachItem.name);
                } else if (setNotes != null) {
                  setNotes(EachItem.name);
                }
                setHeadName(EachItem.name);
                setSettingsDropdown && setSettingsDropdown(EachItem.name);
              }}
            >
              {EachItem.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
