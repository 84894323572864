import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export const FormsDropDowns = ({
  head_input,
  list,
  list_fun,
  setdropdown,
  dropdown,
  type,

  setDropDownData = null,
}) => {
  const [head, setHead] = useState(head_input);
  // const [type]
  return (
    <div className="ResultDropDown">
      <div
        className="head"
        onClick={(e) => {
          dropdown === type ? setdropdown(null) : setdropdown(type);
        }}
      >
        <p>{head}</p>
        <i class="fas fa-chevron-down"></i>
      </div>
      {dropdown === type && (
        <ul>
          {list.map((EachItem) => (
            <li
              onClick={(e) => {
                setdropdown(null);
                setHead(EachItem.name);
              }}
            >
              <Link
                onClick={(e) => {
                  list_fun(EachItem.name);
                  setDropDownData(EachItem);
                  setdropdown(null);
                  setHead(EachItem.name);
                }}
              >
                {EachItem.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
