import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PopUpConfirm } from "./PopUpConfirm";
const EditBox = ({
  heading,
  lock = null,
  StateOriginal,
  SettingState,
  id,
  date,
  lockVal,
}) => {
  const [active, setActive] = useState(false);
  const [hover, sethover] = useState(false);
  const [popup, showpopup] = useState(false);

  const cancel = (e) => {
    showpopup(false);
  };
  const deletefun = (e) => {
    let FilterData = StateOriginal.filter((Each) => Each.id != id);
    SettingState(FilterData);
    showpopup(false);
  };
  return (
    <div className="lock-box notification-box">
      {popup && <PopUpConfirm cancel={cancel} deletefun={deletefun} />}
      <div className={`top-area ${!lock && "not_locked"}`}>
        {lock && (
          <div
            className={`action-image ${lockVal == true && "active"}`}
            id="Judd-Trump-lock-2"
            onClick={(e) => {
              setActive(!active);
              let FilterDataExceptLock = StateOriginal.filter(
                (Each) => Each.id != id
              );
              let FilterDataLock = StateOriginal.filter((Each) => {
                if (Each.id == id) {
                  if (Each.lockVal == true) {
                    Each.lockVal = false;
                  } else {
                    Each.lockVal = true;
                  }

                  return 1;
                }
              });

              SettingState([...FilterDataExceptLock, ...FilterDataLock]);
            }}
          ></div>
        )}
        <div
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            showpopup(true);
          }}
        >
          <i class="fas fa-times-circle"></i>
        </div>
      </div>

      <div className="presentation">
        <Link to="/home/pdf-insert-behaviour">
          <h2>{heading}</h2>
        </Link>
        <div
          className="buttons-wrapper"
          onMouseEnter={(e) => {
            sethover(true);
          }}
          onMouseLeave={(e) => {
            sethover(false);
          }}
        >
          {hover && (
            <>
              {!active && <Link>Edit</Link>}

              <Link>View PDF</Link>
            </>
          )}
        </div>
        <div className="edit-detail">
          <h6>Last Edit</h6>
          <p>{date}</p>
        </div>
      </div>
    </div>
  );
};
export default EditBox;
