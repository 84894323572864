import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { withAuthenticator, Button, Heading } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Logo from "../../Assets/img/logo.png";
import "../../Assets/style/css/login.css";
import { Link } from "react-router-dom";

// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState(null);

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     try {
//       await Auth.signIn(email, password);
//       // Redirect to Home or other page upon successful login
//       window.location.href = "/Home";
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   return (
//     <div className="Login">
//       <img src={Logo} alt="Logo" />
//       <form onSubmit={handleLogin}>
//         <div className="input-wrapper">
//           <i className="fas fa-pen"></i>
//           <input
//             type="text"
//             placeholder="Employee ID/Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//         </div>
//         <div className="input-wrapper">
//           <i className="fas fa-pen"></i>
//           <input
//             type="password"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//         </div>

//         <button className="login-button" type="submit">
//           Login
//         </button>
//         {error && <p className="error">{error}</p>}

//         <p className="or-divider">
//           ---------------------- or ----------------------
//         </p>
//         <Link className="sign-up-link" to="/signup">
//           Sign Up
//         </Link>
//       </form>
//     </div>
//   );
// }

// export default Login;

function Login() {
  return (
    <div className="Login">
      <img src={Logo} alt="" />
      <form>
        <div className="input-wrapper">
          <i class="fas fa-pen"></i>
          <input type="text" placeholder="Employee ID/Email" />
        </div>
        <div className="input-wrapper">
          <i class="fas fa-pen"></i>
          <input type="text" placeholder="Password" />
        </div>

        <Link className="login-button" to="/Home">
          Login
        </Link>
        <p className="or-divider">
          ---------------------- or ----------------------
        </p>
        <Link className="sign-up-link">Sign Up</Link>
      </form>
    </div>
  );
}

export default Login;
