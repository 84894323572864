import React from "react";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);
const data = {
  labels: [
    ["11/1/21", "Fady B", "AM"],
    ["11/1/22", "Fady B", "AM"],
    ["11/1/23", "Fady B", "AM"],
    ["11/1/24", "Fady B", "AM"],
    ["11/1/25", "Fady B", "AM"],
    ["11/1/26", "Fady B", "AM"],
    ["11/1/27", "Fady B", "AM"],
    ["11/1/28", "Fady B", "AM"],
    ["11/1/29", "Fady B", "AM"],
    ["11/1/21", "Fady B", "AM"],
    ["11/1/22", "Fady B", "AM"],
    ["11/1/23", "Fady B", "AM"],
    ["11/1/24", "Fady B", "AM"],
    ["11/1/25", "Fady B", "AM"],
    ["11/1/26", "Fady B", "AM"],
    ["11/1/27", "Fady B", "AM"],
    ["11/1/28", "Fady B", "AM"],
    ["11/1/29", "Fady B", "AM"],
    ["11/1/21", "Fady B", "AM"],
    ["11/1/22", "Fady B", "AM"],
    ["11/1/23", "Fady B", "AM"],
    ["11/1/24", "Fady B", "AM"],
    ["11/1/25", "Fady B", "AM"],
    ["11/1/26", "Fady B", "AM"],
    ["11/1/27", "Fady B", "AM"],
    ["11/1/28", "Fady B", "AM"],
    ["11/1/29", "Fady B", "AM"],
  ],
  datasets: [
    {
      data: [
        2, 3, 4, 6, 6, 7, 8, 11, 2, 3, 4, 6, 6, 7, 8, 11, 2, 3, 4, 6, 6, 7, 8,
        11,
      ],
      borderColor: "#707070",
      borderWidth: 0.5,
      pointRadius: 10,
      pointHoverRadius: 10,
      pointBackgroundColor: "#000",
    },
  ],
};
const zoomOptions = {
  pan: {
    enabled: true,
    mode: "x",
  },
  zoom: {
    mode: "x",
    wheel: {
      enabled: true,
    },
  },
};
const options = {
  responsive: true,
  plugins: {
    zoom: zoomOptions,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },

  scales: {
    y: {
      min: 0,
      max: 20,
      ticks: {
        padding: 10,
      },
      title: {
        display: true,
        text: "# of Targets",
        font: {
          size: 20,
          weight: 600,
        },
      },
    },
    x: {
      ticks: {
        padding: 5,
        align: "center",
      },
    },
  },
};

export const CumulativeGraph = () => {
  return (
    <div className="CumulativeGraph">
      <Line data={data} options={options} height={200} />
    </div>
  );
};
