import React from "react";
import Header from "../../../component/Header";
import { ProfileInformation } from "../../../component/results/profileInformation/ProfileInformation";

import "../../../../Assets/style/css/results/Skill.css";
import { SkillAcquisitionBodyAreaBox } from "../../../component/results/profileInformation/SkillAcquisitionBodyAreaBox";

export const SkillAcquisition = () => {
  return (
    <div className="SkillAcquisition">
      <Header current="home" />

      {/* ProfileInformation */}
      <ProfileInformation
        profileName="Jessica Ryle"
        profileEmail="info@endlessabilities.com"
        last_login_date="11/05/2020"
        last_login_time="11/05/2020"
      />

      <div className="NotesBodyArea">
        <SkillAcquisitionBodyAreaBox active="Skill Acquisition" />
      </div>
    </div>
  );
};
// /results/behaviours
