import React, { useEffect, useState } from "react";
import EALogoWhite from "../../Assets/img/EA logo_wht.png";
import ProfileImage from "../../Assets/img/profile-image.png";
import { Link } from "react-router-dom";
export const TopLiveDateArea = ({ nohome }) => {
  const [todayDate, settodayDate] = useState("");
  const [time, settime] = useState("");
  const [ProfileImageState, setProfileImage] = useState(ProfileImage);
  const [LogoImg, setLogoImg] = useState(EALogoWhite);
  useEffect(() => {
    let today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    today = dd + "/" + mm + "/" + yyyy;

    let date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;

    settime(strTime);
    settodayDate(today);
  }, []);
  return (
    <div className="profile-information">
      <div className="profile-information-content w-90">
        <label className="profile_ea_wrapper" href="logo_white">
          <img src={LogoImg} alt="" className="profile-ea-logo-white" />
          <i class="far fa-file-image"></i>
          <input
            type="file"
            id="logo_white"
            onChange={(e) => {
              let File = e.target.files[0];
              let reader = new FileReader();
              reader.readAsDataURL(File);

              reader.addEventListener("load", () => {
                setLogoImg(reader.result);
              });
            }}
            style={{ display: "none" }}
          />
        </label>
        <div className="profile-about">
          <div className="left">
            <small>Employee ID/ Email</small>
            <h1>
              Jessica Ryle <span>(12342151632)</span>
            </h1>
            <p className="email">info@endlessabilities.com</p>
          </div>
          <div className="right">
            <small>Login</small>
            <div className="login-information">
              <div className="date">
                <i class="far fa-calendar"></i>
                <p>{todayDate}</p>
              </div>
              <div className="time">
                <i class="far fa-clock"></i>
                <p>{time}</p>
              </div>
            </div>
          </div>
        </div>
        {nohome == null && (
          <label htmlFor="get_file" className="profile_image_wrapper">
            <img src={ProfileImageState} alt="" className="profile-image" />
            <i class="far fa-file-image"></i>
            <input
              type="file"
              name=""
              id="get_file"
              onChange={(e) => {
                let File = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(File);

                reader.addEventListener("load", () => {
                  setProfileImage(reader.result);
                });
              }}
              style={{ display: "none" }}
            />
          </label>
        )}

        {nohome == null && (
          <Link className="add-new-client-button" to="/AddClientInformation">
            Add New Clients
          </Link>
        )}
      </div>
    </div>
  );
};
