import "./App.css";
import "./Assets/style/css/common.css";
import SignUp from "./App/pages/SignUp";
import Login from "./App/pages/Login";
import Home from "./App/pages/Home";
import ConsentFileUpload from "./App/pages/FileUploads/ConsentFileUpload";
import AssessmentFileUpload from "./App/pages/FileUploads/AssessmentFileUpload";
import InsertPdfCurrent from "./App/pages/InsertPdfCurrent";
import InsertPdfBehaviour from "./App/pages/InsertPdfBehaviour";
import AssignInstructor from "./App/pages/AssignInstructor";
import AddClientInformation from "./App/pages/AddClientInformation";
import AddNewCurrentProgram from "./App/pages/AddNewCurrentProgram";
import BehaviourSupportPlan from "./App/pages/BehaviourSupportPlan";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import BehaviourManagementTools from "./App/pages/BehaviourManagementTools";
import BehaviourManagementTools2 from "./App/pages/BehaviourManagementTools2";
import IndividualSupportPlan from "./App/pages/IndividualSupportPlan";
import Notification from "./App/pages/Notification";
import { Notes } from "./App/pages/results/notes/Notes";
import { RowData } from "./App/pages/results/RowData/RowData";
import { SkillAcquisition } from "./App/pages/results/SkillAcquisition/SkillAcquisition";
import { Behaviours } from "./App/pages/results/Behaviours/Behaviours";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    console.log(document.querySelectorAll("button"));
  });
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Login />
          </Route>
          {/* <Route path="/signup" exact>
            <SignUp />
          </Route> */}
          <Route path="/home" exact>
            <Home />
          </Route>
          <Route path="/Notification" exact>
            <Notification />
          </Route>
          <Route path="/home/ConsentUpload">
            <ConsentFileUpload />
          </Route>
          <Route path="/home/AssessmentUpload">
            <AssessmentFileUpload />
          </Route>
          <Route path="/home/pdf-insert-current">
            <InsertPdfCurrent />
          </Route>
          <Route path="/home/pdf-insert-behaviour">
            <InsertPdfBehaviour />
          </Route>

          <Route path="/AssignInstructor">
            <AssignInstructor />
          </Route>
          <Route path="/AddClientInformation">
            <AddClientInformation />
          </Route>
          <Route path="/AddNewCurrentProgram">
            <AddNewCurrentProgram />
          </Route>
          <Route path="/BehaviourSupportPlan">
            <BehaviourSupportPlan />
          </Route>
          <Route path="/IndividualSupportPlan">
            <IndividualSupportPlan />
          </Route>
          <Route path="/BehaviourManagementTools">
            <BehaviourManagementTools />
          </Route>

          {/* results */}
          <Route path="/results/notes" exact>
            <Notes />
          </Route>
          <Route path="/results/SkillAcquisition" exact>
            <SkillAcquisition />
          </Route>
          <Route path="/results/RowData" exact>
            <RowData />
          </Route>
          <Route path="/results/behaviours" exact>
            <Behaviours />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
