import React, { useState } from "react";
import { useEffect } from "react";
import ProfileImage from "../../Assets/img/profile-image.png";

const AssignInstructorComponent = ({
  MainImage,
  email,
  name,
  ClientIds,
  Assigned,
  active,
  setActive,
}) => {
  const [AssignedState, setAssigned] = useState(Assigned);
  const [ClientIdsState, setClientIds] = useState(ClientIds);
  const [Editbox, showEditBox] = useState(false);
  const [AddingCheckboxPartialState, showAddingCheckboxPartial] = useState([]);

  const [AddingCheckboxPartialStateRight, showAddingCheckboxPartialStateRight] =
    useState([]);

  const RemoveAssigned = (row) => {
    let Remaningfilter = AssignedState.filter(
      (EachRow) => EachRow.id != row.id
    );
    let RemovingFilter = AssignedState.filter(
      (EachRow) => EachRow.id == row.id
    );

    console.log(...RemovingFilter);

    setAssigned(Remaningfilter);
    setClientIds([...ClientIdsState, ...RemovingFilter]);
  };

  const AddingCheckboxPartialRight = (row) => {
    showAddingCheckboxPartialStateRight([
      ...AddingCheckboxPartialStateRight,
      row,
    ]);
  };

  const RemoveCheckboxPartialRight = (row) => {
    let NewFilter = AddingCheckboxPartialStateRight.filter(
      (EachRow) => EachRow.id != row.id
    );

    showAddingCheckboxPartialStateRight(NewFilter);
  };

  const AddingCheckboxPartial = (row) => {
    showAddingCheckboxPartial([...AddingCheckboxPartialState, row]);
  };

  const RemoveCheckboxPartial = (row) => {
    let NewFilter = AddingCheckboxPartialState.filter(
      (EachRow) => EachRow.id != row.id
    );

    showAddingCheckboxPartial(NewFilter);
  };
  useEffect(() => {
    console.log(AddingCheckboxPartialStateRight);
  }, [AddingCheckboxPartialStateRight, AddingCheckboxPartialState]);

  return (
    <div className="assign-instructor">
      <div className="left-side">
        <div className="intructor-info">
          <p className="name">{name}</p>
          <p className="email">{email}</p>
        </div>
        <button
          class="edit-clients-button"
          onClick={(e) => {
            setActive(email);
          }}
        >
          Edit Clients
        </button>
      </div>
      <div className="middle-side">
        <h1>Assigned Client List</h1>
        <ul>
          {AssignedState.map((EachAssigned) => (
            <li>
              <p className="name">
                <i class="fas fa-id-badge"></i> {EachAssigned.name}
              </p>
              <p>{EachAssigned.id}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="right-side">
        <div className="image-wrapper">
          <img src={MainImage} alt="" />
        </div>
        <p>{AssignedState.length} Assigned Clients </p>
      </div>

      {active == email && (
        <form className="edit-details">
          <div className="clients-id-wrapper">
            <h1>Client IDs</h1>
            <ul className="information ClientIdsStateInformation">
              {ClientIdsState.map((EachClientId) => (
                <li>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id={`Roy-${EachClientId.id}`}
                      onChange={(e) => {
                        if (e.target.checked) {
                          AddingCheckboxPartial(EachClientId);
                        } else {
                          RemoveCheckboxPartial(EachClientId);
                        }
                      }}
                    />
                    <label htmlFor={`Roy-${EachClientId.id}`}>
                      <div className="custom-checkbox">
                        <i class="fas fa-check"></i>
                      </div>
                      <p className="name">{EachClientId.name}</p>
                      <p>{EachClientId.id}</p>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="arrow-box">
            <div
              className={`icon-wrapper  ${
                AddingCheckboxPartialStateRight.length > 0 && "active"
              }`}
              id="left-arrow"
              onClick={(e) => {
                let Selected = AssignedState.filter((EachState) =>
                  AddingCheckboxPartialStateRight.some(
                    (Each) => Each.id == EachState.id
                  )
                );

                let NonSelectedLocal = AssignedState.filter((EachState) => {
                  let val = AddingCheckboxPartialStateRight.some(
                    (Each) => Each.id == EachState.id
                  );

                  console.log(val);
                  if (val == true) {
                    return false;
                  }

                  if (val == false) {
                    return true;
                  }
                });

                setClientIds([...ClientIdsState, ...Selected]);

                setAssigned(NonSelectedLocal);
                showAddingCheckboxPartialStateRight([]);
              }}
            >
              <i class="fas fa-arrow-circle-left"></i>
            </div>
            <div
              className={`icon-wrapper  ${
                AddingCheckboxPartialState.length > 0 && "active"
              }`}
              id="right-arrow"
              onClick={(e) => {
                let Selected = ClientIdsState.filter((EachState) =>
                  AddingCheckboxPartialState.some(
                    (Each) => Each.id == EachState.id
                  )
                );

                let NonSelectedLocal = ClientIdsState.filter((EachState) => {
                  let val = AddingCheckboxPartialState.some(
                    (Each) => Each.id == EachState.id
                  );

                  console.log(val);
                  if (val == true) {
                    return false;
                  }

                  if (val == false) {
                    return true;
                  }
                });

                setAssigned([...AssignedState, ...Selected]);

                setClientIds(NonSelectedLocal);
                showAddingCheckboxPartial([]);
              }}
            >
              <i class="fas fa-arrow-circle-right"></i>
            </div>
          </div>
          <div className="Assigned-wrapper">
            <h1>Assigned</h1>
            <ul className="information">
              {AssignedState.map((EachAssigned) => (
                <li className="time-circle">
                  <input
                    type="checkbox"
                    id={`checkbox-${EachAssigned.id}`}
                    onChange={(e) => {
                      if (e.target.checked) {
                        AddingCheckboxPartialRight(EachAssigned);
                      } else {
                        RemoveCheckboxPartialRight(EachAssigned);
                      }
                    }}
                  />
                  <label htmlFor={`checkbox-${EachAssigned.id}`}>
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">{EachAssigned.name}</p>
                    <p>{EachAssigned.id}</p>
                  </label>
                  <i
                    class="fas fa-times-circle"
                    onClick={(e) => RemoveAssigned(EachAssigned)}
                  ></i>
                </li>
              ))}
            </ul>
          </div>
          <div className="buttons-wrapper">
            <button>Cancel</button>
            {/* <button
              onClick={(e) => {
                e.preventDefault();
                showEditBox(false);
                setActive(null);
              }}
            >
              Save
            </button> */}
          </div>
        </form>
      )}
    </div>
  );
};
export default AssignInstructorComponent;
