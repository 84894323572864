import React, { createContext, useContext, useReducer } from "react";

let ConnectState = createContext();

export const StateProvider = ({ reducer, InitialState, children }) => (
  <ConnectState.Provider value={useReducer(reducer, InitialState)}>
    {children}
  </ConnectState.Provider>
);

export const ConnectUseState = () => useContext(ConnectState);
