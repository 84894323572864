export const client_program = [
  {
    clinic: "Client Program 1",
    id: 444444,
    lockVal: false,
    date: "22/09/2020",
    "taskanalysis-checkboxes": {
      "forward-chaining": "yes",
      "backward-chaining": "no",
      "total-tasks": "no",
    },
    programinfo: {
      assignedispbsp: "enter ISP or BSP plan name here",
      "program-name": "program name here",
      domain: "writing",
    },
    longtext: {
      "discriminative-stimulus": "free form text here",
      goal: "free form text here",
      "response-criterion": "free form text here",
    },
    "reinforcement-schedule": {
      maintenance: {
        fixedratio: "yes",
        variableinterval: "no",
        inputnum: 13,
        fixedinterval: "no",
        variableratio: "no",
        crf: "no",
      },
      currenttargets: {
        fixedratio: "no",
        variableinterval: "no",
        inputnum: 0,
        fixedinterval: "no",
        variableratio: "no",
        crf: "yes",
      },
    },
    technique: {
      bypass: "yes",
      "dr-incompatible": "no",
      "dr-alternative": "yes",
      "differential-reinforcement": "yes",
      "dr-higher": "no",
      "dr-other": "no",
      "non-contingent-reinforcement": "no",
      "recommended-value": 3,
      "dr-lower": "no",
      "functional-comm-training": "no",
      recommended: "no",
      "bypass-value": 4,
    },
    "targets-tasksteps": {
      target3: {
        name: "free form text here",
      },
      target2: {
        name: "car",
      },
      target1: {
        name: "apple",
      },
    },
    dropdowns: {
      revisioncriterion: {
        "rev3-consecutive": "yes",
        "rev1-percentage": 60,
        "rev2-timeline": 4,
      },
      "maintenance-checkbox": "yes",
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    datacollection: {
      duration: "no",
      "frequency-rate": "no",
      percentage: "no",
      probe: "yes",
    },
    stimuli: {
      stim3: "free form text here",
      stim1: "free form text here",
      stim2: "free form text here",
    },
    "prompting-procedure": {
      type: "physical",
      details: {
        option3: "gestural prompt",
        option4: "independent",
        option1: "full physical prompt",
        option2: "partial physical prompt",
      },
      hierarchy: "most-least",
    },
    generalization: {
      material: "no",
      people: "yes",
      settings: "no",
    },
    errorcorrection: {
      "in-app-error-correction-checkbox": "yes",
      step2: "free form text here",
      step3: "free form text here",
      step1: "free form text here",
    },
    teachingsteps: {
      step4: "free form text here",
      step2: "free form text here",
      step3: "free form text here",
      step1: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },
  {
    clinic: "Client Program 2",
    id: 123123,
    lockVal: false,
    date: "17/06/2020",
    "taskanalysis-checkboxes": {
      "forward-chaining": "yes",
      "backward-chaining": "no",
      "total-tasks": "no",
    },
    programinfo: {
      assignedispbsp: "enter ISP or BSP plan name here",
      "program-name": "program name here",
      domain: "writing",
    },
    longtext: {
      "discriminative-stimulus": "free form text here",
      goal: "free form text here",
      "response-criterion": "free form text here",
    },
    "reinforcement-schedule": {
      maintenance: {
        fixedratio: "yes",
        variableinterval: "no",
        inputnum: 13,
        fixedinterval: "no",
        variableratio: "no",
        crf: "no",
      },
      currenttargets: {
        fixedratio: "no",
        variableinterval: "no",
        inputnum: 0,
        fixedinterval: "no",
        variableratio: "no",
        crf: "yes",
      },
    },
    technique: {
      bypass: "yes",
      "dr-incompatible": "no",
      "dr-alternative": "yes",
      "differential-reinforcement": "yes",
      "dr-higher": "no",
      "dr-other": "no",
      "non-contingent-reinforcement": "no",
      "recommended-value": 3,
      "dr-lower": "no",
      "functional-comm-training": "no",
      recommended: "no",
      "bypass-value": 4,
    },
    "targets-tasksteps": {
      target3: {
        name: "free form text here",
      },
      target2: {
        name: "car",
      },
      target1: {
        name: "apple",
      },
    },
    dropdowns: {
      revisioncriterion: {
        "rev3-consecutive": "yes",
        "rev1-percentage": 60,
        "rev2-timeline": 4,
      },
      "maintenance-checkbox": "yes",
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    datacollection: {
      duration: "no",
      "frequency-rate": "no",
      percentage: "no",
      probe: "yes",
    },
    stimuli: {
      stim3: "free form text here",
      stim1: "free form text here",
      stim2: "free form text here",
    },
    "prompting-procedure": {
      type: "physical",
      details: {
        option3: "gestural prompt",
        option4: "independent",
        option1: "full physical prompt",
        option2: "partial physical prompt",
      },
      hierarchy: "most-least",
    },
    generalization: {
      material: "no",
      people: "yes",
      settings: "no",
    },
    errorcorrection: {
      "in-app-error-correction-checkbox": "yes",
      step2: "free form text here",
      step3: "free form text here",
      step1: "free form text here",
    },
    teachingsteps: {
      step4: "free form text here",
      step2: "free form text here",
      step3: "free form text here",
      step1: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },
];
