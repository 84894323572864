export const InitialState = {
  clients: [],
};

export const Action = {
  SET_CLIENT: "SET_CLIENT",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Action.SET_CLIENT:
      return {
        clients: [...state.clients, action.client],
      };

    default:
      return state;
  }
};
