export const Navigation = [
  {
    name: "Skill Acquisition",
    link: "/results/SkillAcquisition",
  },
  {
    name: "Behaviours",
    link: "/results/behaviours",
  },
  {
    name: "Notes",
    link: "/results/notes",
  },
  {
    name: "Raw Data",
    link: "/results/RowData",
  },
];
