import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import EALogoWhite from "../../Assets/img/EA logo_wht.png";
import "../../Assets/style/css/BehaviourManagementTools.css";
import { CustomDropDown } from "../../Assets/script/js/CustomDropDown";
import { FormsDropDowns } from "../component/FormsDropDowns";
import * as BehaviourManagementDropdowns from "../../Assets/script/js/DropdownFormConstants";
import { Link } from "react-router-dom";

import CommonHeader from "../component/CommonHeader";
import { useHistory } from "react-router-dom";
import { ConnectUseState } from "../statemanagement/StateProvider";

import {
  BehaviourManagmentDeleteIcon,
  BehaviourManagmentLi,
} from "../../Assets/script/js/BehaviourManagment";
function BehaviourManagementTools() {
  const [GenericBehaviour, setGenericBehaviour] =
    useState("Select a Behaviour");
  const [GenericDefinition, setGenericDefinition] = useState("");
  const [CustomBehaviour, setCustomBehaviour] = useState("");
  const [CustomDefinition, setCustomDefinition] = useState("");
  const [DataCollection, setDataCollection] = useState("");

  const [SelectedList, setSelectedList] = useState([]);

  // const

  const [dropdown, setdropdown] = useState(null);

  const [dropdownContent, setdropdownContent] = useState(
    BehaviourManagementDropdowns.genericBehaviours
  );

  const [DropDownData, setDropDownData] = useState({});

  useEffect(() => {
    CustomDropDown();
  }, []);

  useEffect(() => {
    console.log(SelectedList);
  }, [SelectedList]);

  const HandleFormSubmit = (e) => {
    e.preventDefault();
    let filterarr = SelectedList.filter(
      (EachList) => EachList.name == DropDownData.name
    );
    console.log(filterarr);

    if (filterarr.length > 0) {
      setSelectedList((e) => {
        let filterarr = SelectedList.filter((EachList) => {
          if (EachList.name == DropDownData.name) {
            return false;
          } else {
            return true;
          }
        });

        console.log(filterarr);
        return [...filterarr, DropDownData];
      });
    } else {
      setSelectedList([...SelectedList, DropDownData]);
    }
  };
  const DeleteRow = (row) => {
    let filterarr = SelectedList.filter(
      (EachList) => EachList.name != row.name
    );
    setSelectedList(filterarr);
  };
  const SelectedRow = (row) => {
    let filterarr = SelectedList.filter(
      (EachList) => EachList.name == row.name
    );

    console.log(row);
    if (row.CustomBehaviour == "Not Applicable") {
      setGenericBehaviour(null);
    } else {
      setGenericBehaviour("Select a Behaviour");
    }

    setDropDownData(filterarr[0]);
  };
  const HandleBehaviour = (e) => {
    window.alert("working");
  };

  const HandleDeleteBehaviour = (e) => {
    window.alert("working");
  };
  const history = useHistory();
  const [state, dispatch] = ConnectUseState();
  const [Name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [monthYear, setMonthYear] = useState("");

  const HandleSubmit = (e) => {
    // e.preventDefault()
    dispatch({
      type: "SET_CLIENT",
      client: {
        id: Math.random().toString().substr(3),
        name: Name,
        gender: gender,
        birth: birth,
        serviceHours: serviceHours,
        monthYear: monthYear,
      },
    });
    setName("");
    setGender("");
    setBirth("");
    setServiceHours("");
    setMonthYear("");
  };

  useEffect(() => {
    console.log(DropDownData);
  }, [dropdown]);

  return (
    <div className="AddClientInformation AddNewIndividuals  BehaviourManagementTools">
      {/* header */}
      <Header current="home" />
      <CommonHeader
        EALogoWhite={EALogoWhite}
        Name={Name}
        setName={setName}
        gender={gender}
        setGender={setGender}
        birth={birth}
        setBirth={setBirth}
        title="Behaviour Management Tools"
        serviceHours={serviceHours}
        setServiceHours={setServiceHours}
        monthYear={monthYear}
        setMonthYear={setMonthYear}
      />

      <section className="behaviour-form-area">
        <form className="behaviour-form-content-area">
          <div className="select-container">
            <label htmlFor="">Generic Behaviour</label>
            <div className="select-wrapper">
              <FormsDropDowns
                head_input={"Select a Behaviour"}
                list={dropdownContent}
                list_fun={setGenericBehaviour}
                setdropdown={setdropdown}
                dropdown={dropdown}
                setDropDownData={setDropDownData}
                type="generic-behaviour"
              />
            </div>
          </div>

          <div className="text-wrapper">
            <label htmlFor="Generic-Definition">Generic Definition: </label>
            <p
              name=""
              id="Generic-Definition"
              style={{ transform: "translateY(40%)" }}
              onChange={(e) => setGenericDefinition(e.target.value)}
            >
              {DropDownData.genericDef}
            </p>
          </div>

          {GenericBehaviour === "Select a Behaviour" ? (
            <div className="input-collection">
              <label htmlFor="Custom-Behaviour">Custom Behaviour</label>
              <div className="input-wrapper">
                <i class="fas fa-pen"></i>
                <input
                  type="text"
                  value={DropDownData.CustomBehaviour}
                  onChange={(e) => {
                    let CurrentWork = dropdownContent;
                    CurrentWork.map((Eachwork) => {
                      if (Eachwork.name == DropDownData.name) {
                        Eachwork.CustomBehaviour = e.target.value;
                      }
                    });
                    setDropDownData({
                      ...DropDownData,
                      CustomBehaviour: e.target.value,
                    });
                    setdropdown(CurrentWork);
                  }}
                  placeholder="Input Unique Behaviour Name"
                />
              </div>
            </div>
          ) : (
            <div className="input-collection">
              <label htmlFor="Custom-Behaviour">Custom Behaviour</label>
              <div className="input-wrapper">
                <p>Not Applicable</p>
              </div>
            </div>
          )}

          <div className="input-collection">
            <label htmlFor="Goal">Custom Definition</label>

            <div className="text-area-wrapper" style={{ height: "100%" }}>
              <i class="fas fa-pen"></i>
              <textarea
                name=""
                id="Goal"
                value={DropDownData.CustomDef}
                onChange={(e) => {
                  let CurrentWork = dropdownContent;
                  CurrentWork.map((Eachwork) => {
                    if (Eachwork.name == DropDownData.name) {
                      Eachwork.CustomDef = e.target.value;
                    }
                  });
                  setDropDownData({
                    ...DropDownData,
                    CustomDef: e.target.value,
                  });
                  setdropdown(CurrentWork);

                  setCustomDefinition(e.target.value);
                }}
              ></textarea>
            </div>
          </div>

          <div className="checkboxes">
            <label htmlFor="DataCollection">Data Collection</label>
            <div className="checkbox-container">
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="Duration"
                  onChange={(e) => setDataCollection(e.target.value)}
                  name="data-collection-1"
                  id="Duration"
                />
                <label htmlFor="Duration">
                  <div className="custom-checkbox">
                    <i class="fas fa-check"></i>
                  </div>
                  <p className="Duration">Duration</p>
                </label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="Frequency"
                  onChange={(e) => setDataCollection(e.target.value)}
                  name="data-collection-2"
                  id="Frequency"
                />

                <label htmlFor="Frequency">
                  <div className="custom-checkbox">
                    <i class="fas fa-check"></i>
                  </div>
                  <p className="Frequency">Frequency / Rate</p>
                </label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  value="Momentary"
                  onChange={(e) => setDataCollection(e.target.value)}
                  name="data-collection-3"
                  id="checkbox-Momentary"
                />
                <label htmlFor="checkbox-Momentary">
                  <div className="custom-checkbox">
                    <i class="fas fa-check"></i>
                  </div>
                  <p className="name">Momentary Time Sampling</p>
                </label>
              </div>
            </div>
          </div>

          <div className="button-wrapper">
            <input
              type="submit"
              id="add-behaviour"
              onClick={HandleFormSubmit}
            />
            <label htmlFor="add-behaviour">Add Behaviour</label>
          </div>

          <div className="added-behaviour-wrapper">
            <p>Added Behaviours</p>
            <div className="scroll-wrapper">
              <ul className="scroll-content">
                {SelectedList.map((EachList) => (
                  <>
                    <input
                      type="radio"
                      id={EachList.name}
                      name="selected"
                      style={{ display: "none" }}
                    />
                    <label htmlFor={EachList.name}>
                      <li onClick={(e) => SelectedRow(EachList)}>
                        {EachList.name}
                        <div
                          className="delete-icon"
                          onClick={(e) => DeleteRow(EachList)}
                        >
                          <i class="fas fa-times-circle"></i>
                        </div>
                      </li>
                    </label>
                  </>
                ))}
              </ul>

              <div className="buttons-wrapper">
                <input type="submit" id="save" />
                <Link to="/home">
                  <label htmlFor="cancel" className="cancel-label">
                    Cancel
                  </label>
                </Link>
                <label htmlFor="save">Save</label>
                <input type="submit" id="cancel" />
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default BehaviourManagementTools;
