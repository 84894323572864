import React from "react";
import Header from "../../../component/Header";
import { ProfileInformation } from "../../../component/results/profileInformation/ProfileInformation";

import "../../../../Assets/style/css/results/Notes.css";
import { NotesBodyAreaBox } from "../../../component/results/profileInformation/NotesBodyAreaBox";

export const Notes = () => {
  return (
    <div className="Notes">
      <Header current="home" />

      {/* ProfileInformation */}
      <ProfileInformation
        profileName="Jessica Ryle"
        profileEmail="info@endlessabilities.com"
        last_login_date="11/05/2020"
        last_login_time="11/05/2020"
      />

      <div className="NotesBodyArea">
        <NotesBodyAreaBox active="Notes" />
      </div>
    </div>
  );
};
