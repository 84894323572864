import React, { useState } from "react";
import { ResultBoxArea } from "../organisms/SkillAcquisition/ResultBoxArea/ResultBoxArea";
import { CumulativeGraph } from "./CumulativeGraph";
import { CumulativeList } from "./CumulativeList";

export const CumulativeArea = () => {
  const [first_list, setFirstList] = useState([
    "Total Weeks with no Mastery: # of weeks",
    "Consecutive Weeks with no Mastery: ",
    "Average Increase per Week: ",
  ]);

  const [screen, setScreen] = useState("graph");
  return (
    <div className="PercentageArea">
      <h1>Cumulative Mastery Graph</h1>
      <div className="buttons_switching_area">
        <button
          onClick={(e) => setScreen("graph")}
          className={screen == "graph" && "active"}
        >
          Graph
        </button>
        <button
          onClick={(e) => setScreen("list")}
          className={screen == "list" && "active"}
        >
          List
        </button>
      </div>

      {screen == "list" && <CumulativeList />}
      {screen == "graph" && <CumulativeGraph />}

      <div
        className="percentage_bottom_area extra_padding"
        style={style.percentageBottom}
      >
        <ResultBoxArea lists={first_list} title="Statistics" />
      </div>
    </div>
  );
};
const style = {
  percentageBottom: {
    gridTemplateColumns: "1fr",
  },
};
