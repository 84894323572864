export const client_plan_isp = [
  {
    clinic: "Endless Abilities Inc Isp Plan",
    id: 9712390,
    lockVal: false,
    date: "22/06/2020",
    longtext: {
      monitoringprogress: "free form text here",
      parentinvolvement: "free form text here",
      behavioralrisks: "free form text here",
      reinforcers: "free form text here",
      "goal-planobjective": "free form text here",
      inteferringbehaviorsassessprocess: "free form text here",
    },
    risks: {
      risk2: "free form text here",
      risk1: "free form text here",
      risk3: "free form text here",
    },
    ispdetailstable: {
      "domain-socialinterpersonal": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-selfregulation": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-motor": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-cognitivefunctions": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-vocational": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-playleisure": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-schoolreadiness": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-adaptive": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-communications": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
    },
    dropdowns: {
      revisioncriterion: {
        "rev3-consecutive": "yes",
        "rev1-percentage": 60,
        "rev2-timeline": 4,
      },
      "maintenance-checkbox": "yes",
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    checkboxes: {
      datacollection: {
        duration: "no",
        "momentary-time-sampling": "no",
        "frequency-rate": "no",
        percentage: "yes",
        probe: "yes",
      },
      generalization: {
        material: "yes",
        people: "no",
        settings: "no",
      },
    },
    planinfo: {
      type: "individual support plan",
      consent:
        "Informed consent was gathered by for the completion of curricular assessments, and implementation of applied behavioral analytic therapy.",
      "plan-name": "plan name here",
    },
    reinforcementschedule: {
      maintenance: {
        fixedratio: "yes",
        variableinterval: "no",
        inputnum: 3,
        fixedinterval: "no",
        variableratio: "no",
        crf: "no",
      },
      currenttargets: {
        fixedratio: "no",
        variableinterval: "no",
        inputnum: 0,
        fixedinterval: "no",
        variableratio: "no",
        crf: "yes",
      },
    },
    benefits: {
      benefit3: "free form text here",
      benefit1: "free form text here",
      benefit2: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
    applicableassessments: {
      "VB-MAPP": "no",
      "ABLLS-R": "yes",
      PEAK: "no",
      AFLS: "no",
      ESDM: "yes",
    },
  },
  {
    clinic: "Endless Abilities Inc Isp Plan 2",
    id: 97070,
    date: "22/09/2020",
    lockVal: false,
    longtext: {
      monitoringprogress: "free form text here",
      parentinvolvement: "free form text here",
      behavioralrisks: "free form text here",
      reinforcers: "free form text here",
      "goal-planobjective": "free form text here",
      inteferringbehaviorsassessprocess: "free form text here",
    },
    risks: {
      risk2: "free form text here",
      risk1: "free form text here",
      risk3: "free form text here",
    },
    ispdetailstable: {
      "domain-socialinterpersonal": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-selfregulation": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-motor": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-cognitivefunctions": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-vocational": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-playleisure": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-schoolreadiness": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-adaptive": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-communications": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
    },
    dropdowns: {
      revisioncriterion: {
        "rev3-consecutive": "yes",
        "rev1-percentage": 60,
        "rev2-timeline": 4,
      },
      "maintenance-checkbox": "yes",
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    checkboxes: {
      datacollection: {
        duration: "no",
        "momentary-time-sampling": "no",
        "frequency-rate": "no",
        percentage: "yes",
        probe: "yes",
      },
      generalization: {
        material: "yes",
        people: "no",
        settings: "no",
      },
    },
    planinfo: {
      type: "individual support plan",
      consent:
        "Informed consent was gathered by for the completion of curricular assessments, and implementation of applied behavioral analytic therapy.",
      "plan-name": "plan name here",
    },
    reinforcementschedule: {
      maintenance: {
        fixedratio: "yes",
        variableinterval: "no",
        inputnum: 3,
        fixedinterval: "no",
        variableratio: "no",
        crf: "no",
      },
      currenttargets: {
        fixedratio: "no",
        variableinterval: "no",
        inputnum: 0,
        fixedinterval: "no",
        variableratio: "no",
        crf: "yes",
      },
    },
    benefits: {
      benefit3: "free form text here",
      benefit1: "free form text here",
      benefit2: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
    applicableassessments: {
      "VB-MAPP": "no",
      "ABLLS-R": "yes",
      PEAK: "no",
      AFLS: "no",
      ESDM: "yes",
    },
  },
  {
    clinic: "Endless Abilities Inc Isp Plan 3",
    id: 9869869,
    lockVal: false,
    date: "07/06/2020",
    longtext: {
      monitoringprogress: "free form text here",
      parentinvolvement: "free form text here",
      behavioralrisks: "free form text here",
      reinforcers: "free form text here",
      "goal-planobjective": "free form text here",
      inteferringbehaviorsassessprocess: "free form text here",
    },
    risks: {
      risk2: "free form text here",
      risk1: "free form text here",
      risk3: "free form text here",
    },
    ispdetailstable: {
      "domain-socialinterpersonal": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-selfregulation": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-motor": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-cognitivefunctions": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-vocational": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-playleisure": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-schoolreadiness": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-adaptive": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
      "domain-communications": {
        programmingarea: "free form text here",
        strengths: "free form text here",
      },
    },
    dropdowns: {
      revisioncriterion: {
        "rev3-consecutive": "yes",
        "rev1-percentage": 60,
        "rev2-timeline": 4,
      },
      "maintenance-checkbox": "yes",
      masterycriterion: {
        "mast1-percentage": 90,
        "mast2-timeline": 4,
        "mast3-approval": "1 therapist and 1 parent",
      },
    },
    checkboxes: {
      datacollection: {
        duration: "no",
        "momentary-time-sampling": "no",
        "frequency-rate": "no",
        percentage: "yes",
        probe: "yes",
      },
      generalization: {
        material: "yes",
        people: "no",
        settings: "no",
      },
    },
    planinfo: {
      type: "individual support plan",
      consent:
        "Informed consent was gathered by for the completion of curricular assessments, and implementation of applied behavioral analytic therapy.",
      "plan-name": "plan name here",
    },
    reinforcementschedule: {
      maintenance: {
        fixedratio: "yes",
        variableinterval: "no",
        inputnum: 3,
        fixedinterval: "no",
        variableratio: "no",
        crf: "no",
      },
      currenttargets: {
        fixedratio: "no",
        variableinterval: "no",
        inputnum: 0,
        fixedinterval: "no",
        variableratio: "no",
        crf: "yes",
      },
    },
    benefits: {
      benefit3: "free form text here",
      benefit1: "free form text here",
      benefit2: "free form text here",
    },
    patientinfo: {
      servicehours: 4,
      date: "04-16-2021",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
    applicableassessments: {
      "VB-MAPP": "no",
      "ABLLS-R": "yes",
      PEAK: "no",
      AFLS: "no",
      ESDM: "yes",
    },
  },
];
