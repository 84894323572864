import React, { useState } from "react";
import { ResultDropDown } from "../../../ResultDropDown";
import styles from "./DropDownList.module.css";
export const DropDownList = ({
  OptionFunc,
  SettingsDropdown,
  setProgramAssign,
  AssignTherapist,
  setSettingsDropdown,
}) => {
  const [target_head, settarget_head] = useState(null);
  const [therapist_head, settherapist_head] = useState(null);
  const [programVal, setprogramVal] = useState(null);
  const [program, setProgram] = useState([
    {
      name: "REC ID",
    },
    {
      name: "Text",
    },
    {
      name: "Writing",
    },
    {
      name: "Manding",
    },
  ]);
  const [target, setTarget] = useState([
    {
      name: "Apple",
    },
    {
      name: "Car",
    },
    {
      name: "Moose",
    },
  ]);
  const [Therapist, setTherapist] = useState([
    {
      name: "Combined",
    },
    {
      name: "Therapist 1",
    },
    {
      name: "Therapist 2",
    },
    {
      name: "Therapist 3",
    },
  ]);
  const [Options, setOptions] = useState([
    {
      name: "Percentage",
    },
    {
      name: "Probe",
    },
    {
      name: "Cumulative",
    },
    {
      name: "Maintenance",
    },
  ]);

  const HandleProgram = (name) => {
    switch (name) {
      case "Manding":
        setOptions([
          {
            name: "Combined",
          },
          {
            name: "Rate / Day",
          },
          {
            name: "Rate / Hour",
          },
          {
            name: "Rate / Minute",
          },
        ]);
        setTarget([
          {
            name: "30 per hour",
          },
          {
            name: "15 per hour",
          },
          {
            name: "Mand with Pronoun",
          },
        ]);
        setProgramAssign(name);
        break;
      default:
        setOptions([
          {
            name: "Percentage",
          },
          {
            name: "Probe",
          },
          {
            name: "Cumulative",
          },
          {
            name: "Maintenance",
          },
        ]);
        setTarget([
          {
            name: "Apple",
          },
          {
            name: "Car",
          },
          {
            name: "Moose",
          },
        ]);
    }
  };

  const HandleTarget = (name) => {};

  const HandleTherapist = (name) => {
    if (name != "Combined") AssignTherapist(name);
    if (name == "Combined") AssignTherapist(null);
  };

  const HandleOptions = (name) => {
    OptionFunc(name);
    switch (name) {
      case "Probe":
        setTherapist([
          {
            name: "Combined",
          },
          {
            name: "Therapist 1",
          },
          {
            name: "Therapist 2",
          },
          {
            name: "Therapist 3",
          },
        ]);
        setTarget([]);
        settarget_head("Not Applicable");
        settherapist_head(null);
        break;
      case "Cumulative":
        setTherapist([]);
        setTarget([]);
        settarget_head("Not Applicable");
        settherapist_head("Not Applicable");
        break;
      case "Maintenance":
        setTherapist([]);
        setTarget([]);
        settarget_head("Not Applicable");
        settherapist_head("Not Applicable");
        break;
      default:
        setTherapist([
          {
            name: "Combined",
          },
          {
            name: "Therapist 1",
          },
          {
            name: "Therapist 2",
          },
          {
            name: "Therapist 3",
          },
        ]);
        setTarget([
          {
            name: "Apple",
          },
          {
            name: "Car",
          },
          {
            name: "Moose",
          },
        ]);
        settarget_head(null);
        settherapist_head(null);
    }
  };
  return (
    <div className={styles.DropDownList}>
      <div className="dropdownWrapper">
        <h2>Select a Program</h2>
        <ResultDropDown list={program} function_custom={HandleProgram} />
      </div>{" "}
      <div className="dropdownWrapper">
        <h2>Select a Target</h2>
        <ResultDropDown
          custom_head={target_head}
          list={target}
          function_custom={HandleTarget}
          SettingsDropdown={SettingsDropdown}
        />
      </div>
      <div className="dropdownWrapper">
        <h2>Per Therapist</h2>
        <ResultDropDown
          list={Therapist}
          custom_head={therapist_head}
          function_custom={HandleTherapist}
          SettingsDropdown={SettingsDropdown}
        />
      </div>
      <div className="dropdownWrapper">
        <h2>Select Options</h2>
        <ResultDropDown
          list={Options}
          function_custom={HandleOptions}
          setSettingsDropdown={setSettingsDropdown}
        />
      </div>
    </div>
  );
};
