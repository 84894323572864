import React, { useState } from "react";
import { ResultBoxArea } from "../organisms/SkillAcquisition/ResultBoxArea/ResultBoxArea";
import { DurationGraph } from "./DurationGraph";

import rightArrow from "../../../../Assets/img/arrow_right.png";
import leftArrow from "../../../../Assets/img/arrow_left.png";
export const DurationArea = ({ slider = null }) => {
  const [phase_list, setphase_list] = useState([
    "Date of Phase Change: (input Date Entry Box)",
    "Reason for Phase Change: (Text Input)",
  ]);
  const [first_list, setFirstList] = useState([
    "Average Result: Yes / No",
    "Trend: Upwards/Downwards/Stable",
    "Consecutive Sessions Not Run: ",
  ]);
  const [second_list, setSecondList] = useState([
    "Revision Criteria Met: Yes / No",
    "New Target: (Text Entry)",
    "New Goal: (Ibrahim to add Dropdown)",
  ]);

  return (
    <div className="PercentageArea">
      <h1>Duration Graph</h1>
      <div className="graph_wrapper">
        <span onClick={(e) => slider("Frequency")}>
          <img src={leftArrow} alt="" />
        </span>

        <DurationGraph />
        <span onClick={(e) => slider("Rate / Day")}>
          <img src={rightArrow} alt="" />
        </span>
      </div>

      <div className="graph_points_area">
        <div>
          <p>Prompted</p> <span className="container_graph"></span>
        </div>
        <div>
          {" "}
          <p>Independent</p>
          <span className="container_graph"></span>
        </div>
      </div>

      <div className="percentage_bottom_area">
        {" "}
        <div className="phase_change">
          <ResultBoxArea
            lists={phase_list}
            title="Phase Change"
            buttons
            buttonSingle
          />
        </div>
        <ResultBoxArea
          lists={first_list}
          title="Statistics of Current Target"
        />
        <ResultBoxArea lists={second_list} title="Target Revision" buttons />
      </div>
    </div>
  );
};
