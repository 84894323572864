import React from "react";

export const SeniorTherapistNotes = () => {
  return (
    <div className="SeniorTherapistNotes NotesWritingAea">
      <div className="top">
        <h3>Senior Therapist Notes</h3>
        <button>Download PDF</button>
      </div>
      <div className="instructor_observerd">
        <p>Instructor Observed:</p>
        <input type="text" />
      </div>
      <div className="textareaWrapper">
        <textarea name="" id="" cols="30" rows="10"></textarea>

        <div className="buttonWrapper">
          <button>Save</button> <button>Send to Team</button>
        </div>
      </div>
    </div>
  );
};
