import React from "react";
import { Link } from "react-router-dom";
import "../../../Assets/style/css/results/results.css";
import { Navigation } from "./constants.js";
export const ResultNavigation = ({ active }) => {
  return (
    <div className="ResultNavigation">
      {Navigation.map((EachNav) => (
        <li>
          <Link
            to={EachNav.link}
            className={active == EachNav.name && "active"}
          >
            {EachNav.name}
          </Link>
        </li>
      ))}
    </div>
  );
};
