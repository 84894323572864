import ProfileImage from "../../Assets/img/profile-image.png";
import ProfileImage1 from "../../Assets/img/profile-image1.png";
import ProfileImage2 from "../../Assets/img/profile-image2.png";
export const AssignInstructorJson = [
  {
    MainPicture: ProfileImage,
    Name: "Jessica Ryle",
    email: "mehmewtmolla@connect.com",
    ClientIds: [
      {
        name: "Roy Andrews",
        id: 4424712654,
      },
      {
        name: "Ruth Barrett",
        id: 44247012654,
      },
      {
        name: "Atif Asim butt ",
        id: 4422247012654,
      },
    ],

    Assigned: [
      {
        name: "Roy Reynolds",
        id: 778644545,
      },
      {
        name: "Atif Asim",
        id: 12345446654,
      },
    ],
  },

  {
    MainPicture: ProfileImage1,
    Name: "Pamela Palmer",
    email: "mehmaetmolla@connect.com",
    ClientIds: [
      {
        name: "Roy Andrews",
        id: 2472212654,
      },
      {
        name: "Ruth Barrett",
        id: 24227012654,
      },
    ],

    Assigned: [
      {
        name: "Roy Reynolds",
        id: 227786545,
      },
      {
        name: "Atif Asim",
        id: 22123456654,
      },
    ],
  },

  {
    MainPicture: ProfileImage2,
    Name: "Walter Richards",
    email: "mehmeetmolla@connect.com",
    ClientIds: [
      {
        name: "Roy Andrews",
        id: 24712655554,
      },
      {
        name: "Ruth Barrett",
        id: 555247012654,
      },
    ],

    Assigned: [
      {
        name: "Roy Reynolds",
        id: 23237786545,
      },
      {
        name: "Atif Asim",
        id: 12345236654,
      },
    ],
  },
];
