import React, { useState, useEffect } from "react";
import Header from "../../component/Header";
import EALogoWhite from "../../../Assets/img/EA logo_wht.png";
import "../../../Assets/style/css/file-upload.css";
import { Link } from "react-router-dom";
import { HandleFileUploadEvents } from "../../../Assets/script/js/FileUploadEvents";
function ConsentFileUpload() {
  const [ActiveOption, setActiveOption] = useState("Consent-form");
  useEffect(() => {
    HandleFileUploadEvents();
  }, [ActiveOption]);
  return (
    <div className="FileUploadHome">
      <Header current="home" />
      <div className="profile-information">
        <div className="top-area">
          <div className="top-area-content">
            <Link to="/home">
              <i class="fas fa-arrow-circle-left"></i>
            </Link>
            <h1>Upload Files</h1>
          </div>
        </div>
      </div>

      <div className="profile-information">
        <div className="profile-information-content w-90">
          <img src={EALogoWhite} alt="" className="profile-ea-logo-white" />
          <div className="profile-about">
            <div className="left">
              <small>Employee ID/ Email</small>
              <h1>
                Jessica Ryle <span>(12342151632)</span>
              </h1>
              <p className="email">info@endlessabilities.com</p>
            </div>
            <div className="right">
              <small>Login</small>
              <div className="login-information">
                <div className="date">
                  <i class="far fa-calendar"></i>
                  <p>11/05/2020</p>
                </div>
                <div className="time">
                  <i class="far fa-clock"></i>
                  <p>02:16:00 pm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="file-upload-wrapper">
        <div className="forms-wrapper">
          {/* <div className="form-top-nav">
            <h2
              onClick={(e) => {
                setActiveOption("Consent-form");
              }}
              className={`${ActiveOption === "Consent-form" && "active"}`}
            >
              Consent Form
            </h2>
            <h2
              onClick={(e) => {
                setActiveOption("Assessment-Graph");
              }}
              className={`${ActiveOption === "Assessment-Graph" && "active"}`}
            >
              Assessment Graph
            </h2>
          </div> */}
          {/* 
          {ActiveOption === "Consent-form" && ( */}
          <div className="consent-form-content">
            <h1>Please Upload your Client Files Here</h1>
            <form>
              <input type="file" id="consent-file-input" />

              <label htmlFor="consent-file-input">
                Drag & Drop
                <span>or click here to upload</span>
              </label>
              <Link to="/home">
                <input
                  type="submit"
                  id="consent-submit-input"
                  style={{
                    position: "absolute",
                    width: "20%",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                />
              </Link>
            </form>
          </div>
          {/* )} */}
          {/* {ActiveOption === "Assessment-Graph" && (
            <div className="Assessment-form-content">
              <form>
                <div className="left-side">
                  <div className="drop-down-wrapper">
                    <label>Assessment Name</label>
                    <div className="drop-down-pure">
                      <div
                        className="drop-down-head"
                        onClick={(e) => {
                          document
                            .querySelector(".drop-down-result")
                            .classList.toggle("active");
                        }}
                      >
                        <p>Select One </p>
                      </div>
                      <div className="drop-down-result">
                        <div className="input-wrapper">
                          <input
                            type="radio"
                            name="assessment-name"
                            id="Verbal-Behaviour"
                          />
                          <label htmlFor="Verbal-Behaviour">
                            Verbal Behaviour Milestones Assessment and Placement
                            Program (VB-MAPP){" "}
                          </label>
                        </div>

                        <div className="input-wrapper">
                          <input
                            type="radio"
                            name="assessment-name"
                            id="Assessment-of-Basic-Language"
                          />
                          <label htmlFor="Assessment-of-Basic-Language">
                            Assessment of Basic Language and Learning Skills —
                            Revised (ABLLS-R)
                          </label>
                        </div>
                        <div className="input-wrapper">
                          <input
                            type="radio"
                            name="assessment-name"
                            id="Promoting-Emergence"
                          />
                          <label htmlFor="Promoting-Emergence">
                            Promoting Emergence of Advanced Knowledge (PEAK)
                          </label>
                        </div>
                        <div className="input-wrapper">
                          <input
                            type="radio"
                            name="assessment-name"
                            id="Essential-for-Living"
                          />
                          <label htmlFor="Essential-for-Living">
                            Essential for Living (EFL)
                          </label>
                        </div>

                        <div className="input-wrapper">
                          <input
                            type="radio"
                            name="assessment-name"
                            id="Assessment-of-functional"
                          />
                          <label htmlFor="Assessment-of-functional">
                            Assessment of functional living skills (AFLS)
                          </label>
                        </div>

                        <div className="input-wrapper">
                          <input
                            type="radio"
                            name="assessment-name"
                            id="Denver-Model"
                          />
                          <label htmlFor="Denver-Model">
                            Early Start Denver Model (ESDM)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <h1>Please Upload your Client Files Here</h1>

                  <input type="file" id="Assessment-file-input" />

                  <label htmlFor="Assessment-file-input">
                    Drag & Drop
                    <span>or click here to upload</span>
                  </label>
                  <input type="submit" id="Assessment-submit-input" />
                </div>
              </form>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default ConsentFileUpload;
