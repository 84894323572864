import React, { useState } from "react";
import { ResultBoxArea } from "../organisms/SkillAcquisition/ResultBoxArea/ResultBoxArea";
import { PercentageGraph } from "./PercentageGraph";
import { FrequencyGraph } from "./FrequencyGraph";

export const PercentageArea = ({ Therapist = null }) => {
  const [first_list, setFirstList] = useState([
    "Trials to Criterion: how many trials to master",
    "Average Value: %",
    "Trend: Upwards/Downwards/Stable",
    "Consecutive Sessions Not Run: ",
  ]);
  const [second_list, setSecondList] = useState([
    "Revision Criteria Met: Yes / No",
    "New Target: (Text Entry)",
    "New Goal: (Ibrahim to add Dropdown)",
  ]);
  return (
    <div className="PercentageArea">
      <h1>Percentage Graph</h1>
      <p>Goal : Partial Prompt</p>

      <PercentageGraph Therapist={Therapist} />
      {/* <FrequencyGraph /> */}

      <div className="percentage_bottom_area">
        <ResultBoxArea
          lists={first_list}
          title="Statistics of Current Target"
        />
        <ResultBoxArea lists={second_list} title="Target Revision" buttons />
      </div>
    </div>
  );
};
