import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const FrequencyGraph = ({ nogridLines = null, Therapist = null }) => {
  const [XTicks, setXticks] = useState(true);
  const [YTicks, setYticks] = useState(true);
  useEffect(() => {
    if (nogridLines != null) {
      setXticks(false);
      setYticks(false);
    } else {
      setXticks(true);
      setYticks(true);
    }
  }, []);
  const data = {
    labels: [
      ["11/1/21", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["8/21/21", `${Therapist ? Therapist : "Dom"}`, "PM"],
      ["11/1/23", `${Therapist ? Therapist : "Kero"}`, "PM"],
      ["11/1/24", `${Therapist ? Therapist : "Kiro"}`, "AM"],
      ["11/1/25", `${Therapist ? Therapist : "Hany"}`, "PM"],
      ["11/1/26", `${Therapist ? Therapist : "Martin"}`, "PM"],
      ["11/1/21", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["8/21/21", `${Therapist ? Therapist : "Dom"}`, "PM"],
      ["11/1/23", `${Therapist ? Therapist : "Kero"}`, "PM"],
      ["11/1/24", `${Therapist ? Therapist : "Kiro"}`, "AM"],
      ["11/1/25", `${Therapist ? Therapist : "Hany"}`, "PM"],
      ["11/1/26", `${Therapist ? Therapist : "Martin"}`, "PM"],
      ["11/1/21", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["8/21/21", `${Therapist ? Therapist : "Dom"}`, "PM"],
      ["11/1/23", `${Therapist ? Therapist : "Kero"}`, "PM"],
      ["11/1/24", `${Therapist ? Therapist : "Kiro"}`, "AM"],
      ["11/1/25", `${Therapist ? Therapist : "Hany"}`, "PM"],
      ["11/1/26", `${Therapist ? Therapist : "Martin"}`, "PM"],
    ],
    datasets: [
      {
        data: [
          15, 10, 16, 25, 15, 5, 15, 10, 16, 25, 15, 5, 15, 10, 16, 25, 15, 5,
        ],
        borderColor: "#707070",
        borderWidth: 0.5,

        pointRadius: 13,
        pointStyle: "rect",
        pointHoverRadius: 10,
        pointBackgroundColor: "#000",
      },
      {
        data: [
          25, 20, 26, 35, 25, 15, 25, 20, 26, 35, 25, 15, 25, 20, 26, 35, 25,
          15,
        ],
        borderColor: "#707070",
        borderWidth: 0.5,
        pointRadius: 10,
        pointStyle: "dot",
        pointHoverRadius: 10,
        pointBackgroundColor: "#000",
      },
    ],
  };
  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      mode: "x",
      wheel: {
        enabled: true,
      },
    },
  };
  const options = {
    responsive: true,
    plugins: {
      zoom: zoomOptions,
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          padding: 10,
          stepSize: 5,
          display: YTicks,
          text: "Atif",
        },
        title: {
          display: true,
          text: "Frequency",
          font: {
            size: 20,
            weight: 600,
          },
        },
      },
      x: {
        ticks: {
          padding: 20,
          display: XTicks,
        },
      },
    },
  };

  return (
    <div className="CumulativeGraph">
      <Line data={data} options={options} height={300} />
      <h1 className="table_big_caption">Date | Therapist 2 | AM/PM</h1>
    </div>
  );
};
