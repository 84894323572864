import React from "react";

export const TargetTable = () => {
  const HandleSeperateDescisionSelection = (e) => {
    let OuterParentElement = e.target.parentElement.parentElement.nextSibling;
    OuterParentElement.classList.add("active");
  };
  const HandleSelectionBox = (e) => {
    let Value = e.target.value;

    let ApproveCheckbox = document.querySelectorAll(".approve-checkbox");
    let AllCheckbox = document.querySelectorAll(".row-checkbox");
    let RejectCheckbox = document.querySelectorAll(".reject-checkbox");
    let MessageForm = document.querySelectorAll(".message-form");

    if (Value === "Approve") {
      ApproveCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });

      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });

      MessageForm.forEach((EachForm) => {
        EachForm.classList.add("active");
      });
    } else if (Value === "Reject") {
      RejectCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });

      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });
      MessageForm.forEach((EachForm) => {
        EachForm.classList.add("active");
      });
    } else if (Value === "select-all") {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });
    } else if (Value === "select-clear") {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
      RejectCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
      ApproveCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
      MessageForm.forEach((EachForm) => {
        EachForm.classList.remove("active");
      });
    }
  };

  const HandleMainCheckbox = (e) => {
    let AllCheckbox = document.querySelectorAll(".row-checkbox");
    if (e.target.checked) {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = true;
      });
    } else {
      AllCheckbox.forEach((EachCheckbox) => {
        EachCheckbox.checked = false;
      });
    }
  };

  return (
    <form className="notification-table-area">
      <div className="notification-table-top">
        <div className="col-1 drop-down-area">
          <h4>Domain</h4>
        </div>
        <div className="col-2">
          <h4>Created By</h4>
        </div>
        <div className="col-3">
          <h4>Date</h4>
        </div>
        <div className="col-4">
          <h4>Target</h4>
        </div>
      </div>

      {/* table body */}

      <div className="notification-table-body-area">
        <div className="notification-table-body-row active">
          <div className="col-1">
            <p>Duis rhoncus dui venenatis</p>
          </div>

          <div className="col-2">
            <p>Craig Palmer</p>
          </div>
          <div className="col-3">
            <p>12/28/2021</p>
          </div>
          <div className="col-4">
            <div className="checkbox">
              <p className="name">Approve</p>
            </div>
            <div className="checkbox">
              <p className="name">Reject</p>
            </div>
          </div>
        </div>

        <div className="notification-table-body-row">
          <div className="col-1">
            <p>In at iaculis lorem. Praesent tempor</p>
          </div>

          <div className="col-2">
            <p>Craig Palmer</p>
          </div>
          <div className="col-3">
            <p>12/28/2021</p>
          </div>
          <div className="col-4">
            <p className="name">Approve</p>

            <p className="name">Reject</p>
          </div>
        </div>
      </div>
    </form>
  );
};
