import React, { useState } from "react";
import { ResultDropDown } from "../../../ResultDropDown";
import styles from "./DropDownList.module.css";
export const DropDownListBehvaiour = ({
  OptionFunc,
  SettingsDropdown,
  setProgramAssign,
}) => {
  const [therapist_head, settherapist_head] = useState(null);
  const [dropdownHide, setDropdownHide] = useState(null);

  const [program, setProgram] = useState([
    {
      name: "Agression",
    },
    {
      name: "SIB",
    },
    {
      name: "....",
    },
    {
      name: "ABC Notes",
    },
  ]);

  const [Therapist, setTherapist] = useState([
    {
      name: "Combined",
    },
    {
      name: "Therapist 1",
    },
    {
      name: "Therapist 2",
    },
    {
      name: "Therapist 3",
    },
  ]);
  const [Options, setOptions] = useState([
    {
      name: "Frequency",
    },
    {
      name: "Duration",
    },
    {
      name: "Rate / Day",
    },
    {
      name: "Rate / Hour",
    },
    {
      name: "Rate / Minute",
    },
  ]);

  const HandleBehaviour = (name) => {
    setProgramAssign(name);
    switch (name) {
      case "ABC Notes":
        setDropdownHide("not_null");
        break;
      default:
        setDropdownHide(null);
    }
  };

  const HandleTherapist = (name) => {};

  const HandleOptions = (name) => {
    OptionFunc(name);
  };
  return (
    <div className={styles.DropDownList}>
      <div className="dropdownWrapper">
        <h2>Select a Behaviour</h2>
        <ResultDropDown list={program} function_custom={HandleBehaviour} />
      </div>{" "}
      {!dropdownHide && (
        <>
          <div className="dropdownWrapper">
            <h2>Per Therapist</h2>
            <ResultDropDown
              list={Therapist}
              custom_head={therapist_head}
              function_custom={HandleTherapist}
              SettingsDropdown={SettingsDropdown}
            />
          </div>
          <div className="dropdownWrapper">
            <h2>Select Options</h2>
            <ResultDropDown
              list={Options}
              custom_head={therapist_head}
              function_custom={HandleOptions}
              SettingsDropdown={SettingsDropdown}
            />
          </div>
        </>
      )}
    </div>
  );
};
