import React from "react";

import EALogoWhite from "../../../../Assets/img/EA logo_wht.png";
export const ProfileInformation = ({
  profileName,
  profileEmail,
  last_login_date,
  last_login_time,
}) => {
  return (
    <div className="profile-information" style={{ padding: "0rem 0rem" }}>
      <div className="profile-information-content w-90">
        <img src={EALogoWhite} alt="" className="profile-ea-logo-white" />
        <div className="profile-about">
          <div className="left">
            <small>Employee ID/ Email</small>
            <h1>
              {profileName} <span>(12342151632)</span>
            </h1>
            <p className="email">{profileEmail}</p>
          </div>
          <div className="right">
            <small>Login</small>
            <div className="login-information">
              <div className="date">
                <i class="far fa-calendar"></i>
                <p>{last_login_date}</p>
              </div>
              <div className="time">
                <i class="far fa-clock"></i>
                <p>{last_login_time}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
