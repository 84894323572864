export const client_behaviormanagement = [
  {
    clinic: "Client Behavious 1",
    id: 1010101,
    lockVal: false,
    date: "14/05/2020",
    addedbehaviors: {
      behavior1: {
        name: "free form text here",
        description: "free form text here",
        duration: "yes",
        frequency: "yes",
      },
      behavior3: {
        name: "free form text here",
        description: "free form text here",
        duration: "yes",
      },
      behavior2: {
        name: "free form text here",
        description: "free form text here",
        frequency: "yes",
        momentarytimesampling: "yes",
      },
    },
    patientinfo: {
      servicehours: 4,
      date: "04-21-2022",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },
  {
    clinic: "Client Behavious 2",
    id: 10412314861,
    lockVal: false,
    date: "19/05/2020",
    addedbehaviors: {
      behavior1: {
        name: "free form text here",
        description: "free form text here",
        duration: "yes",
        frequency: "yes",
      },
      behavior3: {
        name: "free form text here",
        description: "free form text here",
        duration: "yes",
      },
      behavior2: {
        name: "free form text here",
        description: "free form text here",
        frequency: "yes",
        momentarytimesampling: "yes",
      },
    },
    patientinfo: {
      servicehours: 4,
      date: "04-21-2022",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },
  {
    clinic: "Client Behavious 3",
    id: 1044222861,
    lockVal: false,
    date: "22/05/2020",
    addedbehaviors: {
      behavior1: {
        name: "free form text here",
        description: "free form text here",
        duration: "yes",
        frequency: "yes",
      },
      behavior3: {
        name: "free form text here",
        description: "free form text here",
        duration: "yes",
      },
      behavior2: {
        name: "free form text here",
        description: "free form text here",
        frequency: "yes",
        momentarytimesampling: "yes",
      },
    },
    patientinfo: {
      servicehours: 4,
      date: "04-21-2022",
      name: "Fady Ibrahim",
      gender: "male",
      age: 8,
    },
    instructorinfo: {
      name: "Fady",
      email: "fady.testemail@cnnect.ca",
    },
  },
];
