import React, { useState } from "react";
import Header from "../component/Header";
import { FormsDropDowns } from "../component/FormsDropDowns";
import * as BehaviourSupportDropdowns from "../../Assets/script/js/DropdownFormConstants";
import "../../Assets/style/css/AddClientInformation.css";
import Trash from "../../Assets/img/trash.png";
import tick from "../../Assets/img/tick.png";
import EALogoWhite from "../../Assets/img/EA logo_wht.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { CustomDropDown } from "../../Assets/script/js/CustomDropDown";
import { HandleFormNavigation } from "../../Assets/script/js/HandleFormNavigation";
import { AddListItem } from "../../Assets/script/js/AddListItem";
import CommonHeader from "../component/CommonHeader";
import { useHistory } from "react-router-dom";
import { ConnectUseState } from "../statemanagement/StateProvider";
function BehaviourSupportPlan() {
  // Master Criteria Selection Inputs
  const [masteryCriterion1, setMasteryCriterion1] = useState(1);
  const [masteryCriterion2, setMasteryCriterion2] = useState(2);
  const [masteryCriterion3, setMasteryCriterion3] = useState(3);
  const [BaselinedBehaviour, setBaselinedBehaviour] = useState();
  const [DataCollection, setDataCollection] = useState("");
  const [Generalization, setGeneralization] = useState("");
  const [dropdown, setdropdown] = useState(null);

  useEffect(() => {
    HandleFormNavigation();

    CustomDropDown();
  }, []);

  const history = useHistory();
  const [state, dispatch] = ConnectUseState();
  const [Name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [monthYear, setMonthYear] = useState("");
  useEffect(() => {
    HandleFormNavigation();
  }, []);

  // add event listener
  useEffect(() => {
    AddListItem(".fa-plus-square", ".trash-image");
  }, []);

  const HandleSubmit = (e) => {
    // e.preventDefault()
    dispatch({
      type: "SET_CLIENT",
      client: {
        id: Math.random().toString().substr(3),
        name: Name,
        gender: gender,
        birth: birth,
        serviceHours: serviceHours,
        monthYear: monthYear,
      },
    });
    setName("");
    setGender("");
    setBirth("");
    setServiceHours("");
    setMonthYear("");
  };

  return (
    <div className="AddClientInformation AddNewCurrentProgram BehaviourSupportPlan">
      <Header current="home" noSticky />

      <CommonHeader
        EALogoWhite={EALogoWhite}
        Name={Name}
        setName={setName}
        gender={gender}
        setGender={setGender}
        birth={birth}
        setBirth={setBirth}
        title="ADD New Behavious Support Plan"
        serviceHours={serviceHours}
        setServiceHours={setServiceHours}
        monthYear={monthYear}
        setMonthYear={setMonthYear}
      />
      <section className="main-form-area">
        <div className="main-form-area-content w-90">
          <div className="navigation-area">
            <div className="main-heading">
              <img src={tick} alt="" />
              <p>Last Auto-Save 2s ago</p>
            </div>

            <ul>
              <li>
                <a href="#Plan-Type" className="active">
                  Plan Type
                </a>
              </li>
              <li>
                <a href="#Plan-Name">Plan Name</a>
              </li>
              <li>
                <a href="#Baselined-Behaviour">Baselined Behaviour</a>
              </li>
              <li>
                <a href="#Informed-Consent">Informed Consent</a>
              </li>
              <li>
                <a href="#Goal-Objective">Goal / Plan Objective</a>
              </li>
              <li className="active">
                <a href="#Biopsychosocial-Considerations">
                  Biopsychosocial Considerations
                </a>
              </li>
              <li>
                <a href="#Mastery-Criteria">Mastery Criteria</a>
              </li>
              <li>
                <a href="#Target-Behaviour">Target Behaviour</a>
              </li>
              <li>
                <a href="#Hypothesized">
                  Hypothesized Function(s) of Target Behaviour
                </a>
              </li>

              <li>
                <a href="#Replacement-Behaviours">Replacement Behaviours</a>
              </li>
              <li>
                <a href="#Reduce-Target-Behaviour">
                  Strategies to Reduce Target Behaviour
                </a>
              </li>
              <li>
                <a href="#Risks-Benefit">Risks / Benefit</a>
              </li>
              <li>
                <a href="#Data-Collection">Data Collection</a>
              </li>
              <li>
                <a href="#Monitoring-Progress">Monitoring Progress</a>
              </li>

              <li>
                <a href="#Generalization">Generalization</a>
              </li>
            </ul>
          </div>

          <form action="#" className="pure-form-area">
            <div className="input-collection d-flex" id="Plan-Type">
              <div className="left-side">
                <label htmlFor="">
                  {" "}
                  <span className="required_Ast">*</span> Plan Type
                </label>

                <div className="select-wrapper">
                  <div
                    className="select-heading"
                    onClick={(e) => {
                      document
                        .querySelector(".Behaviour-Support-Plan")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Behaviour Support Plan</p>
                    <i class="fas fa-chevron-down"></i>
                  </div>
                  <ul className="select-body  Behaviour-Support-Plan">
                    <li>
                      <Link to="/IndividualSupportPlan">
                        Individual Support Plan
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right-side" id="Plan-Name">
                <label htmlFor="">
                  {" "}
                  <span className="required_Ast">*</span>Plan Name
                </label>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" placeholder="Please Write Plan Name" />
                </div>
              </div>
            </div>

            <div className="input-collection d-flex">
              <div className="left-side" id="Baselined-Behaviour">
                <label htmlFor="">
                  {" "}
                  <span className="required_Ast">*</span>Baselined Behaviour
                </label>
                <div className="select-wrapper" style={{ width: "50%" }}>
                  {/* <div
                    className="select-heading select-Behaviour-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Select-Behaviour-1")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Select a Behaviour</p>
                    <i class="fas fa-chevron-down"></i>
                  </div> */}
                  <FormsDropDowns
                    head_input={"Select a Behaviour"}
                    list={BehaviourSupportDropdowns.genericBehaviours}
                    list_fun={setBaselinedBehaviour}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="baselined-behaviour"
                  />
                </div>
              </div>
            </div>
            {/* <ul className="select-body  Select-Behaviour-1">
                    <li>
                      <input
                        type="radio"
                        name="Select-Behaviour"
                        id="Aggression-Select-Behaviour"
                        value="Aggression"
                        onChange={(e) => setBaselinedBehaviour(e.target.value)}
                      />

                      <label
                        htmlFor="Aggression-Select-Behaviour"
                        id="select-Behaviour-label"
                      >
                        Aggression
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="Select-Behaviour"
                        id="option-Select-Behaviour"
                        value="Option"
                        onChange={(e) => setBaselinedBehaviour(e.target.value)}
                      />

                      <label
                        htmlFor="option-Select-Behaviour"
                        id="select-Behaviour-label"
                      >
                        Self Injurious Behaviour
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="Select-Behaviour"
                        id="option-Select-Behaviour"
                        value="Option"
                        onChange={(e) => setBaselinedBehaviour(e.target.value)}
                      />

                      <label
                        htmlFor="option-Select-Behaviour"
                        id="select-Behaviour-label"
                      >
                        Property Destruction
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}

            <div className="input-collection" id="Add-Baseline-Behaviour-Graph">
              <label htmlFor="Add-Baseline-Behaviour-Graph">
                <span className="required_Ast">*</span> Add Baseline Behaviour
                Graph
              </label>
              <div className="checkboxes">
                <div className="checkbox">
                  <input type="checkbox" id="Yes" />
                  <label htmlFor="Yes">
                    <p className="name">Yes</p>
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div
              className="input-collection text-input-area"
              id="Informed-Consent"
            >
              <h3>
                {" "}
                <span className="required_Ast">*</span>Informed Consent:
              </h3>
              <p>
                Informed consent was gathered by for the completion of
                curricular assessments, and implementation of applied
                behavioural analytic therapy.
              </p>
            </div>

            <div className="input-collection" id="Goal-Objective">
              <label htmlFor="Goal">
                {" "}
                <span className="required_Ast">*</span>Goal / Plan Objective
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea name="" id="Goal" cols="30" rows="10"></textarea>
              </div>
            </div>

            <div
              className="input-collection"
              id="Biopsychosocial-Considerations"
            >
              <label htmlFor="Biophysical-Considerations">
                <span className="required_Ast">*</span> Biopsychosocial
                Considerations:
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  placeholder="Biological"
                  id="Biological"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  placeholder="Psychological"
                  id="Psychological"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  placeholder="Social"
                  id="Social"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="input-collection" id="Mastery-Criteria">
              <label htmlFor="Mastery-Criteria" style={{ marginRight: 30 }}>
                <span className="required_Ast">*</span> Mastery Criteria
              </label>
              {/* </div> */}
              <div className="selecters-grid">
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-Criterion-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-1")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Select Mastery Criterion 1</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Percentage"}
                    list={BehaviourSupportDropdowns.masteryCriterion1}
                    list_fun={setMasteryCriterion1}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-1"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-Criterion-2-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-2")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Select Mastery Criterion 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Timeline"}
                    list={BehaviourSupportDropdowns.masteryCriterion2}
                    list_fun={setMasteryCriterion2}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-2"
                  />
                  {/* </div> */}
                </div>

                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-select-body-2")
                        .classList.toggle("active");
                    }}
                  > */}
                  {/* <p>Select Mastery Criterion 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Therapist/Parent"}
                    list={BehaviourSupportDropdowns.masteryCriterion3}
                    list_fun={setMasteryCriterion3}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-3"
                  />
                </div>
              </div>
            </div>

            <div className="input-collection" id="Target-Behaviour">
              <label htmlFor="Target-Behaviour">
                {" "}
                <span className="required_Ast">*</span>Target Behaviour
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Target-Behaviour"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="input-collection" id="Hypothesized">
              <label htmlFor="Hypothesized-Function">
                <span className="required_Ast">*</span> Hypothesized Function(s)
                of Target Behaviour
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Hypothesized-Function"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="input-collection" id="Replacement-Behaviours">
              <label htmlFor="Replacement-Behaviours">
                <span className="required_Ast">*</span> Replacement Behaviours
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Replacement-Behaviours"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <div className="input-collection" id="Reduce-Target-Behaviour">
              <label htmlFor="Reduce-Target-Behaviours">
                <span className="required_Ast">*</span> Strategies to Reduce
                Target Behaviours
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  placeholder="Antecedent Strategy"
                  id="Reduce-Target-Behaviours"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  placeholder="Consequent Strategy"
                  id="Reduce-Target-Behaviours"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <div className="input-collection" id="Risks-Benefit">
              <label htmlFor="Risks-Benefit">Risks / Benefits</label>
              <div style={{ marginTop: 30 }}>
                <label htmlFor="Risks">
                  <span className="required_Ast">*</span> Risk
                  <i class="fas fa-plus-square"></i>
                </label>
              </div>
              <div className="input-wrapper">
                <i class="fas fa-pen"></i>
                <input type="text" id="Teaching-Steps" />
                <img src={Trash} alt="" className="trash-image" />
              </div>
              <div>
                <div style={{ marginTop: 30 }}>
                  <label htmlFor="Benefits">
                    <span className="required_Ast">*</span> Benefit
                    <i class="fas fa-plus-square"></i>
                  </label>
                </div>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" id="Targets" />
                  <img src={Trash} alt="" className="trash-image" />
                </div>
              </div>
            </div>

            <div className="input-collection" id="Data-Collection">
              <label htmlFor="Data-collection">
                {" "}
                <span className="required_Ast">*</span>Data Collection
              </label>

              <div className="checkboxes">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Duration"
                    onChange={(e) => setDataCollection(e.target.value)}
                    name="data-collection-1"
                    id="checkbox-Duration"
                  />
                  <label htmlFor="checkbox-Duration">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Duration</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Frequency"
                    onChange={(e) => setDataCollection(e.target.value)}
                    name="data-collection-2"
                    id="checkbox-Frequency"
                  />
                  <label htmlFor="checkbox-Frequency">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Frequency / Rate</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Momentary"
                    onChange={(e) => setDataCollection(e.target.value)}
                    name="data-collection-3"
                    id="checkbox-Momentary"
                  />
                  <label htmlFor="checkbox-Momentary">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Momentary Time Sampling</p>
                  </label>
                </div>
              </div>
            </div>

            <div className="input-collection" id="Monitoring-Progress">
              <label htmlFor="Monitoring-Progress">
                {" "}
                <span className="required_Ast">*</span>Monitoring Progress
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Monitoring-Progress"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <div
              className="input-collection"
              style={{ borderBottom: "none" }}
              id="Generalization"
            >
              <label htmlFor="Generalization">
                {" "}
                <span className="required_Ast">*</span>Generalization
              </label>

              <div className="checkboxes">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="People"
                    onChange={(e) => setGeneralization(e.target.value)}
                    name="generalization-people"
                    id="checkbox-People"
                  />
                  <label htmlFor="checkbox-People">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">People</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Setting"
                    onChange={(e) => setGeneralization(e.target.value)}
                    name="generalization-settings"
                    id="checkbox-Settings"
                  />
                  <label htmlFor="checkbox-Settings">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Settings</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Material"
                    onChange={(e) => setGeneralization(e.target.value)}
                    name="generalization-material"
                    id="checkbox-Material"
                  />
                  <label htmlFor="checkbox-Material">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Material</p>
                  </label>
                </div>
              </div>
            </div>

            <div className="buttons-wrapper">
              <input
                type="submit"
                id="save"
                value=""
                style={{ display: "none" }}
              />
              <input
                type="submit"
                id="Save-Template"
                value=""
                style={{ display: "none" }}
              />
              <Link to="/home">
                <label htmlFor="close" id="close-label">
                  Close
                </label>
              </Link>
              <label htmlFor="save" id="submit-label">
                Submit
              </label>
              <input
                type="submit"
                id="close"
                value=""
                style={{ display: "none" }}
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default BehaviourSupportPlan;
