import React, { useState } from "react";
import { ResultBoxArea } from "../organisms/SkillAcquisition/ResultBoxArea/ResultBoxArea";
import { MaintainTableValue } from "./constants";
export const MaintenanceArea = () => {
  const [first_list, setFirstList] = useState([
    "Total Weeks not Maintained: # of weeks",
    "Consecutive Weeks not Maintained",
    "Average Result: Yes / No",
  ]);

  return (
    <div className="PercentageArea">
      <h1>Maintenance List </h1>

      <div className="MaintainResultContainer">
        {" "}
        <div className="MaintainResultWrapper">
          <ul className="head">
            {MaintainTableValue[0].map((EachItem) => (
              <li>{EachItem}</li>
            ))}
          </ul>
          <div className="body">
            {MaintainTableValue[1].map((EachArray) => (
              <ul className="row">
                {EachArray.map((EachValue) => (
                  <li>{EachValue}</li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>

      <div
        className="percentage_bottom_area extra_padding"
        style={style.percentageBottom}
      >
        <ResultBoxArea lists={first_list} title="Statistics per Target" />
      </div>
    </div>
  );
};

const style = {
  percentageBottom: {
    gridTemplateColumns: "1fr",
  },
};
