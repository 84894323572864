import React, { useState } from "react";
import Header from "../component/Header";
import { FormsDropDowns } from "../component/FormsDropDowns";
import * as IndividualSupportDropdowns from "../../Assets/script/js/DropdownFormConstants";
import "../../Assets/style/css/AddClientInformation.css";
import Trash from "../../Assets/img/trash.png";
import tick from "../../Assets/img/tick.png";
import EALogoWhite from "../../Assets/img/EA logo_wht.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { CustomDropDown } from "../../Assets/script/js/CustomDropDown";
import { HandleFormNavigation } from "../../Assets/script/js/HandleFormNavigation";
import { AddListItem } from "../../Assets/script/js/AddListItem";
import {
  DecreamentValueSecond,
  DecreamentValueFirst,
  IncreamentValueFirst,
  IncreamentValueSecond,
} from "../../Assets/script/js/ArrowFunction";
import CommonHeader from "../component/CommonHeader";
import { useHistory } from "react-router-dom";
import { ConnectUseState } from "../statemanagement/StateProvider";
function IndividualSupportPlan() {
  const [ApplicableAssessmentsFirst, setApplicableAssessmentsFirst] =
    useState("");

  const [ApplicableAssessmentsSecond, setApplicableAssessmentsSecond] =
    useState("");

  const [ApplicableAssessmentsthird, setApplicableAssessmentsthird] =
    useState("");
  const [ApplicableAssessmentsFour, setApplicableAssessmentsFour] =
    useState("");
  const [ApplicableAssessmentsFive, setApplicableAssessmentsFive] =
    useState("");

  // Reinforcement Schedule Integers
  const [FirstInteger, setFirstInteger] = useState(1);
  const [SecondInteger, setSecondInteger] = useState(1);
  // Master Criteria Selection Inputs
  const [masteryCriterion1, setMasteryCriterion1] = useState(1);
  const [masteryCriterion2, setMasteryCriterion2] = useState(2);
  const [masteryCriterion3, setMasteryCriterion3] = useState(3);
  // Revision Critera Selection Inputs
  const [revisionCriteria1, setRevisionCriteria1] = useState(1);
  const [revisionCriteria2, setRevisionCriteria2] = useState(2);
  const [revisionCriteria3, setRevisionCriteria3] = useState(3);

  const [DataCollection, setDataCollection] = useState("");
  const [Generalization, setGeneralization] = useState("");

  const [dropdown, setdropdown] = useState(null);

  useEffect(() => {
    HandleFormNavigation();
    CustomDropDown();

    let Labels = document.querySelectorAll(
      ".special-select-body input + label"
    );
    Labels.forEach((Label) => {
      Label.addEventListener("click", () => {
        let CountElement = Label.closest(".special-select-body").querySelector(
          ".important-variable .count"
        );
        console.log(CountElement);
        if (
          Label.textContent === "Continuous Schedule of Reinforcements (CRF)"
        ) {
          CountElement.style.backgroundColor = "gray";
          CountElement.style.pointerEvents = "none";
          CountElement.style.color = "#fff";
        } else {
          CountElement.style.backgroundColor = "#fff";
          CountElement.style.color = "#000";
          CountElement.style.pointerEvents = "unset";
        }
      });
    });
  }, []);

  const history = useHistory();
  const [state, dispatch] = ConnectUseState();
  const [Name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [FirstValue, setFirstValue] = useState(false);
  const [SecondValue, setSecondValue] = useState(false);
  useEffect(() => {
    HandleFormNavigation();
  }, []);

  // add event listener
  useEffect(() => {
    AddListItem(".fa-plus-square", ".trash-image");
  }, []);

  const HandleSubmit = (e) => {
    // e.preventDefault()
    dispatch({
      type: "SET_CLIENT",
      client: {
        id: Math.random().toString().substr(3),
        name: Name,
        gender: gender,
        birth: birth,
        serviceHours: serviceHours,
        monthYear: monthYear,
      },
    });
    setName("");
    setGender("");
    setBirth("");
    setServiceHours("");
    setMonthYear("");
  };
  return (
    <div className="AddClientInformation AddNewCurrentProgram IndividualSupportPlan">
      <Header current="home" />

      <CommonHeader
        EALogoWhite={EALogoWhite}
        Name={Name}
        setName={setName}
        gender={gender}
        setGender={setGender}
        birth={birth}
        setBirth={setBirth}
        title="ADD New Individual Support Plan"
        serviceHours={serviceHours}
        setServiceHours={setServiceHours}
        monthYear={monthYear}
        setMonthYear={setMonthYear}
      />

      <section className="main-form-area">
        <div className="main-form-area-content w-90">
          <div className="navigation-area">
            <div className="main-heading">
              <img src={tick} alt="" />
              <p>Last Auto-Save 2s ago</p>
            </div>

            <ul>
              <li>
                <a href="#Plan-Type" className="active">
                  Plan Type
                </a>
              </li>
              <li>
                <a href="#Plan-Name">Plan Name</a>
              </li>
              <li>
                <a href="#Informed-Consent">Informed Consent</a>
              </li>
              <li>
                <a href="#Goal-Objective">Goal / Plan Objective</a>
              </li>
              <li>
                <a href="#Behavioural-Risks">Behavioural Risks</a>
              </li>
              <li>
                <a href="#Maintenance">Maintenance</a>
              </li>
              <li>
                <a href="#Mastery-Revision-Criteria">
                  Mastery / Revision Criteria
                </a>
              </li>
              <li>
                <a href="#Applicable-Assessments">Applicable Assessments</a>
              </li>

              <li>
                <a href="#Assessing-Interfering-Behaviours">
                  Process for Assessing Interfering Behaviours
                </a>
              </li>
              <li>
                <a href="#Risks-Benefit">Risks / Benefit</a>
              </li>
              <li>
                <a href="#Data-Collection">Data Collection</a>
              </li>
              <li>
                <a href="#Reinforcers">Reinforcers</a>
              </li>

              <li>
                <a href="#Monitoring-Progress">Monitoring Progress</a>
              </li>
              <li>
                <a href="#Parent-Involvement">Parent Involvement</a>
              </li>
              <li>
                <a href="#Generalization">Generalization</a>
              </li>
              <li>
                <a href="#Schedule-Of-Reinforcements">
                  Schedule Of Reinforcements
                </a>
              </li>

              <li>
                <a href="#Individualized-Support-Plan">
                  Individualized Support Plan Details Table
                </a>
              </li>
            </ul>
          </div>

          <form action="#" className="pure-form-area">
            <span id="Plan-Type"></span>
            <span id="Plan-Name"></span>
            <div className="input-collection d-flex" id="">
              <div className="left-side">
                <label htmlFor="">
                  {" "}
                  <span className="required_Ast">*</span> Plan Type
                </label>

                <div className="select-wrapper">
                  <div
                    className="select-heading"
                    onClick={(e) => {
                      document
                        .querySelector(".Individual-Support-Plan")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Individual Support Plan</p>
                    <i class="fas fa-chevron-down"></i>
                  </div>
                  <ul className="select-body  Individual-Support-Plan">
                    <li>
                      <Link to="/BehaviourSupportPlan">
                        Behaviour Support Plan
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="right-side">
                <label htmlFor="">
                  <span className="required_Ast">*</span>Plan Name
                </label>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" placeholder="Please Write Plan Name" />
                </div>
              </div>
            </div>
            <span id="Informed-Consent"></span>
            <div className="input-collection text-input-area" id="">
              <h3>Informed Consent:</h3>
              <p>
                Informed consent was gathered by for the completion of
                curricular assessments, and implementation of applied
                behavioural analytic therapy.
              </p>
            </div>
            <span id="Goal-Objective"></span>
            <div className="input-collection" id="">
              <label htmlFor="Goal">
                <span className="required_Ast">*</span>Goal / Plan Objective
              </label>
              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea name="" id="Goal" cols="30" rows="10"></textarea>
              </div>
            </div>
            <span id="Behavioural-Risks"></span>
            <div className="input-collection" id="">
              <label htmlFor="Behavioural-Risks">
                {" "}
                <span className="required_Ast">*</span>Behavioural Risks
              </label>
              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Behavioural-Risks"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <span id="Maintenance"></span>
            <div className="input-collection" id="">
              <div className="checkboxes">
                <label htmlFor="Maintenance" style={{ marginRight: 30 }}>
                  <span className="required_Ast">*</span> Maintenance
                </label>
                <div className="checkbox">
                  <input
                    type="radio"
                    name="maintenance-option"
                    id="checkbox-Yes"
                  />
                  <label htmlFor="checkbox-Yes">
                    <p className="name">Yes</p>
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="radio"
                    name="maintenance-option"
                    id="checkbox-No"
                  />

                  <label htmlFor="checkbox-No">
                    <p className="name">No</p>
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <span id="Mastery-Revision-Criteria"></span>
            <div className="input-collection" id="">
              <label
                htmlFor="Mastery-Revision-Criteria"
                style={{ marginRight: 30 }}
              >
                <span className="required_Ast">*</span> Mastery / Revision
                Criteria
              </label>
              <div className="selecters-grid">
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-select-body-1")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Select Mastery Criterion 1</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Percentage"}
                    list={IndividualSupportDropdowns.masteryCriterion1}
                    list_fun={setMasteryCriterion1}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-1"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-select-body-2")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Select Mastery Criterion 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Timeline"}
                    list={IndividualSupportDropdowns.masteryCriterion2}
                    list_fun={setMasteryCriterion2}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-2"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Mastery-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Mastery-Criterion-select-body-2")
                        .classList.toggle("active");
                    }}
                  > */}
                  {/* <p>Select Mastery Criterion 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Select Mastery Criterion - Therapist/Parent"}
                    list={IndividualSupportDropdowns.masteryCriterion3}
                    list_fun={setMasteryCriterion3}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="mastery-criterion-3"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Revision-1-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Revision-Criteria-select-body-1")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Revision Criteria 1</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Revision Criteria - Percentage"}
                    list={IndividualSupportDropdowns.revCriteria1}
                    list_fun={setRevisionCriteria1}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="revision-criteria-1"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Revision-2-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Revision-Criteria-select-body-2")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Revision Criteria 2</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Revision Criteria - Timeline"}
                    list={IndividualSupportDropdowns.revCriteria2}
                    list_fun={setRevisionCriteria2}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="revision-criteria-2"
                  />
                  {/* </div> */}
                </div>
                <div className="select-wrapper" style={{ width: "30%" }}>
                  {/* <div
                    className="select-heading Revision-3-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Revision-Criteria-select-body-3")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Revision Criteria 3</p>
                    <i class="fas fa-chevron-down"></i> */}
                  <FormsDropDowns
                    head_input={"Revision Criteria - Consecutive"}
                    list={IndividualSupportDropdowns.revCriteria3}
                    list_fun={setRevisionCriteria3}
                    setdropdown={setdropdown}
                    dropdown={dropdown}
                    type="revision-criteria-3"
                  />
                  {/* </div> */}
                </div>
              </div>
            </div>
            <span id="Applicable-Assessments"></span>
            <div className="input-collection" id="">
              <label htmlFor="">
                <span className="required_Ast">*</span>Applicable Assessments
              </label>

              <div className="select-container">
                <div className="select-wrapper">
                  <div
                    className="select-heading Current-Targats-head"
                    onClick={(e) => {
                      document
                        .querySelector(".Applicable-Assessments-select-body")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Uploaded Assessments</p>
                    <i class="fas fa-chevron-down"></i>
                  </div>
                  <ul className="select-body Applicable-Assessments-select-body">
                    <li>
                      <input
                        type="checkbox"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-1"
                      />

                      <label
                        id="demi-data-Current-Targats-1"
                        onClick={(e) => {
                          if (ApplicableAssessmentsFirst == "") {
                            setApplicableAssessmentsFirst(
                              `Verbal Behavior Milestones Assessment and Placement Program (VB-MAPP)`
                            );
                          } else {
                            setApplicableAssessmentsFirst(``);
                          }

                          document
                            .querySelector(
                              ".Applicable-Assessments-select-body"
                            )
                            .classList.remove("active");
                        }}
                        className="Applicable-Assessments-label"
                        htmlFor="demi-data-Current-Targats-1"
                      >
                        Verbal Behavior Milestones Assessment and Placement
                        Program (VB-MAPP)
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-2"
                      />

                      <label
                        id="demi-data-Current-Targats-2"
                        onClick={(e) => {
                          if (ApplicableAssessmentsSecond == "") {
                            setApplicableAssessmentsSecond(` Assessment of Basic Language and Learning Skills—Revised
                            (ABLLS-R)`);
                          } else {
                            setApplicableAssessmentsSecond(``);
                          }

                          document
                            .querySelector(
                              ".Applicable-Assessments-select-body"
                            )
                            .classList.remove("active");
                        }}
                        className="Applicable-Assessments-label"
                        htmlFor="demi-data-Current-Targats-2"
                      >
                        Assessment of Basic Language and Learning Skills—Revised
                        (ABLLS-R)
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-3"
                      />

                      <label
                        id="demi-data-Current-Targats-3"
                        onClick={(e) => {
                          if (ApplicableAssessmentsthird == "") {
                            setApplicableAssessmentsthird(
                              ` Promoting Emergence of Advanced Knowledge (PEAK)`
                            );
                          } else {
                            setApplicableAssessmentsthird(``);
                          }

                          document
                            .querySelector(
                              ".Applicable-Assessments-select-body"
                            )
                            .classList.remove("active");
                        }}
                        className="Applicable-Assessments-label"
                        htmlFor="demi-data-Current-Targats-3"
                      >
                        Promoting Emergence of Advanced Knowledge (PEAK)
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-4"
                      />

                      <label
                        id="demi-data-Current-Targats-4"
                        onClick={(e) => {
                          if (ApplicableAssessmentsFour == "") {
                            setApplicableAssessmentsFour(
                              ` Assessment of functional living skills (AFLS)`
                            );
                          } else {
                            setApplicableAssessmentsFour(``);
                          }

                          document
                            .querySelector(
                              ".Applicable-Assessments-select-body"
                            )
                            .classList.remove("active");
                        }}
                        className="Applicable-Assessments-label"
                        htmlFor="demi-data-Current-Targats-4"
                      >
                        Assessment of functional living skills (AFLS)
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-5"
                      />

                      <label
                        id="demi-data-Current-Targats-5"
                        onClick={(e) => {
                          if (ApplicableAssessmentsFive == "") {
                            setApplicableAssessmentsFive(
                              `  Early Start Denver Model (ESDM)`
                            );
                          } else {
                            setApplicableAssessmentsFive(``);
                          }
                          document
                            .querySelector(
                              ".Applicable-Assessments-select-body"
                            )
                            .classList.remove("active");
                        }}
                        className="Applicable-Assessments-label"
                        htmlFor="demi-data-Current-Targats-5"
                      >
                        Early Start Denver Model (ESDM)
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <p style={{ marginTop: 20, marginLeft: 10 }}>
                {ApplicableAssessmentsFirst}
                <br /> <br />
                {ApplicableAssessmentsSecond}
                <br /> <br />
                {ApplicableAssessmentsthird}
                <br /> <br />
                {ApplicableAssessmentsFour}
                <br /> <br />
                {ApplicableAssessmentsFive}
              </p>
            </div>
            <span id="Assessing-Interfering-Behaviours"></span>
            <div className="input-collection" id="">
              <label htmlFor="Assessing-Interfering-Behaviours">
                <span className="required_Ast">*</span> Process For Assessing
                Interfering Behaviours
              </label>
              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Assessing-Interfering-Behaviours"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <span id="Risks-Benefit"></span>
            <div className="input-collection" id="">
              <label htmlFor="Risks-Benefit">
                {" "}
                <span className="required_Ast">*</span>Risks / Benefits
              </label>
              <div style={{ marginTop: 30 }}>
                <label htmlFor="Risks">
                  Risk
                  <i class="fas fa-plus-square"></i>
                </label>
              </div>
              <div className="input-wrapper">
                <i class="fas fa-pen"></i>
                <input type="text" id="Teaching-Steps" />
                <img src={Trash} alt="" className="trash-image" />
              </div>
              <div>
                <div style={{ marginTop: 30 }}>
                  <label htmlFor="Benefits">
                    Benefit
                    <i class="fas fa-plus-square"></i>
                  </label>
                </div>
                <div className="input-wrapper">
                  <i class="fas fa-pen"></i>
                  <input type="text" id="Targets" />
                  <img src={Trash} alt="" className="trash-image" />
                </div>
              </div>
            </div>
            <span id="Data-Collection"></span>
            <div className="input-collection" id="">
              <label htmlFor="Data-collection">
                {" "}
                <span className="required_Ast">*</span>Data Collection
              </label>

              <div className="checkboxes">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Percentage"
                    onChange={(e) => setDataCollection(e.target.value)}
                    name="data-collection-1"
                    id="checkbox-Percentage"
                  />
                  <label htmlFor="checkbox-Percentage">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Percentage</p>
                  </label>
                </div>

                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Probe"
                    onChange={(e) => setDataCollection(e.target.value)}
                    name="data-collection-1"
                    id="checkbox-Probe"
                  />
                  <label htmlFor="checkbox-Probe">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Probe</p>
                  </label>
                </div>
              </div>
            </div>
            <span id="Reinforcers"></span>
            <div className="input-collection" id="">
              <label htmlFor="Reinforcers">
                {" "}
                <span className="required_Ast">*</span>Reinforcers
              </label>
              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Reinforcers"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <span id="Monitoring-Progress"></span>
            <div className="input-collection" id="">
              <label htmlFor="Monitoring-Progress">
                {" "}
                <span className="required_Ast">*</span>Monitoring Progress
              </label>
              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Monitoring-Progress"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <span id="Parent-Involvement"></span>
            <div className="input-collection" id="Parent-Involvement">
              <label htmlFor="Parent-Involvement">
                {" "}
                <span className="required_Ast">*</span> Parent Involvement
              </label>

              <div className="text-area-wrapper">
                <i class="fas fa-pen"></i>
                <textarea
                  name=""
                  id="Parent-Involvement"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <span id="Generalization"></span>
            <div
              className="input-collection"
              style={{ borderBottom: "none" }}
              id=""
            >
              <label htmlFor="Generalization">
                {" "}
                <span className="required_Ast">*</span> Generalization
              </label>

              <div className="checkboxes">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="People"
                    onChange={(e) => setGeneralization(e.target.value)}
                    name="generalization-people"
                    id="checkbox-People"
                  />
                  <label htmlFor="checkbox-People">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">People</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Setting"
                    onChange={(e) => setGeneralization(e.target.value)}
                    name="generalization-settings"
                    id="checkbox-Settings"
                  />
                  <label htmlFor="checkbox-Settings">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Settings</p>
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="Material"
                    onChange={(e) => setGeneralization(e.target.value)}
                    name="generalization-material"
                    id="checkbox-Material"
                  />
                  <label htmlFor="checkbox-Material">
                    <div className="custom-checkbox">
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="name">Material</p>
                  </label>
                </div>
              </div>
            </div>
            <span id="Schedule-Of-Reinforcements"></span>
            <div
              className="input-collection"
              style={{ borderBottom: "none" }}
              id=""
            >
              <label htmlFor="Targets">
                {" "}
                <span className="required_Ast">*</span> Schedule Of
                Reinforcements
              </label>
              <div className="select-container">
                <div className="select-wrapper">
                  <h4>Current Targets</h4>

                  <ul className="special-select-body Targats-select-body">
                    <li id="step-1-1">
                      <input
                        type="radio"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-1-1"
                      />

                      <label
                        id="Current-Targats-label"
                        onClick={(e) => {
                          setFirstValue(true);
                        }}
                        htmlFor="demi-data-Current-Targats-1-1"
                      >
                        Continuous Schedule of Reinforcements (CRF)
                      </label>
                    </li>
                    <li id="step-1-2">
                      <input
                        type="radio"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-2-1"
                      />

                      <label
                        onClick={(e) => {
                          setFirstValue(false);
                        }}
                        id="Current-Targats-label"
                        htmlFor="demi-data-Current-Targats-2-1"
                      >
                        Fixed Ratio
                      </label>
                    </li>
                    <li id="step-1-3">
                      <input
                        type="radio"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-3-1"
                      />

                      <label
                        id="Current-Targats-label"
                        onClick={(e) => {
                          setFirstValue(false);
                        }}
                        htmlFor="demi-data-Current-Targats-3-1"
                      >
                        Variable Ratio
                      </label>
                    </li>
                    <li id="step-1-4">
                      <input
                        type="radio"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-4-1"
                      />

                      <label
                        id="Current-Targats-label"
                        onClick={(e) => {
                          setFirstValue(false);
                        }}
                        htmlFor="demi-data-Current-Targats-4-1"
                      >
                        Fixed Interval
                      </label>
                    </li>
                    <li id="step-1-5">
                      <input
                        type="radio"
                        name="Current-Targats-1"
                        id="demi-data-Current-Targats-5-1"
                      />

                      <label
                        id="Current-Targats-label"
                        onClick={(e) => {
                          setFirstValue(false);
                        }}
                        htmlFor="demi-data-Current-Targats-5-1"
                      >
                        Variable Interval
                      </label>
                    </li>

                    <li className="important-variable">
                      <div className="count">
                        <div
                          className="arrow arrow-left"
                          id="step-1-arrow"
                          onClick={(e) => {
                            if (FirstInteger > 1)
                              setFirstInteger(FirstInteger - 1);
                          }}
                        >
                          <i class="fas fa-arrow-left"></i>
                        </div>
                        <div className="number">
                          <p>{FirstValue ? 0 : FirstInteger}</p>
                        </div>
                        <div
                          className="arrow-right arrow"
                          onClick={(e) => {
                            setFirstInteger(FirstInteger + 1);
                            // setSecondInteger
                          }}
                          id="step-1-arrow"
                        >
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="select-wrapper">
                  <h4>Maintenance</h4>
                  {/* <div
                    className="select-heading Maintenance-head"
                    onClick={(e) => {
                      document
                        .querySelector(".maintenance-select-body")
                        .classList.toggle("active");
                    }}
                  >
                    <p>Maintenance</p>
                    <i class="fas fa-chevron-down"></i>
                  </div> */}
                  <ul className="special-select-body maintenance-select-body">
                    <li id="step-2-1">
                      <input
                        type="radio"
                        name="Maintenance-1"
                        id="demi-data-Maintenance-1"
                      />

                      <label
                        id="Maintenance-label"
                        htmlFor="demi-data-Maintenance-1"
                        onClick={(e) => {
                          setSecondValue(true);
                        }}
                      >
                        Continuous Schedule of Reinforcements (CRF)
                      </label>
                    </li>
                    <li id="step-2-2">
                      <input
                        type="radio"
                        name="Maintenance-1"
                        id="demi-data-Maintenance-2"
                      />

                      <label
                        id="Maintenance-label"
                        onClick={(e) => {
                          setSecondValue(false);
                        }}
                        htmlFor="demi-data-Maintenance-2"
                      >
                        Fixed Ratio
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="Maintenance-1"
                        id="demi-data-Maintenance-3"
                      />

                      <label
                        id="Maintenance-label"
                        onClick={(e) => {
                          setSecondValue(false);
                        }}
                        htmlFor="demi-data-Maintenance-3"
                      >
                        Variable Ratio
                      </label>
                    </li>
                    <li id="step-2-4">
                      <input
                        type="radio"
                        name="Maintenance-1"
                        id="demi-data-Maintenance-4"
                      />

                      <label
                        id="Maintenance-label"
                        onClick={(e) => {
                          setSecondValue(false);
                        }}
                        htmlFor="demi-data-Maintenance-4"
                      >
                        Fixed Interval
                      </label>
                    </li>
                    <li id="step-2-5">
                      <input
                        type="radio"
                        name="Maintenance-1"
                        id="demi-data-Maintenance-5"
                      />

                      <label
                        id="Maintenance-label"
                        onClick={(e) => {
                          setSecondValue(false);
                        }}
                        htmlFor="demi-data-Maintenance-5"
                      >
                        Variable Interval
                      </label>
                    </li>
                    <li className="important-variable">
                      <div className="count">
                        <div
                          className="arrow arrow-left"
                          onClick={(e) => {
                            if (SecondInteger > 1)
                              setSecondInteger(SecondInteger - 1);

                            // setSecondInteger
                          }}
                          id="step-2-arrow"
                        >
                          <i class="fas fa-arrow-left"></i>
                        </div>
                        <div className="number">
                          <p>{SecondValue ? 0 : SecondInteger}</p>
                        </div>
                        <div
                          className="arrow-right arrow"
                          onClick={(e) => {
                            setSecondInteger(SecondInteger + 1);
                          }}
                          id="step-2-arrow"
                        >
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span id="Individualized-Support-Plan"></span>
            <div className="table-container" id="">
              <p>
                {" "}
                <span className="required_Ast">*</span>Individualized Support
                Plan Details Table
              </p>
              <div className="table-area">
                <div className="left-table-area">
                  <div className="head">
                    <h4>Domain</h4>
                  </div>
                  <div>
                    <p>Communications</p>
                  </div>
                  <div>
                    <p>Social and Interpersonal</p>
                  </div>
                  <div>
                    <p>Cognitive Function</p>
                  </div>
                  <div>
                    <p>Motor</p>
                  </div>
                  <div>
                    <p>Adaptive</p>
                  </div>
                  <div>
                    <p>Play and Leisure</p>
                  </div>
                  <div>
                    <p>Self Regulation</p>
                  </div>
                  <div>
                    <p>Vocational</p>
                  </div>
                </div>
                <div className="mibble-table-area">
                  <div className="head">
                    <h4>Strengths</h4>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                </div>
                <div className="right-table-area">
                  <div className="head">
                    <h4>Programming Area</h4>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                  <div>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="buttons-wrapper">
              <input
                type="submit"
                id="save"
                value=""
                style={{ display: "none" }}
              />
              <input
                type="submit"
                id="Save-Template"
                value=""
                style={{ display: "none" }}
              />
              <Link to="/home">
                <label htmlFor="close" id="close-label">
                  Close
                </label>
              </Link>
              <label htmlFor="save" id="submit-label">
                Submit
              </label>
              <input
                type="submit"
                id="close"
                value=""
                style={{ display: "none" }}
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default IndividualSupportPlan;
