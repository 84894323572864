import React, { useState } from "react";
import { ResultBoxArea } from "../organisms/SkillAcquisition/ResultBoxArea/ResultBoxArea";

import { FrequencyGraph } from "./FrequencyGraph";
import rightArrow from "../../../../Assets/img/arrow_right.png";
import leftArrow from "../../../../Assets/img/arrow_left.png";
export const FrequencyArea = ({
  nogridLines = null,
  Therapist = null,
  slider = null,
}) => {
  const [phase_list, setphase_list] = useState([
    "Date of Phase Change: (input Date Entry Box)",
    "Reason for Phase Change: (Text Input)",
  ]);
  const [first_list, setFirstList] = useState([
    "Average Result: Yes / No",
    "Trend: Upwards/Downwards/Stable",
    "Consecutive Sessions Not Run: ",
  ]);
  const [second_list, setSecondList] = useState([
    "Revision Criteria Met: Yes / No",
    "New Target: (Text Entry)",
    "New Goal: (Ibrahim to add Dropdown)",
  ]);
  return (
    <div className="PercentageArea">
      <h1>Frequency Graph</h1>

      <div className="graph_wrapper">
        {nogridLines && (
          <span onClick={(e) => slider("Rate / Day")}>
            <img src={leftArrow} alt="" />
          </span>
        )}

        <FrequencyGraph nogridLines={nogridLines} Therapist={Therapist} />
        {nogridLines && (
          <span onClick={(e) => slider("Duration")}>
            <img src={rightArrow} alt="" />
          </span>
        )}
      </div>
      <div className="graph_points_area">
        <div>
          <p>Prompted</p> <span className="container_graph"></span>
        </div>
        <div>
          {" "}
          <p>Independent</p>
          <span className="container_graph"></span>
        </div>
      </div>

      <div className="percentage_bottom_area">
        <div className="phase_change">
          <ResultBoxArea
            lists={phase_list}
            title="Phase Change"
            buttons
            buttonSingle
          />
        </div>
        <ResultBoxArea
          lists={first_list}
          title="Statistics of Current Target"
        />
        <ResultBoxArea lists={second_list} title="Target Revision" buttons />
      </div>
    </div>
  );
};
