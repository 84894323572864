import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import "../../Assets/style/css/home.css";

import { HandleHomeEvent } from "../../Assets/script/js/HomeEvents";
import Clients from "../component/Clients";
import { ConnectUseState } from "../statemanagement/StateProvider";
import { TopLiveDateArea } from "../component/TopLiveDateArea";
import { clientsHelper } from "./clientsHelper";

function Home() {
  const [state, dispatch] = ConnectUseState();
  const [helper, sethelper] = useState(clientsHelper);

  useEffect(() => {
    console.log(state["clients"]);
    HandleHomeEvent();
  }, [state]);

  const SearchClient = (text) => {
    let TextValue = text.toLowerCase();
    let FilterArrInclude = clientsHelper.filter((EachHelper) => {
      let Name = EachHelper.name.toLowerCase();

      if (Name.includes(TextValue)) {
        return true;
      } else {
        return false;
      }
    });
    let FilterArrExclude = clientsHelper.filter((EachHelper) => {
      let Name = EachHelper.name.toLowerCase();

      if (Name.includes(TextValue)) {
        return false;
      } else {
        return true;
      }
    });

    console.log(FilterArrInclude);
    console.log(FilterArrExclude);
    sethelper([...FilterArrInclude, ...FilterArrExclude]);
  };
  return (
    <div className="Home">
      <Header current="home" search SearchClient={SearchClient} />
      <TopLiveDateArea />
      <div className="clients-working-section">
        <div className="clients-working-section-content w-90">
          {helper.map((EachHelper) => (
            <Clients name={EachHelper.name} code={EachHelper.code} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
