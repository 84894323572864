export const HandleFormNavigation = () => {
  const NavigationBarAnchor = document.querySelectorAll(
    ".navigation-area ul li a"
  );

  NavigationBarAnchor.forEach((EachAnchor) => {
    EachAnchor.addEventListener("click", (e) => {
      NavigationBarAnchor.forEach((EachAnchor) => {
        EachAnchor.classList.remove("active");
      });
      e.target.classList.add("active");
    });
  });
};
