import React from "react";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const PercentageGraph = ({ Therapist = null }) => {
  const data = {
    labels: [
      ["11/1/21", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/22", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/23", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/24", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/25", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/26", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/27", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/28", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],

      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
      ["11/1/29", `${Therapist ? Therapist : "Fady B"}`, "AM"],
    ],
    datasets: [
      {
        data: [
          15, 10, 16, 25, 25, 35, 40, 45, 40, 15, 10, 16, 25, 25, 35, 40, 45,
          40, 15, 10, 16, 25, 25, 35, 40, 45, 40,
        ],
        borderColor: "#707070",
        borderWidth: 0.5,
        pointRadius: 10,

        pointHoverRadius: 10,
        pointBackgroundColor: "#000",
      },
    ],
  };
  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      mode: "x",
      wheel: {
        enabled: true,
      },

      pinch: {
        enabled: true,
      },
    },
  };
  const options = {
    responsive: true,

    plugins: {
      zoom: zoomOptions,
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 5,
        },
        title: {
          display: true,
          text: "Percent %",
          font: {
            size: 20,
            weight: 600,
          },
        },
      },
      x: {
        ticks: {
          padding: 20,
        },
      },
    },
  };

  return (
    <div className="CumulativeGraph">
      <Line data={data} options={options} height={300} />
      <h1 className="table_big_caption">Date | Therapist 2 | AM/PM</h1>
    </div>
  );
};
