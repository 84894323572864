import React from "react";
import Header from "../component/Header";
import "../../Assets/style/css/pdf-insert.css";
function InsertPdfCurrent() {
  return (
    <div className="InsertPdf">
      <Header current="home" />
      <form>
        {/* <input type="file" id="pdf-insert-file" /> */}
        <label htmlFor="pdf-insert-file">
          <p>
            Insert Current Program <span>PDF Here</span>
          </p>
        </label>
      </form>
    </div>
  );
}

export default InsertPdfCurrent;
