import React, { useEffect, useState } from "react";
import Header from "../component/Header";

import "../../Assets/style/css/AssignInstructor.css";

import { TopLiveDateArea } from "../component/TopLiveDateArea";
import AssignInstructorComponent from "../component/AssignInstructor";

import { AssignInstructorJson } from "../Api/AssignInstructorJson";

function AssignInstructor() {
  const [active, setActive] = useState(null);
  return (
    <div className="AssignInstructor">
      <Header current="instructor" />
      <TopLiveDateArea nohome={true} />

      <div className="assign-instructor-wrapper">
        <div className="assign-instructor-content">
          {AssignInstructorJson.map((EachInstructor) => (
            <AssignInstructorComponent
              active={active}
              setActive={setActive}
              MainImage={EachInstructor.MainPicture}
              email={EachInstructor.email}
              name={EachInstructor.Name}
              ClientIds={EachInstructor.ClientIds}
              Assigned={EachInstructor.Assigned}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AssignInstructor;
